export function DeviceCard1(props :any) {
  return (
    <div className='card [ anim8-border ]' data-card-animate="">
      <div className='card-body'>
        <div className='d-flex align-items-start flex-wrap'>
          <div className='symbol symbol-2by3 symbol-circleX me-5'>
            <div className='symbol-label bg-transparent text-primary border border-secondary border-dashed | overflow-hidden'>
              {/* <img
                src='https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Fimages-platform.99static.com%2Fn-_i2hOikgnlYdsJlJ5YJOimfxk%3D%2Ffit-in%2F900x675%2F99designs-contests-attachments%2F1%2F1311%2Fattachment_1311321&f=1&nofb=1'
                className='img-fluid'
              /> */}
               <img
                src={props.device?.type == "Geyser" ? "/img/logos/h2o-logo@0.5x.png" : "/img/logos/inverter-logo3.png"}
                className='img-fluid'
              />
            </div>
          </div>
          <div className='d-flex flex-column'>
            <h2 className='mb-1'>{props.device?.name}</h2>
            <div className='text-muted fw-bold [ d-flex justify-content-start flex-column ]'>
              <div className="d-flex flex-wrap">
                <span className=''>Device ID</span>
                <span className='mx-3'>:</span>
                <span className='tc-rubicon-primary | min-w-150px'>{props.device?.identifier}</span>
              </div>
              {/* <div>
                <span className='tc-rubicon-primary'>props.device?.companyID</span>
              </div> */}
            </div>

            <button className='btn btn-sm btn-light-primary fw-bolder min-w-150px w-100 max-w-220px mt-8' onClick={props.onClick}>
              Switch Device
            </button>
          </div>
        </div>
      </div>
      {/* <div className='card-content [ d-flex flex-center flex-column | px-9 pb-9 ]'>
        <button className='btn btn-sm btn-light-primary fw-bolder w-50'>Switch Device</button>
      </div> */}
    </div>
  )
}
