import { useAuth0 } from "@auth0/auth0-react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Device } from "../../interfaces/Device";
import { setGlobalState, useGlobalState } from "../../modules/global-state/GlobalState";
import { DeviceCard } from "./DeviceCard";
import { DeviceManager } from "../../modules/global-state/DeviceManager";

export function SwitchDevice() {
  const navigate = useNavigate()
  const [userID] = useGlobalState('userID')
  const [devices, setDevices] = useState<any>({list:[]})
  const { getAccessTokenSilently, getIdTokenClaims } = useAuth0()
  const [isLoaded, setLoaded] = useState<boolean>(false);

  function switchDevice(device: Device) {
    localStorage.setItem('deviceID', device.deviceID.toString());
    DeviceManager.deviceID = device.deviceID.toString();
    setGlobalState('deviceInfo', device);
    setGlobalState('ActiveModel', null);
    setGlobalState('FunctionModel', null);
    navigate('/active-view')
  }

  useEffect(() => {
    
    getAccessTokenSilently().then(token => {
      fetch(process.env.REACT_APP_IOT_API_URL + 'devices/list?' + new URLSearchParams({
        UserFilter: userID.toString()
      }), {
        headers: {
          Accept: 'application/json',
          Authorization: 'Bearer ' + token
        }
      }).then(response => {
        if (![200, 204].includes(response.status)) return
        setLoaded(true);
        response.json().then(setDevices)
      }).catch()
    })

  }, [getAccessTokenSilently, fetch, setDevices, getIdTokenClaims])

  return (
    <div className='row g-6 g-xl-9'>
      {
        (isLoaded == true && devices.list.length <= 0) ? 
          <><div className='card [ anim8-border ]' data-card-animate="">
          <div className='card-body'><h2 className="text-center">There are no devices linked to this user.</h2></div></div></>
        : 
        devices.list.map((device: Device) => {
          return <DeviceCard device={device} onClick={() => switchDevice(device)} key={device.deviceID}/>
        })
      }
    </div>
  )
}