import {Device} from '../../interfaces/Device'
import {DeviceCard1} from './DeviceCard1'

interface IMyProps {
  device: Device
  onClick: () => void
}

export function DeviceCard(props: IMyProps) {
  return (
    <div
      className='col-md-6 col-xxl-4 [ cursor-pointer ]'
      onClick={() => props.onClick()}
      key={props.device?.deviceID}
    >
      {/* <div className='card'>
        <div className='card-body d-flex flex-center flex-column p-9'>
          <p className='fs-4 text-dark fw-bolder mb-0'>{props.device?.name}</p>
          <p className='fw-bold text-gray-400 mb-6'>Device ID: {props.device?.deviceID}</p>
          <button className='btn btn-sm btn-light-primary fw-bolder w-50'>Switch Device</button>
        </div>
      </div> */}

      <DeviceCard1 device={props.device} onClick={props.onClick} />
    </div>
  )
}
