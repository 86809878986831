import { DisplayDevice } from '../../../Components/active-view/DisplayDevice'
import { StatsWidget3 } from '../../../Components/active-view/StatsWidget3'
import { KTSVG } from '../../../_metronic/helpers/components/KTSVG'
import { ActiveViewModel } from '../../interfaces/ActiveView'
import { ActiveInverterViewModel } from '../../interfaces/ActiveInverterView'
import OverlayTrigger from 'react-bootstrap/esm/OverlayTrigger'
import Tooltip from 'react-bootstrap/esm/Tooltip'
import React from 'react'
import { setGlobalState, useGlobalState } from '../../modules/global-state/GlobalState'
import { useEffect, useState } from 'react'

interface IMyProps {
  refresh: any
}

interface IReadings {
  readingId: number,
  dateEvent: string,
  dateCreated: string,
  gridFrequency: number,
  gridVoltage: number,
  gridCurrent: number,
  gridPower: number,
  roomTemperature: number, 
  enviromentalTemperature: number,
  loadVoltage: number,
  loadCurrent: number,
  loadPower: number,
  loadRate: number,
  batteryVoltage: number,
  batteryCurrent: number,
  batterySoc: number,
  batteryTemperature: number,
  batteryPower: number,
  consumption: number

}

interface IActiveModel {
  inverterId: number,
  inverterName: string,
  inverterReadings: IReadings,

}

interface IMyProps {
  ActiveModel: ActiveInverterViewModel | null,
  refresh: any
}

export function ActiveViewInverter(props: IMyProps) {

  /* Demo purposes
    useEffect(() => {
    reloadActiveView();
    setInterval(reloadActiveView, 10000);

  }, []);*/

  //const [ActiveModel, setActiveModel] = useState<any>(null);

  //#region Demo purposes
  /*async function reloadActiveView() {
    fetch("https://rubiconmonitorapi.azurewebsites.net/api/inverter/getinverterreadings?inverterID=2", {
      headers: {

      },
    })
      .then((response) => {
        if (![200, 204].includes(response.status)) return

        if (response.status == 204) {
          setActiveModel(null)
        } else {
          response
            .clone()
            .json()
            .then((data) => {
              setActiveModel(
                Object.assign({}, ActiveModel, {
                  inverterId: data.inverterId,
                  inverterName: data.inverterName,
                  inverterReadings: {
                    readingId: data.inverterReadings.readingId,
                    dateEvent: data.inverterReadings.dateEvent,
                    dateCreated: data.inverterReadings.dateCreated,
                    gridFrequency: data.inverterReadings.gridFrequency,
                    gridVoltage: data.inverterReadings.gridVoltage,
                    gridCurrent: data.inverterReadings.gridCurrent,
                    gridPower: data.inverterReadings.gridPower,
                    roomTemperature: data.inverterReadings.roomTemperature,
                    enviromentalTemperature: data.inverterReadings.enviromentalTemperature,
                    loadVoltage: data.inverterReadings.loadVoltage,
                    loadCurrent: data.inverterReadings.loadCurrent,
                    loadPower: data.inverterReadings.loadPower,
                    loadRate: data.inverterReadings.loadRate,
                    batteryVoltage: data.inverterReadings.batteryVoltage,
                    batteryCurrent: data.inverterReadings.batteryCurrent,
                    batterySoc: data.inverterReadings.batterySoc,
                    batteryTemperature: data.inverterReadings.batteryTemperature,
                    batteryPower: data.inverterReadings.batteryPower,
                    consumption: data.inverterReadings.consumption
                  }

                })
              )
            })
        }
      })
      .catch()
  }*/
//#endregion


  const [deviceInfo] = useGlobalState('deviceInfo');

  // var total = props.ActiveModel != null ? props.ActiveModel.PVProductedToday + props.ActiveModel.ImportedToday : 0;
  // var percImported =
  //   props.ActiveModel != null
  //     ? (props.ActiveModel.PVProductedToday / total) * 100 : 0;

  // var percGradient = getLinearGradientFromPerc(percImported);

  return (
    <>
      <div className='card card-custom shadow'>
        <div className='card-body'>
          <div className='active_view_holder d-grid'>
            <div className='grid-item'></div>
            <div className='grid-item'>
              {/* <StatsWidget3 /> */}
              {/* <div className='card-body d-flexX d-none align-items-center justify-content-center flex-wrap | text-center'>
                <span className='svg-icon svg-icon-2hx svg-icon-info me-4 mb-5 mb-sm-0 [ pulse ]'>
                  <KTSVG
                    path='/media/icons/duotune/general/gen030.svg'
                    className='svg-icon svg-icon-success svg-icon-2hx ms-n1 flex-grow-1'
                  />
                </span>
                <div className='d-flex flex-column'>
                  <div className='me-2'>
                    <h2 className='fw-bold text-gray-800 mb-3'>Transmission</h2>
                    <div className='text-muted fw-semibold fs-6'>
                      <h5 className='mb-0'>
                        {props.ActiveModel != null ? (
                          <span>
                            Last Transmission received on
                            <b className='text-primary ms-1'>
                              {props.ActiveModel != null ? props.ActiveModel.DateReceivedS : ''}
                            </b>
                            <span className='mx-2'> at</span>
                            <b className='text-primary'>
                              {props.ActiveModel != null ? props.ActiveModel.TimeReceivedS : ''}
                            </b>
                          </span>
                        ) : (
                          <span>No transmission received yet.</span>
                        )}
                      </h5>
                    </div>
                  </div>
                  <a
                    href='javascript:;'
                    className='btn btn-light-warning fw-semibold [ mt-4 ]'
                    onClick={props.refresh}
                  >
                    <span
                      className='svg-icon svg-icon-1 svg-icon-primary'
                      data-html='true'
                      data-tip='Click to refresh'
                    >
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        width='16'
                        height='16'
                        fill='currentColor'
                        className='bi bi-arrow-counterclockwise'
                        viewBox='0 0 16 16'
                      >
                        <path
                          fill-rule='evenodd'
                          d='M8 3a5 5 0 1 1-4.546 2.914.5.5 0 0 0-.908-.417A6 6 0 1 0 8 2v1z'
                        />
                        <path d='M8 4.466V.534a.25.25 0 0 0-.41-.192L5.23 2.308a.25.25 0 0 0 0 .384l2.36 1.966A.25.25 0 0 0 8 4.466z' />
                      </svg>
                    </span>
                    Refresh
                  </a>
                </div>
              </div> */}
            </div>
            <div className='grid-item'></div>
            <div className='grid-item'>
              {/* <div className='card my-6X min-h-300px [ anim8-border ]' data-card-animate='true'>
                <div className='card-body | d-flex flex-center'>
                  <div className='content_holder'>
                    <h1 className='text-white'>Aweh - work in progress</h1>
                  </div>
                </div>
              </div> */}
              <div
                className='card w-250px h-250px h-lg-400px w-lg-400px | bg-gray-300'
                data-card-animate=''
              // style={{'background': percGradient} as React.CSSProperties}
              >
                <div className='card-body | d-flex flex-center'>
                  <div className='content_holder'>
                    <div className='symbol symbol-100px symbol-2by3'>
                      <div className='symbol-label bg-transparent text-primary | overflow-hidden'>
                        {/* <img src='/img/logos/h2o-logo@0.5x.png' className='img-fluid rounded' /> */}
                        <img src='/img/logos/inverter-logo3.png' className='img-fluid rounded' />
                      </div>
                    </div>
                    <h1 className='tc-rubicon-primary mt-3 text-center'>
                      {props.ActiveModel != null ? Math.abs((props.ActiveModel.consumption / 1000)).toFixed(2) : '-'} kWH
                    </h1>
                    <small className='fs-4'>Consumed</small>
                    {props.ActiveModel != null ? (props.ActiveModel.grid_frequency < 1 ? <small className='fs-4 text-danger'>Battery Mode</small>
                    : <small className='fs-4 text-success'>Grid Mode</small>
                    /* {props.ActiveModel != null && props.ActiveModel.PowerMode == 3 ? <small className='fs-4 text-danger'>Boost Mode</small> : null}
                    {props.ActiveModel != null && props.ActiveModel.PowerMode == 2 ? <small className='fs-4 text-danger'>Grid Mode</small> : null}
                    {props.ActiveModel != null && props.ActiveModel.PowerMode == 1 ? <small className='fs-4 text-success'>Solar Mode</small> : null}
                    {props.ActiveModel != null && props.ActiveModel.PowerMode == 0 ? <small className='fs-4 text-muted'>Idle Mode</small> : null} */): null}
                  </div>
                </div>
              </div>
            </div>

            <div className='grid-item [ arr-b ]'>
            <svg
                id='arrow-01'
                data-name='arrow-01'
                xmlns='http://www.w3.org/2000/svg'
                viewBox='0 0 30 10'
              >
                <line
                  x1='15'
                  y1='1'
                  x2='15'
                  y2='30'
                  stroke={
                    props.ActiveModel == null || props.ActiveModel.battery_power <= 0 ? 'var(--bs-danger)' : 'var(--bs-success)'
                  }
                  stroke-width='.5'
                  stroke-dasharray={'0%'}
                ></line>
              </svg>
              <div className='card bg-gray-300'>
                <div className='card-body | d-flex flex-center'>
                  <div className='content_holder text-center'>
                    <i className='bi bi-battery-half fs-2hx'></i>
                    <h1 className='mb-0 mt-3' style={{'color': props.ActiveModel == null || props.ActiveModel.battery_power <= 0 ? 'var(--bs-danger)' : 'var(--bs-success)'}}>
                      {props.ActiveModel != null ? props.ActiveModel.battery_power.toFixed(2) : '-'} W
                    </h1>
                  </div>
                </div>
              </div>
              <div
                className={
                  props.ActiveModel == null || props.ActiveModel.battery_soc > 50
                    ? 'card mt-6 [ geyser_temp hotX warmX full ]'
                    : props.ActiveModel.battery_soc > 25
                      ? 'card mt-6 [ geyser_temp hotX warm fullX ]'
                      : 'card mt-6 [ geyser_temp hot warmX fullX ]'
                }
              >
                <div className='card-body | d-flex flex-center'>
                  <div className='content_holder [ d-flex align-items-center ]'>
                    <i
                      className={
                        props.ActiveModel != null && props.ActiveModel.battery_soc > 50
                          ? 'bi bi-battery-full [ fs-5tx ]'
                          : 'bi bi-battery-full [ fs-5tx | d-none ]'
                      }
                    ></i>
                    <i
                      className={
                        props.ActiveModel != null &&
                        props.ActiveModel.battery_soc > 25 &&
                        props.ActiveModel.battery_soc <= 50
                          ? 'bi bi-battery-half [ fs-5tx ]'
                          : 'bi bi-battery-half [ fs-5tx | d-none ]'
                      }
                    ></i>
                    <i
                      className={
                        props.ActiveModel == null || props.ActiveModel.battery_soc <= 25
                          ? 'bi bi-battery [ fs-5tx ]'
                          : 'bi bi-battery [ fs-5tx | d-none ]'
                      }
                    ></i>
                    <h1 className='text-white mb-0 display-4'>
                      {props.ActiveModel != null ? " " + props.ActiveModel.battery_soc : '-'} %
                    </h1>
                  </div>
                </div>
              </div>

              <div className='d-flex align-items-center justify-content-center flex-row | py-3'>

                <span className='svg-icon svg-icon-2hx svg-icon-info me-4 mb-5 mb-sm-0 [ pulse ]'>
                  <KTSVG
                    path='/media/icons/duotune/general/gen030.svg'
                    className='svg-icon svg-icon-success svg-icon-2hx ms-n1 flex-grow-1'
                  />
                </span>
                <div className='bg-rubicon-primary badge px-3 text-center'>
                  <span className='d-block | fs-5 text-white max-ch py-1' >
                    {deviceInfo.name} (DeviceID: {deviceInfo.identifier})
                  </span>
                </div>

                <OverlayTrigger
                  delay={{ show: 250, hide: 400 }}
                  placement='right'
                  overlay={
                    <Tooltip id='info-tooltip'>
                      <h5 className='mb-0'>
                        {props.ActiveModel != null ? (
                          <span>
                          Last Transmission received on
                          <b className='text-primary ms-1'>
                            {props.ActiveModel != null ? props.ActiveModel.DateReceivedS : ''}
                          </b>
                          <span className='mx-2'> at</span>
                          <b className='text-primary'>
                            {props.ActiveModel != null ? props.ActiveModel.TimeReceivedS : ''}
                          </b>
                        </span>
                        ) : (
                          <span>No transmission received yet.</span>
                        )}
                      </h5>
                    </Tooltip>
                  }
                >
                  <i className='bi bi-info-circle fs-3 ms-4 tc-rubicon-primary'></i>
                </OverlayTrigger>
              </div>
            </div>
            <div className='grid-item | arr-l arr-r2' data-animate=''>
              <svg
                id='arrow-01'
                data-name='arrow-01'
                xmlns='http://www.w3.org/2000/svg'
                viewBox='0 0 30 10'
              >
                <line
                  x1='0'
                  y1='1'
                  x2='30'
                  y2='1'
                  stroke={
                    props.ActiveModel == null || Math.abs(props.ActiveModel.grid_power) <= 0 ? 'var(--bs-gray-500)' : 'var(--bs-success)'
                  }
                  stroke-width='.5'
                  stroke-dasharray={props.ActiveModel == null || Math.abs(props.ActiveModel.grid_power) <= 0 ? '3%' : '0%'}
                ></line>
              </svg>
              <div className='card bg-gray-300'>
                <div className='card-body | d-flex flex-center'>
                  <div className='content_holder text-center'>
                    <i className='bi bi-lightning-charge fs-2hx'></i>
                    <h1 className='mb-0 mt-3' style={{'color': 'var(--bs-success) !important'}}>
                      {props.ActiveModel != null ? Math.abs(props.ActiveModel.grid_power).toFixed(2) : '-'} W
                    </h1>
                  </div>
                </div>
              </div>
            </div>
            <div className='grid-item | arr-r arr-l2' data-animate=''>
              <svg
                id='arrow-02'
                data-name='arrow-02'
                xmlns='http://www.w3.org/2000/svg'
                viewBox='0 0 30 10'
              >
                <line
                  x1='0'
                  y1='1'
                  x2='30'
                  y2='1'
                  stroke={props.ActiveModel == null || props.ActiveModel.load_power <= 0 ? 'var(--bs-gray-500)' : 'var(--bs-danger)'}
                  stroke-width='.5'
                  stroke-dasharray={props.ActiveModel == null || props.ActiveModel.load_power <= 0 ? '3%' : '0%'}
                ></line>
              </svg>
              <div className='card bg-gray-300'>
                <div className='card-body | d-flex flex-center'>
                  <div className='content_holder text-center'>
                    <i className='bi bi-lightbulb fs-2hx'></i>
                    <h1 className='mb-0 mt-3' style={{'color': 'var(--bs-success) !important'}}>
                      {props.ActiveModel != null ? props.ActiveModel.load_power.toFixed(2) : '-'} W
                    </h1>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-4 p-5">
              <h1>Grid</h1>
              <div className='d-flex flex-wrap align-items-center bg-light rounded my-3'>
                <span className='svg-icon svg-icon-warning me-5 [ d-none ]'>
                  {/* <KTSVG path='/media/icons/duotune/abstract/abs027.svg' className='svg-icon-1' /> */}
                  <i className="bi bi-align-center"></i>
                </span>

                <div className='flex-grow-1 me-2'>
                  <span className='fw-bolder text-gray-800 fs-6'>Voltage</span>
                  {/* <span className='text-muted fw-bold d-block'>Friday, 05 Aug 2022</span> */}
                </div>

                <span className='fw-bolder tc-rubicon-primary py-1 fs-4'>{props.ActiveModel != null ? (props.ActiveModel.grid_voltage).toFixed(2) : "-"} V</span>
              </div>

              <div className='d-flex flex-wrap align-items-center bg-light rounded my-3'>
                <span className='svg-icon svg-icon-warning me-5 [ d-none ]'>
                  {/* <KTSVG path='/media/icons/duotune/abstract/abs027.svg' className='svg-icon-1' /> */}
                  <i className="bi bi-align-top"></i>
                </span>

                <div className='flex-grow-1 me-2'>
                  <span className='fw-bolder text-gray-800 fs-6'>Current</span>
                  {/* <span className='text-muted fw-bold d-block'>Friday, 05 Aug 2022</span> */}
                </div>

                <span className='fw-bolder tc-rubicon-primary py-1 fs-4'>{props.ActiveModel != null ? (props.ActiveModel.grid_current).toFixed(2) : "-"} A</span>
              </div>

              <div className='d-flex flex-wrap align-items-center bg-light rounded my-3'>
                <span className='svg-icon svg-icon-warning me-5 [ d-none ]'>
                  {/* <KTSVG path='/media/icons/duotune/abstract/abs027.svg' className='svg-icon-1' /> */}
                  <i className="bi bi-align-bottom"></i>
                </span>

                <div className='flex-grow-1 me-2'>
                  <span className='fw-bolder text-gray-800 fs-6'>Power</span>
                  {/* <span className='text-muted fw-bold d-block'>Friday, 05 Aug 2022</span> */}
                </div>

                <span className='fw-bolder tc-rubicon-primary py-1 fs-4'>{props.ActiveModel != null ? (props.ActiveModel.grid_power).toFixed(2) : "-"} W</span>
              </div>
              <div className='d-flex flex-wrap align-items-center bg-light rounded my-3'>
                <span className='svg-icon svg-icon-warning me-5 [ d-none ]'>
                  {/* <KTSVG path='/media/icons/duotune/abstract/abs027.svg' className='svg-icon-1' /> */}
                  <i className="bi bi-align-top"></i>
                </span>

                <div className='flex-grow-1 me-2'>
                  <span className='fw-bolder text-gray-800 fs-6'>Frequency</span>
                  {/* <span className='text-muted fw-bold d-block'>Friday, 05 Aug 2022</span> */}
                </div>

                <span className='fw-bolder tc-rubicon-primary py-1 fs-4'>{props.ActiveModel != null ? (props.ActiveModel.grid_frequency).toFixed(2) : "-"} Hz</span>
              </div>
              {deviceInfo.type != "Deye" ? <div className='d-flex flex-wrap align-items-center bg-light rounded my-3'>
                <span className='svg-icon svg-icon-warning me-5 [ d-none ]'>
                  {/* <KTSVG path='/media/icons/duotune/abstract/abs027.svg' className='svg-icon-1' /> */}
                  <i className="bi bi-align-top"></i>
                </span>

                <div className='flex-grow-1 me-2'>
                  <span className='fw-bolder text-gray-800 fs-6'>Room Temperature</span>
                  {/* <span className='text-muted fw-bold d-block'>Friday, 05 Aug 2022</span> */}
                </div>

                <span className='fw-bolder tc-rubicon-primary py-1 fs-4'>{props.ActiveModel != null ? (props.ActiveModel.room_temp).toFixed(2) : "-"} °C</span>
              </div> :null}
            </div>
            <div className="col-md-4 p-5">
              <h1>Load</h1>
              <div className='d-flex flex-wrap align-items-center bg-light rounded my-3'>
                <span className='svg-icon svg-icon-warning me-5 [ d-none ]'>
                  {/* <KTSVG path='/media/icons/duotune/abstract/abs027.svg' className='svg-icon-1' /> */}
                  <i className="bi bi-align-center"></i>
                </span>

                <div className='flex-grow-1 me-2'>
                  <span className='fw-bolder text-gray-800 fs-6'>Voltage</span>
                  {/* <span className='text-muted fw-bold d-block'>Friday, 05 Aug 2022</span> */}
                </div>

                <span className='fw-bolder tc-rubicon-primary py-1 fs-4'>{props.ActiveModel != null ? (props.ActiveModel.load_voltage).toFixed(2) : "-"} V</span>
              </div>

              <div className='d-flex flex-wrap align-items-center bg-light rounded my-3'>
                <span className='svg-icon svg-icon-warning me-5 [ d-none ]'>
                  {/* <KTSVG path='/media/icons/duotune/abstract/abs027.svg' className='svg-icon-1' /> */}
                  <i className="bi bi-align-top"></i>
                </span>

                <div className='flex-grow-1 me-2'>
                  <span className='fw-bolder text-gray-800 fs-6'>Current</span>
                  {/* <span className='text-muted fw-bold d-block'>Friday, 05 Aug 2022</span> */}
                </div>

                <span className='fw-bolder tc-rubicon-primary py-1 fs-4'>{props.ActiveModel != null ? (props.ActiveModel.load_current).toFixed(2) : "-"} A</span>
              </div>

              <div className='d-flex flex-wrap align-items-center bg-light rounded my-3'>
                <span className='svg-icon svg-icon-warning me-5 [ d-none ]'>
                  {/* <KTSVG path='/media/icons/duotune/abstract/abs027.svg' className='svg-icon-1' /> */}
                  <i className="bi bi-align-bottom"></i>
                </span>

                <div className='flex-grow-1 me-2'>
                  <span className='fw-bolder text-gray-800 fs-6'>Power</span>
                  {/* <span className='text-muted fw-bold d-block'>Friday, 05 Aug 2022</span> */}
                </div>

                <span className='fw-bolder tc-rubicon-primary py-1 fs-4'>{props.ActiveModel != null ? (props.ActiveModel.load_power).toFixed(2) : "-"} W</span>
              </div>
              

            </div>
            <div className="col-md-4 p-5">
              <h1>Battery</h1>
              <div className='d-flex flex-wrap align-items-center bg-light rounded my-3'>
                <span className='svg-icon svg-icon-warning me-5 [ d-none ]'>
                  {/* <KTSVG path='/media/icons/duotune/abstract/abs027.svg' className='svg-icon-1' /> */}
                  <i className="bi bi-align-center"></i>
                </span>

                <div className='flex-grow-1 me-2'>
                  <span className='fw-bolder text-gray-800 fs-6'>Voltage</span>
                  {/* <span className='text-muted fw-bold d-block'>Friday, 05 Aug 2022</span> */}
                </div>

                <span className='fw-bolder tc-rubicon-primary py-1 fs-4'>{props.ActiveModel != null ? (props.ActiveModel.battery_voltage).toFixed(2) : "-"} V</span>
              </div>

              <div className='d-flex flex-wrap align-items-center bg-light rounded my-3'>
                <span className='svg-icon svg-icon-warning me-5 [ d-none ]'>
                  {/* <KTSVG path='/media/icons/duotune/abstract/abs027.svg' className='svg-icon-1' /> */}
                  <i className="bi bi-align-top"></i>
                </span>

                <div className='flex-grow-1 me-2'>
                  <span className='fw-bolder text-gray-800 fs-6'>Current ({props.ActiveModel != null ? props.ActiveModel.battery_current < 0 ? "discharging" : "charging" :"none"})</span>
                  {/* <span className='text-muted fw-bold d-block'>Friday, 05 Aug 2022</span> */}
                </div>

                <span className='fw-bolder tc-rubicon-primary py-1 fs-4'>{props.ActiveModel != null ? (props.ActiveModel.battery_current).toFixed(2) : "-"} A</span>
              </div>

              <div className='d-flex flex-wrap align-items-center bg-light rounded my-3'>
                <span className='svg-icon svg-icon-warning me-5 [ d-none ]'>
                  {/* <KTSVG path='/media/icons/duotune/abstract/abs027.svg' className='svg-icon-1' /> */}
                  <i className="bi bi-align-bottom"></i>
                </span>

                <div className='flex-grow-1 me-2'>
                  <span className='fw-bolder text-gray-800 fs-6'>Power ({props.ActiveModel != null ? props.ActiveModel.battery_power < 0 ? "discharging" : "charging" :"none"})</span>
                  {/* <span className='text-muted fw-bold d-block'>Friday, 05 Aug 2022</span> */}
                </div>

                <span className='fw-bolder tc-rubicon-primary py-1 fs-4'>{props.ActiveModel != null ? (props.ActiveModel.battery_power).toFixed(2) : "-"} W</span>
              </div>
              <div className='d-flex flex-wrap align-items-center bg-light rounded my-3'>
                <span className='svg-icon svg-icon-warning me-5 [ d-none ]'>
                  {/* <KTSVG path='/media/icons/duotune/abstract/abs027.svg' className='svg-icon-1' /> */}
                  <i className="bi bi-align-top"></i>
                </span>

                <div className='flex-grow-1 me-2'>
                  <span className='fw-bolder text-gray-800 fs-6'>SOC</span>
                  {/* <span className='text-muted fw-bold d-block'>Friday, 05 Aug 2022</span> */}
                </div>

                <span className='fw-bolder tc-rubicon-primary py-1 fs-4'>{props.ActiveModel != null ? (props.ActiveModel.battery_soc).toFixed(2) : "-"} %</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <div className='card my-6 [ anim8-border ]' data-card-animate='true'>
        <div className='card-body | d-flex flex-center'>
          <div className='content_holder'>
            <h1 className='text-white'>Aweh</h1>
          </div>
        </div>
      </div> */}
    </>
  )
}
