/* eslint-disable jsx-a11y/anchor-is-valid */
import { useAuth0 } from '@auth0/auth0-react'
import { setGlobalState, useGlobalState } from "../../../modules/global-state/GlobalState";
import { DeviceManager } from '../../../modules/global-state/DeviceManager'
import ApexCharts, { ApexOptions } from 'apexcharts'
import ReactApexChart from 'react-apexcharts';
import { useEffect, useRef, useState } from 'react'
import { getCSS } from '../../../../_metronic/assets/ts/_utils'
import { KTSVG } from '../../../../_metronic/helpers'
import { StatsEC } from './StatsEC'

interface IMyProps {
  onSubmit?: () => void
}

interface IDates {
  start: string,
  end: string
}

export function LifetimePanel(props: IMyProps) {
  const [token, setToken] = useState('')
  const { getAccessTokenSilently } = useAuth0()
  const deviceID = DeviceManager.getDeviceID();
  const [deviceInfo] = useGlobalState('deviceInfo');

  const [chartData, setChartData] = useState(null);

  const todayjs = new Date();
  const [dates, setDates] = useState<IDates>({
    start: todayjs.getFullYear() + '-' + ((todayjs.getMonth() < 9 ? "0" : "") + (todayjs.getMonth() + 1)) + '-' + todayjs.getDate(),
    end: todayjs.getFullYear() + '-' + ((todayjs.getMonth() < 9 ? "0" : "") + (todayjs.getMonth() + 1)) + '-' + todayjs.getDate()
  })

  useEffect(() => {
    getAccessTokenSilently()
      .then((token) => {
        setToken(token)

        loadGraph(token);
      })
  }, [getAccessTokenSilently, setToken, fetch])

  const loadGraph = (localtoken: string) => {
    fetch(process.env.REACT_APP_IOT_API_URL + 'energyconsumption/' + deviceID + "?start=" + dates.start + "&end=" + dates.end, {
      headers: {
        Accept: 'application/json',
        Authorization: 'Bearer ' + localtoken,
      },
    })
      .then((response) => {
        if (response.status == 204) {
          setChartData(null);
        } else {
          response.json().then((data) => {

            setChartData(data);

          })
        }

      })

      .catch()
  }



  function getChartOptions(data: any): any {

    var charttotal = data.solar + data.grid;
    var solar = data.solar / charttotal * 100;
    var boost = data.boost / charttotal * 100;
    var grid = data.grid / charttotal * 100;

    var seriesVal = [solar, grid];
    var labelsVal = ['Solar used', 'Grid used'];
    var colorsVal = ['#0077c8', '#2a3d4d']

    

    return {
      series: seriesVal,
      options: {
        labels: labelsVal,
        colors: colorsVal,
        chart: {
          fontFamily: 'inherit',
          type: 'donut',
          height: 350,
          toolbar: {
            show: false,
          },
          animations: {
            enabled: false,
            dynamicAnimation: {
              enabled: true,
            },
          },
        },
        dataLabels: {
          formatter: function (val: any) {
            return val.toFixed(2) + ' %'
          },
        },
        fill: {
          opacity: 1,
        },
        tooltip: {
          fillSeriesColor: false,
          theme: 'light',
          style: {
            fontSize: '12px',
          },
          y: {
            formatter: function (val: any) {
              return val.toFixed(2) + '%'
            },
          },
        },
        plotOptions: {
          pie: {
            expandOnClick: false,
          },
        }
      }
    }
  }

  return (
    <>
      <div className='container'>
        <div className='row'>
          <div className='col-lg-5 col-xl-4'>
            <div className='d-flex justify-content-between'>
              <div className='form-group | col me-1'>
                <label className='form-label'>From</label>
                <input type='date' className='form-control' value={dates.start} onChange={(event) => setDates({ ...dates, start: event.target.value })} />
              </div>
              <div className='form-group ms-3X | col ms-1'>
                <label className='form-label'>To</label>
                <input type='date' className='form-control' value={dates.end} onChange={(event) => setDates({ ...dates, end: event.target.value })} />
              </div>
            </div>
            <div className='d-flex mt-4'>
              <a
                className='btn btn-primary w-100'
                type='button'
                onClick={() => loadGraph(token)}
                href='#lifetime_graph'
              >
                <i className='bi bi-check2 fs-2'></i>Get Data
              </a>
            </div>
            {/* ----------- */}
            <div className='mt-6'>
              {chartData == null ?
                null
                :
                (<StatsEC data={chartData} />)
              }
            </div>
          </div>
          <div className='col-lg-7 col-xl-8'>
            {chartData == null ?
              <div className='mb-lg-15 alert alert-info text-center mt-20'>
                <div className='alert-text font-weight-bold'>There is no data for the chart during the selected period.</div>
              </div> :
              (// @ts-ignore
              <ReactApexChart series={getChartOptions(chartData).series} options={getChartOptions(chartData).options} type="donut"></ReactApexChart>)
            }

          </div>
        </div>
      </div>
    </>
  )
}
