import { useAuth0 } from '@auth0/auth0-react'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useGlobalState } from '../../../modules/global-state/GlobalState'
import { DeviceManager } from '../../../modules/global-state/DeviceManager'
interface IDates {
  start: string,
  end: string
}

interface IError {
  dateS: string,
  description: string,
  severity: string
}

export function FunctionNotificationsPage() {
  const [token, setToken] = useState('')
  const { getAccessTokenSilently } = useAuth0()
  const [notifications, setNotifications] = useState<IError[]>([])
  const deviceID = DeviceManager.getDeviceID();
  const navigate = useNavigate()
  const todayjs = new Date();
  const [dates, setDates] = useState<IDates>({
    start: todayjs.getFullYear() + '-' + ((todayjs.getMonth() < 9 ? "0" : "") + (todayjs.getMonth() + 1)) + '-' + todayjs.getDate(),
    end: todayjs.getFullYear() + '-' + ((todayjs.getMonth() < 9 ? "0" : "") + (todayjs.getMonth() + 1)) + '-' + todayjs.getDate()
  })

  useEffect(() => {
    getAccessTokenSilently()
      .then((token) => {
        setToken(token)

        loadList(token);
      })
  }, [getAccessTokenSilently, setToken, fetch])

  const loadList = (localtoken: string) => {
    fetch(process.env.REACT_APP_IOT_API_URL + 'function/notifications/' + deviceID + "?start=" + dates.start + "&end=" + dates.end, {
      headers: {
        Accept: 'application/json',
        Authorization: 'Bearer ' + localtoken,
      },
    })
      .then((response) => {

        response.json().then((data) => {
          var newList: IError[] = [];
          data.forEach((element: any) => {
            newList.push({ dateS: element.dateS, description: element.description, severity: element.severity });
          });
          setNotifications(newList);

        })

      })

      .catch()
  }

  return (
    <div className='card card-custom shadow'>
      <div className='card-header'>
        <div className='card-title'>
          <button
            onClick={() => navigate('/function-view')}
            className='btn btn-light-dark me-5'
            type='button'
          >
            <i className='bi bi-arrow-left'></i> Back
          </button>
          <h3>Notifications</h3>
        </div>
      </div>
      <div className='card-body pt-4 pb-0'>
        <div className='row'>
          <div className='col-lg-6'>
            <div className='d-flex justify-content-between'>
              <div className='form-group | col me-1'>
                <label className='form-label'>From</label>
                <input type='date' className='form-control' value={dates.start} onChange={(event) => setDates({ ...dates, start: event.target.value })} />
              </div>
              <div className='form-group ms-3X | col ms-1'>
                <label className='form-label'>To</label>
                <input type='date' className='form-control' value={dates.end} onChange={(event) => setDates({ ...dates, end: event.target.value })} />
              </div>
            </div>
          </div>
          <div className='col-lg-6 align-items-end d-flex'>
            <a className='btn btn-primary me-4' type='button' onClick={() => loadList(token)}>
              <i className='bi bi-check2 fs-2'></i>Filter
            </a>

            <button className='btn btn-light-warning btn-icon'>
              <i className='bi bi-arrow-counterclockwise fs-2x'></i>
            </button>
          </div>
        </div>
      </div>
      <div className='card-body pt-0 pb-5 d-flex flex-column flex-center'>
        <table className='table table-row-dashed table-row-gray-300 gy-7'>
          <thead>
            <tr className='fw-bolder fs-6 text-gray-800'>
              <th className='w-200px'>Date</th>
              <th>Description</th>
            </tr>
          </thead>
          <tbody>
            {notifications.map(value => {
              return <tr>
                <td>
                  <div className='d-flex'>
                    <span className='me-2'>
                      <i className={'bi bi-bell-fill ' + (value.severity == "Low" ? '[ status-success ]' : (value.severity == "Med" ? '[ status-warning ]' : '[ status-danger ]'))}></i>
                    </span>
                    <span>{value.dateS}</span>
                  </div>
                </td>
                <td>
                  <span className='lc-2'>
                    {value.description}
                  </span>
                </td>
              </tr>
            })}

            {/* <tr>
              <td>
                <div className='d-flex'>
                  <span className='me-2'>
                    <i className='bi bi-bell-fill [ status-success ]'></i>
                  </span>
                  <span>01 Jan 2022</span>
                </div>
              </td>
              <td>
                <span className='lc-2'>
                  Lorem ipsum dolor sit amet consectetur adipisicing elit. Aliquid laborum nesciunt
                  error? Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptates magnam
                  nihil, possimus suscipit delectus odio distinctio? Placeat repudiandae rerum,
                  explicabo consectetur veritatis facere, voluptate accusantium, exercitationem
                  aliquam vitae assumenda molestias.
                </span>
              </td>
            </tr>
            <tr>
              <td>
                <div className='d-flex'>
                  <span className='me-2'>
                    <i className='bi bi-bell-fill [ status-warning ]'></i>
                  </span>
                  <span>01 Jan 2022</span>
                </div>
              </td>
              <td>
                <span className='lc-2'>
                  Lorem ipsum dolor sit amet consectetur adipisicing elit. Aliquid laborum nesciunt
                  error? Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptates magnam
                  nihil, possimus suscipit delectus odio distinctio? Placeat repudiandae rerum,
                  explicabo consectetur veritatis facere, voluptate accusantium, exercitationem
                  aliquam vitae assumenda molestias.
                </span>
              </td>
            </tr> */}
          </tbody>
        </table>
        {notifications.length <= 0 ? (
          <h2 className='w-100 text-gray-400 text-center'>No errors</h2>
        ) : (
          <div className='container'>
            {/* {notifications.map((notification) => {
              return <NotificationEntry info={notification} />
            })} */}
          </div>
        )}
      </div>

      {/* <div className='card-footer'></div> */}
    </div>
  )
}
