import {lazy, FC, Suspense, useEffect} from 'react'
import {Route, Routes, Navigate, useNavigate} from 'react-router-dom'
import {MasterLayout} from '../../_metronic/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import {DashboardWrapper} from '../pages/dashboard/DashboardWrapper'
import {MenuTestPage} from '../pages/MenuTestPage'
import {getCSSVariableValue} from '../../_metronic/assets/ts/_utils'
import { ActiveView } from '../pages/active-view/ActiveView'
import { FunctionView } from '../pages/function-view/FunctionView'
import { EnergyConsumption } from '../pages/energy-consumption/EnergyConsumption'
import { GeyserActivity } from '../pages/geyser-activity/GeyserActivity'
import { DeviceActivity } from '../pages/device-activity/DeviceActivity'
import { DeviceManagement } from '../pages/device-management/DeviceManagement'
import { UserManagement } from '../pages/user-management/UserManagement'
import { FunctionNotificationsPage } from '../pages/function-view/pages/NotificationsPage'
import { FunctionSupportPage } from '../pages/function-view/pages/SupportPage'
import { FunctionSettingsPage } from '../pages/function-view/pages/SettingsPage'
import { ProfilePage } from '../pages/profile/ProfilePage'
import { SwitchDevice } from '../pages/switch-device/SwitchDevice'
import { setGlobalState, useGlobalState } from '../modules/global-state/GlobalState'
import { DeviceManager } from '../modules/global-state/DeviceManager'
import { useAuth0 } from '@auth0/auth0-react'

const PrivateRoutes = () => {
  const BuilderPageWrapper = lazy(() => import('../pages/layout-builder/BuilderPageWrapper'))
  const WizardsPage = lazy(() => import('../modules/wizards/WizardsPage'))
  const AccountPage = lazy(() => import('../modules/accounts/AccountPage'))
  const WidgetsPage = lazy(() => import('../modules/widgets/WidgetsPage'))
  const ChatPage = lazy(() => import('../modules/apps/chat/ChatPage'))

  const {getAccessTokenSilently} = useAuth0()
  const deviceID = DeviceManager.getDeviceID();
  const navigate = useNavigate()

  useEffect(() => {
    getAccessTokenSilently().then(token => {
      fetch(process.env.REACT_APP_IOT_API_URL + 'users/get', {
        headers: {
          Accept: 'application/json',
          Authorization: 'Bearer ' + token
        }
      }).then(response => {
        response.clone().json().then(data => {
          setGlobalState('userID', data.userID)
        })
      }).catch()

      if (deviceID == "" || deviceID == "-1" || deviceID == null)
        navigate('/switch-device')
      else {
        fetch(process.env.REACT_APP_IOT_API_URL + 'devices/get?deviceID=' + deviceID, {
          headers: {
            Accept: 'application/json',
            Authorization: 'Bearer ' + token
          }
        }).then(response => {
          if (![200, 204].includes(response.status)) return

          response.clone().json().then(data => setGlobalState('deviceInfo', data))
        }).catch()
      }
    })
  }, [navigate, fetch, getAccessTokenSilently, setGlobalState])

  return (
    <Routes>
      <Route element={<MasterLayout isPublic={false}/>}>
        <Route path='/switch-device' element={<SwitchDevice />} />
        {/* Pages */}
        <Route path='/dashboard' element={<DashboardWrapper />} />
        <Route path='/' element={<Navigate to='/active-view' />} />
        <Route path='/active-view' element={<ActiveView />} />
        <Route path='/function-view' element={<FunctionView />} />
        <Route path='/function-view/notifications' element={<FunctionNotificationsPage />} />
        <Route path='/function-view/support' element={<FunctionSupportPage />} />
        <Route path='/function-view/settings' element={<FunctionSettingsPage />} />
        <Route path='/energy-consumption' element={<EnergyConsumption />} />
        <Route path='/geyser-activity' element={<GeyserActivity />} />
        <Route path='/device-activity' element={<DeviceActivity />} />
        <Route path='/user-management/users' element={<UserManagement />} />
        <Route path='/device-management/*' element={<DeviceManagement />} />
        <Route path='/profile' element={<ProfilePage />} />

        <Route path='builder' element={<BuilderPageWrapper />} />
        <Route path='menu-test' element={<MenuTestPage />} />
        {/* Lazy Modules */}
        <Route
          path='crafted/pages/wizards/*'
          element={
            <SuspensedView>
              <WizardsPage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/widgets/*'
          element={
            <SuspensedView>
              <WidgetsPage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/account/*'
          element={
            <SuspensedView>
              <AccountPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/chat/*'
          element={
            <SuspensedView>
              <ChatPage />
            </SuspensedView>
          }
        />
        {/* Page Not Found */}
        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  )
}

const SuspensedView: FC = ({children}) => {
  const baseColor = getCSSVariableValue('--bs-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={TopBarProgress}>{children}</Suspense>
}

export {PrivateRoutes}
