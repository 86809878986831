import React from "react"
import ReactDOM from "react-dom";
import { useRef, useState } from "react"
import { User } from "../../../../interfaces/User"
import Toast from 'react-bootstrap/Toast';
import ToastInfo from '../../../../interfaces/ToastInfo';
import ToastContainer from 'react-bootstrap/ToastContainer'
import { KTSVG } from "../../../../../_metronic/helpers";
import CustomToast from "../../../../modules/utility/CustomToast"

interface IMyProps {
  token: string
  deviceID: any
  onSuccess: () => void
}

export function InviteUserModal(props: IMyProps) {
  const [userEmail, setEmail] = useState('')
  const btnClose = useRef<any>(null)


  function inviteUserPost() {
    fetch(process.env.REACT_APP_IOT_API_URL + 'users/invite?' + new URLSearchParams({
      email: userEmail,
      deviceID: props.deviceID
    }).toString(), {
      method: 'PUT',
      headers: {
        Accept: 'application/json',
        Authorization: 'Bearer ' + props.token
      }
    }).then(response => {

      if (response.status !== 200 && response.status !== 204) {
        CustomToast({ type: 'error', msg: 'An error has occurred while trying to invite the user.' });

        return;
      }

      CustomToast({ type: 'success', msg: 'User has been successfully invited to this device.' });
      

      btnClose.current!.click()
      props.onSuccess();
    }).catch(() => {
      CustomToast({ type: 'error', msg: 'An error has occurred while trying to invite the user.' });
      
      return;
    })
  }

  return <>
    
    <div className='modal fade' tabIndex={-1} id='invite_user_modal'>
      <div className='modal-dialog'>
        <div className='modal-content'>
          <div className='modal-header'>
            <h5 className='modal-title'>
              Send Invite Link
            </h5>
            <div
              className="btn btn-icon btn-sm btn-active-light-primary ms-2"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <KTSVG
                path="/media/icons/duotune/arrows/arr061.svg"
                className="svg-icon svg-icon-2x"
              />
            </div>
          </div>

          <div className='modal-body'>
            <div className='mb-10 d-flex flex-center flex-column gap-2'>
              <h4 className='text-dark text-center'>Enter the Email address of the person you want to Invite to have access to this device</h4>
              <input className='form-control' placeholder='Email Address' type='email' onChange={event => {
                setEmail(event.target.value)
              }} />
            </div>
          </div>

          <div className='modal-footer d-flex flex-center gap-5'>
            <button ref={btnClose} className='btn btn-light w-150px' data-bs-dismiss='modal'>Close</button>
            <button className='btn btn-primary w-150px' onClick={() => inviteUserPost()}>Send invite</button>
          </div>
        </div>
      </div>
    </div>
  </>
}