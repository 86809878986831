import React from 'react'
import {KTSVG} from '../../_metronic/helpers/components/KTSVG'
import {AlertTransmission} from './AlertTransmission'
import {StatsWidget3} from './StatsWidget3'
import {UpdateWidgetBlock} from './UpdateWidget'

export function StatsWidget11() {
  return (
    <>
      <div className='[ d-none ] colX bg-light-primary px-6 py-8 rounded-2'>
        <span className='svg-icon svg-icon-2hx svg-icon-info me-4 mb-5 mb-sm-0'>
          <KTSVG
            path='/media/icons/duotune/abstract/abs027.svg'
            className='svg-icon-muted svg-icon-2hx'
          />
        </span>
      </div>
      {/* ------ */}
      <div className='[ d-none ] card card-xxl bg-bodyX bg-light-primary'>
        <div className='card-body d-flex flex-column justify-content-between'>
          <span className='svg-icon svg-icon-2hx svg-icon-info me-4 mb-5 mb-sm-0'>
            <KTSVG
              path='/media/icons/duotune/general/gen002.svg'
              className='svg-icon svg-icon-success svg-icon-2hx ms-n1 flex-grow-1'
            />
          </span>
          <div className='d-flex flex-column'>
            <div className='text-dark fw-bold fs-1 mb-0 mt-5'>8,600</div>
            <div className='text-muted fw-semibold fs-6'>Lorem, ipsum dolor.</div>
          </div>
        </div>
      </div>
      {/* <AlertTransmission /> */}
      <StatsWidget3 />

      <UpdateWidgetBlock />
    </>
  )
}
