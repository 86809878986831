

class DeviceManager{

    static deviceID = '';

    static getDeviceID = () => {

        if(DeviceManager.deviceID == '')
        {
           DeviceManager.deviceID = <string>localStorage.getItem('deviceID');
        }

        return DeviceManager.deviceID;
    } 


}

export {DeviceManager};