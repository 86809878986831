import { FormikValues } from 'formik'
import { FC, useEffect, useRef, useState } from 'react'
import Form from 'react-bootstrap/esm/Form'
import { StepperComponent } from '../../../_metronic/assets/ts/components'
import { KTSVG } from '../../../_metronic/helpers/components/KTSVG'
import {
  createAccountSchemas,
  ICreateAccount,
  inits,
} from '../wizards/components/CreateAccountWizardHelper'
import CustomToast from "../../modules/utility/CustomToast"

import CustomerDetails from './CustomerDetails'
import { UploadPictures } from './UploadPictures'
import CustomerSignOff from './CustomerSignOff'
import { Rating } from './Rating'
import Signature from './Signature'

import { useSearchParams } from 'react-router-dom'
import IFile from '../../interfaces/File'

interface IValidationResponse {
  isValid: boolean
  status: string
  isExistingEmail: boolean
  invitedByEmail: string
  invitedByFullName: string
}
export function EnrollmentWizard() {
  const stepperRef = useRef<HTMLDivElement | null>(null)
  const stepper = useRef<StepperComponent | null>(null)
  const [currentSchema, setCurrentSchema] = useState(createAccountSchemas[0])
  const [initValues] = useState<ICreateAccount>(inits)
  const [isSubmitButton, setSubmitButton] = useState(false)

  const [searchParams, setSearchParams] = useSearchParams()
  const hash = searchParams.get('hash')
  const maxSteps = 5

  const [step, setStep] = useState(1)
  const [latestStep, setLatestStep] = useState(1);
  const [validationResponse, setValidation] = useState<IValidationResponse>()

  //Model info
  const [userInfo, setUserInfo] = useState<any>(null)
  const [pictures, setPictures] = useState<IFile[]>([])
  const [signDrew, setSignDrew] = useState()
  const [signature, setSignature] = useState<IFile | null>(null)
  const [rating, setRating] = useState<any>(1)

  const [token, setToken] = useState('')
  const [isValid, setValid] = useState(false)
  const [error, setError] = useState('')


  //Refs
  const custDetailsRef = useRef<any>()
  const signoffRef = useRef<any>()
  const signRef = useRef<any>()

  function addPicture(newFile: IFile) {
    setPictures((pics) => [...pics, newFile])
  }

  function addSignature(newFile: IFile) {
    setSignature(newFile)
  }

  function setRatingState(val: any) {
    setRating(val)
  }

  function getValidationMessage() {
    if (validationResponse?.isValid == false) {
      if (validationResponse?.status == 'Expired') return 'This invite has expired.'
      if (validationResponse?.status == 'Linked') return 'This invite has already been linked.'
      if (validationResponse?.status == 'Declined') return 'This invite has already been declined.'
      return 'An unknown error has occurred, please try again.'
    } else return ''
  }

  function onNext(newstep: number | null) {
    if (!validationResponse?.isValid) return

    switch (step) {
      case 1: {
        var custForm = custDetailsRef.current
        custForm.validateForm()
        if (isValid) {
          if (newstep == null) {
            setStep(step + 1)
          } else {
            setStep(newstep!);

          }
          if (step > latestStep)
            setLatestStep(step);
        }

        setValid(false)
        return
      }
      case 2: {
        if (pictures.length > 0) {
          if (newstep == null) {
            setStep(step + 1)
          } else {
            setStep(newstep!);
          }
          if (step > latestStep)
            setLatestStep(step);
        } else {
          CustomToast({ type: 'error', msg: 'Please upload at least one profile picture.' });

        }

        setValid(false)
        return
      }
      case 3: {
        var signForm = signoffRef.current
        if (signForm.validateForm()) {
          if (newstep == null)
            setStep(step + 1)
          else {
            setStep(newstep!);
          }
          if (step > latestStep)
            setLatestStep(step);
        }
        else {
          CustomToast({ type: 'error', msg: 'Please check all confirmation boxes to accept.' });
        }

        setValid(true)
        return
      }
      case 4: {
        if (isValid) {
          if (newstep == null)
            setStep(step + 1)
          else {
            setStep(newstep!);
          }
          if (step > latestStep)
            setLatestStep(step);
        }
        return
      }
      case 5: {
        if (newstep != null && newstep! < 5) {
          setStep(newstep!);
          return;
        }

        if (signature != null || signDrew != null) {
          postComplete()
        } else {
          CustomToast({ type: 'error', msg: 'Please upload a signature for the device.' });

        }

        setValid(false)
        return
      }
    }
  }

  function postComplete() {
    if (userInfo != null) userInfo.Pictures = pictures

    fetch(process.env.REACT_APP_IOT_API_URL + 'devices/acceptownerinvite?hash=' + hash, {
      method: 'PUT',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        Signature: signature || signDrew,
        user: userInfo,
        InstallerRating: rating,
      }),
    })
      .then((response) => {
        if (response.status !== 200 && response.status !== 204) {
          CustomToast({ type: 'error', msg: 'An error has occurred while trying to complete the enrollment.' });

          return
        }

        CustomToast({ type: 'success', msg: 'Customer enrollment has been successfully completed.' });
        setTimeout(() => {
          window.location.href = "/";
        }, 1000);
      })
      .catch(() => {
        CustomToast({ type: 'error', msg: 'An error has occurred while trying to complete the enrollment.' });

      })
  }

  useEffect(() => {
    fetch(process.env.REACT_APP_IOT_API_URL + 'devices/validateownerinvite?hash=' + hash, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
      },
    })
      .then((response) => {
        if (response.status !== 200) return

        response.json().then((data) => {
          setValidation(data)
          if (data.isExistingEmail) {
            setStep(3);
            setLatestStep(3);
          }
        })
      })
      .catch(() => {
        setValidation({
          isValid: false,
          status: 'Unknown',
          isExistingEmail: false,
          invitedByEmail: '',
          invitedByFullName: '',
        })
      })
  }, [])

  useEffect(() => {

    if (step == 5) {
      signRef.current.initCanvas();
    }
  }, [step]);

  const loadStepper = () => {
    stepper.current = StepperComponent.createInsance(stepperRef.current as HTMLDivElement)
  }

  /*const prevStep = () => {
    if (!stepper.current) {
      return
    }

    setSubmitButton(stepper.current.currentStepIndex === stepper.current.totatStepsNumber! - 1)

    stepper.current.goPrev()

    setCurrentSchema(createAccountSchemas[stepper.current.currentStepIndex - 1])
  }*/

  /*const submitStep = (values: ICreateAccount, actions: FormikValues) => {
    if (!stepper.current) {
      return
    }

    setSubmitButton(stepper.current.currentStepIndex === stepper.current.totatStepsNumber! - 1)

    setCurrentSchema(createAccountSchemas[stepper.current.currentStepIndex])

    if (stepper.current.currentStepIndex !== stepper.current.totatStepsNumber) {
      stepper.current.goNext()
    } else {
      stepper.current.goto(1)
      actions.resetForm()
    }
  }*/

  useEffect(() => {
    if (!stepperRef.current) {
      return
    }

    loadStepper()
  }, [stepperRef])

  return (
    <>
      <div className='card [ demo_class ]'>
        <div className='card-header'>
          <h3 className='card-title'>Customer Device Enrollment</h3>
        </div>
        <div className='card-body'>
          <div
            ref={stepperRef}
            className='stepper stepper-links d-flex flex-column'
            id='rubicon_enroll_stepper'
          >
            <div className='stepper-nav mb-5'>
              {validationResponse == null || validationResponse.isExistingEmail == false ?
                <><div className={step == 1 ? "stepper-item current" : "stepper-item"} data-kt-stepper-element='nav' onClick={() => onNext(1)}>
                  <h3 className='stepper-title'>Customer Details</h3>
                </div>

                  <div className={step == 2 ? "stepper-item current" : "stepper-item"} data-kt-stepper-element='nav' onClick={() => { if (latestStep >= 1) onNext(2) }}>
                    <h3 className='stepper-title'>Upload Pictures</h3>
                  </div>
                </> : null}

              <div className={step == 3 ? "stepper-item current" : "stepper-item"} data-kt-stepper-element='nav' onClick={() => { if (latestStep >= 2) onNext(3) }}>
                <h3 className='stepper-title'>Customer Sign-off</h3>
              </div>

              <div className={step == 4 ? "stepper-item current" : "stepper-item"} data-kt-stepper-element='nav' onClick={() => { if (latestStep >= 3) onNext(4) }}>
                <h3 className='stepper-title'>Rating</h3>
              </div>

              <div className={step == 5 ? "stepper-item current" : "stepper-item"} data-kt-stepper-element='nav' onClick={() => { if (latestStep >= 4) onNext(5) }}>
                <h3 className='stepper-title'>Upload a Single Signature</h3>
              </div>
            </div>

            <Form className='mx-auto mw-600px w-100 pt-15x pb-10x' id='rubicon_enroll_form'>
              {!validationResponse?.isValid ? (
                validationResponse?.status != '' ? (
                  <div className='alert alert-danger text-center fs-1 m-0'>
                    <div className='alert-text font-weight-bold'>{getValidationMessage()}</div>
                  </div>
                ) : (
                  <div></div>
                )
              ) : <>
                {validationResponse.isExistingEmail == false ?
                  <>
                    <div className={step == 1 ? 'current' : ""} data-kt-stepper-element='content'>
                      <CustomerDetails
                        ref={custDetailsRef}
                        userInfo={userInfo}
                        setUserInfo={setUserInfo}
                        isValid={isValid}
                        setValid={setValid}
                        validationResponse={validationResponse}
                      />
                    </div>

                    <div className={step == 2 ? 'current' : ""} data-kt-stepper-element='content'>
                      <UploadPictures pictures={pictures} addPicture={addPicture} />
                    </div>
                  </>
                  : null}


                <div className={step == 3 ? 'current' : ""} data-kt-stepper-element='content'>
                  <CustomerSignOff ref={signoffRef} setValid={setValid} setError={setError} />
                </div>

                <div className={step == 4 ? 'current' : ""} data-kt-stepper-element='content'>
                  <Rating setRating={setRatingState} />
                </div>

                <div className={step == 5 ? 'current' : ""} data-kt-stepper-element='content'>
                  <Signature ref={signRef} addSignature={addSignature} setSignDrew={setSignDrew} isUser={true} />
                </div>

                <div className='d-flex flex-stack pt-15'>
                  <div className='mr-2'>
                    <button
                      type='button'
                      className='btn btn-lg btn-light-primary me-3'
                      data-kt-stepper-action='previous'
                    >
                      <KTSVG
                        path='/media/icons/duotune/arrows/arr063.svg'
                        className='svg-icon-4 me-1'
                      />
                      Back
                    </button>
                  </div>

                  <div>
                    <a className='btn btn-lg btn-primary me-3' onClick={() => {
                      onNext(null);
                    }}>
                      <span className='indicator-label'>
                        {step < 5 && 'Continue'}
                        {step == 5 && 'Submit'}
                        <KTSVG
                          path='/media/icons/duotune/arrows/arr064.svg'
                          className='svg-icon-3 ms-2 me-0'
                        />
                      </span>
                    </a>
                  </div>
                </div>
              </>}
            </Form>
          </div>
        </div>
      </div>
    </>
  )
}
