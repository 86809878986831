import { Suspense } from 'react'
import { Outlet } from 'react-router-dom'
import { I18nProvider } from '../_metronic/i18n/i18nProvider'
import { LayoutProvider, LayoutSplashScreen } from '../_metronic/layout/core'
import { MasterInit } from '../_metronic/layout/MasterInit'
import { AuthInit } from './modules/auth'
import { HubConnectionBuilder } from '@microsoft/signalr';
import React, { useState, useEffect, useRef } from 'react';
import { setGlobalState, useGlobalState } from "./modules/global-state/GlobalState";
import { DeviceManager } from './modules/global-state/DeviceManager'
import { useAuth0 } from "@auth0/auth0-react"

const App = () => {

  const [connection, setConnection] = useState<any | null>(null);
  const [ActiveModel, setActiveModel] = useGlobalState('ActiveModel');
  const [ActiveInverterModel, setActiveInverterModel] = useGlobalState('ActiveInverterModel');
  const [FunctionModel, setFunctionModel] = useGlobalState('FunctionModel');
  const [userID] = useGlobalState('userID')
  const { user, getAccessTokenSilently } = useAuth0()


  useEffect(() => {

    if (connection == null) {
      getAccessTokenSilently().then(token => {
        fetch(process.env.REACT_APP_IOT_API_URL + 'users/get', {
          headers: {
            Accept: 'application/json',
            Authorization: 'Bearer ' + token
          }
        }).then(response => {
          if (response.status !== 200 && response.status !== 204) return

          response.json().then(data => {
            const newConnection = new HubConnectionBuilder()
              .withUrl(process.env.REACT_APP_IOT_API_URL + 'ViewDeviceHub?userID=' + data.userID)
              .withAutomaticReconnect()
              .build();

            setConnection(newConnection);
          });
        })
      });


    }
  }, []);

  useEffect(() => {
    if (connection) {
      connection.start()
        .then((result: any) => {


          connection.on('FunctionActiveViewUpdate', (activemessage: any, functmessage: any) => {
            var activeJson = JSON.parse(activemessage);
            var functJson = JSON.parse(functmessage);


            if (functJson.DeviceIdentifier == DeviceManager.getDeviceID()) {
              setFunctionModel(Object.assign({}, FunctionModel, {
                DeviceIdentifier: functJson.DeviceIdentifier,
                isAway: functJson.isAway,
                isBoost: functJson.isBoost,
                isGrid: functJson.isGrid
              }));

              setActiveModel(Object.assign({}, ActiveModel, {
                ImportedToday: (activeJson.ImportedToday < 0) ? 0 : activeJson.ImportedToday,
                PVProductedToday: (activeJson.PVProductedToday < 0) ? 0 : activeJson.PVProductedToday,
                TotalConsumedToday: (activeJson.TotalConsumedToday < 0) ? 0 : activeJson.TotalConsumedToday,
                InstantImported: activeJson.InstantImported,
                InstantPV: activeJson.InstantPV,
                DateReceivedS: activeJson.DateReceivedS,
                TimeReceivedS: activeJson.TimeReceivedS,
                PowerMode: activeJson.PowerMode,
                GeyserTemp: activeJson.GeyserTemp,
                TimeToHot: activeJson.TimeToHot,
                isGrid: functJson.isGrid,
                isConnected: true,
                NewSolarFirmware: activeJson.NewSolarFirmware,
                NewSolarFirmwareID: activeJson.NewSolarFirmwareID,
                NewEspFirmware: activeJson.NewEspFirmware,
                NewEspFirmwareID: activeJson.NewEspFirmwareID
              }));
            }
          });

          connection.on('ActiveInverterViewUpdate', (activemessage: any) => {
            var activeJson = JSON.parse(activemessage);

            var data = activeJson;
            if (activeJson.DeviceIdentifier == DeviceManager.getDeviceID()) {
              
              setActiveInverterModel(Object.assign({}, ActiveInverterModel, {
                    DateReceivedS: data.DateReceivedS,
                    TimeReceivedS: data.TimeReceivedS,
                    grid_frequency: data.grid_frequency,
                    grid_voltage: data.grid_voltage,
                    grid_current: data.grid_current,
                    grid_power: data.grid_power,
                    room_temp: data.room_temp,
                    environmental_temp: data.environmental_temp,
                    load_voltage: data.load_voltage,
                    load_current: data.load_current,
                    load_power: data.load_power,
                    load_rate: data.load_rate,
                    battery_voltage: data.battery_voltage,
                    battery_current: data.battery_current,
                    battery_power: data.battery_power,
                    battery_temp: data.battery_temp,
                    battery_soc: data.battery_soc,
                    consumption: data.consumption,
                    isConnected: true
              }));
            }
          });

        })
        .catch((e: any) => console.log('Connection failed: ', e));
    }
  }, [connection]);

  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <I18nProvider>
        <LayoutProvider>
          <AuthInit>
            <Outlet />
            <MasterInit />
            <>
              <div id="toastr-div"></div>
              <div id="modal-div"></div>
            </>
          </AuthInit>
        </LayoutProvider>
      </I18nProvider>
    </Suspense>
  )
}

export { App }
