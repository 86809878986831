import {UsagePanel} from './components/UsagePanel'
import {StatsPanel} from './components/StatsPanel'

export function DeviceActivity() {
    return (
      <div className='card card-custom shadow'>
        <div className='card-header card-header-stretch'>
          <h3 className='card-title'>Device Activity</h3>
          <div className='card-toolbar'>
            <ul className='nav nav-tabs nav-line-tabs nav-line-tabs-2x nav-stretch fs-6 border-0'>
              <li className='nav-item'>
                <a className='nav-link active' data-bs-toggle='tab' href='#usage_pane'>
                  Usage
                </a>
              </li>
              <li className='nav-item'>
                <a className='nav-link' data-bs-toggle='tab' href='#stats_pane'>
                  Stats
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div className='card-body'>
          <div className='tab-content' id='myTabContent'>
            <div className='tab-pane fade show active' id='usage_pane' role='tabpanel'>
              <UsagePanel />
            </div>
            <div className='tab-pane fade' id='stats_pane' role='tabpanel'>
              <StatsPanel />
            </div>
          </div>
        </div>
      </div>
    )
  }
  