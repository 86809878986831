import {KTSVG} from '../../_metronic/helpers'

export function DeviceNotConnected() {
  return (
    <div className='[ alert alert-custom danger alert-dismissible ] [ d-flex flex-column flex-sm-row align-items-center justify-content-between ] [ p-5 mb-10X ]'>
      <div className='d-flex align-items-center'>
        <span className='svg-icon svg-icon-2hx svg-icon-info me-4 mb-5 mb-sm-0'>
          <KTSVG
            path='/media/icons/duotune/electronics/elc007.svg'
            className='svg-icon-muted svg-icon-2hx'
          />
        </span>
        <div className='d-flex flex-column text-primary pe-0 pe-sm-10'>
          <h5 className='mb-0 text-capitalize'>Device not connected - no transmission in last 10 minutes</h5>
        </div>
      </div>
      <div>
        <a
          href='javascript:;'
          className='btn btn-sm btn-secondary fw-semibold'
          data-bs-dismiss='alert'
        >
          Dismiss
        </a>
      </div>
    </div>
  )
}
