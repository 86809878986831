import {useAuth0} from '@auth0/auth0-react'
import { DecimationAlgorithm } from 'chart.js'
import React, {useEffect, useRef, useState} from 'react'
import {useNavigate} from 'react-router-dom'
import { setSyntheticLeadingComments } from 'typescript'
import {useGlobalState} from '../../../modules/global-state/GlobalState'
import { DeviceManager } from '../../../modules/global-state/DeviceManager'
import CustomToast from "../../../modules/utility/CustomToast"

interface ISettings {
  solarSetpoint: number,
  gridSetpoint: number,
  boostSetpoint: number,
  useJustInTime: boolean,
  criticalTime1: string,
  criticalTime2: string,
  GeyserElementPower: number,
  GeyserSize: number
}

export function FunctionSettingsPage() {
  const [token, setToken] = useState('')
  const {getAccessTokenSilently} = useAuth0()
  const navigate = useNavigate()
  const deviceID = DeviceManager.getDeviceID();

  const [settings, setSettings] = useState<ISettings>({
    solarSetpoint: 0,
    gridSetpoint: 0,
    boostSetpoint: 0,
    useJustInTime: false,
    criticalTime1: "0",
    criticalTime2: "0",
    GeyserElementPower: 0,
    GeyserSize: 0
  });

  function onClick() {

    if(settings.gridSetpoint > 70 || settings.gridSetpoint < 0 || settings.solarSetpoint > 70 || settings.solarSetpoint < 0 || settings.boostSetpoint > 70 || settings.boostSetpoint < 0)
    { 
      CustomToast({type: 'error', msg: 'Set points may not be less than 0 or greater than 70.'});
      return;
    }

    fetch(process.env.REACT_APP_IOT_API_URL + 'function/savesettings/' + deviceID, {
      method: 'POST',
      body: JSON.stringify({
        
          setpoint: {
              command: {
                aC_geyser_setpoint: settings.gridSetpoint,
                pV_geyser_setpoint: settings.solarSetpoint,
                boost_geyser_setpoint: settings.boostSetpoint,
              }
          },
          criticalTime1: settings.criticalTime1,
          criticalTime2: settings.criticalTime2,
          GeyserElementPower: settings.GeyserElementPower,
          GeyserSize: settings.GeyserSize,
          JustInTime: settings.useJustInTime
      }),
      headers: {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
        'content-type': 'application/json'
      },
    }).then((response) => {
      if ([200, 204].includes(response.status)){
        CustomToast({type: 'success', msg: 'Device settings has been successfully updated.'});
        
      }else{
        CustomToast({type: 'error', msg: 'An error has occurred while trying to update the device settings.'});
      }
      }).catch(() => {
        CustomToast({type: 'error', msg: 'An error has occurred while trying to update the device settings.'});
       
      })
  }

  useEffect(() => {
    getAccessTokenSilently().then((token) => {
      setToken(token)

      fetch(process.env.REACT_APP_IOT_API_URL + 'function/get/' + deviceID, {
        headers: {
          Accept: 'application/json',
          Authorization: 'Bearer ' + token,
        },
      })
        .then((response) => {
          if ([200, 204].includes(response.status))
            response
              .json()
              .then((data) => {
                debugger;
                setSettings({
                  solarSetpoint: data.solarSetpoint,
                  gridSetpoint: data.gridSetpoint,
                  boostSetpoint: data.boostSetpoint,
                  useJustInTime: data.use_feature_just_in_time_heating == 1 ? true : false,
                  criticalTime1: data.critical_time1,
                  criticalTime2: data.critical_time2,
                  GeyserElementPower: data.element_power,
                  GeyserSize: data.geyser_capacity
                })
              })
              .catch()
        })
        .catch()
    })
  }, [getAccessTokenSilently, setToken, fetch])

  return (<>
    
    <div className='card card-custom shadow'>
      <div className='card-header'>
        <div className='card-title'>
          <button
            onClick={() => navigate('/function-view')}
            className='btn btn-light-dark me-5'
            type='button'
          >
            <i className='bi bi-arrow-left'></i> Back
          </button>
          <h3>Settings</h3>
        </div>
      </div>

      <div className='card-body py-5 d-flexX flex-columnX gap-4 [ d-grid form_fields ]'>
        <div className='alert alert-secondary d-flex align-items-center | form-group'>
          <span className='me-3'>
            <i className='bi bi-brightness-high fs-2qx'></i>
          </span>

          <div className='d-flex flex-column'>
            <h5 className='mb-1'>Solar Setpoint</h5>
            <input
              type='number'
              className='form-control form-control-lg form-control-solid fs-6'
              min='0'
              max='70'
              value={settings.solarSetpoint}
              onChange={(event) => setSettings({ ...settings, solarSetpoint: parseInt(event.target.value) })}
            />

            {/* <span>
              Lorem, ipsum dolor sit amet consectetur adipisicing elit. Sed maiores exercitationem
              omnis esse eius quam doloribus? Sequi nesciunt molestiae.
            </span> */}
          </div>
        </div>

        <div className='alert alert-secondary d-flex align-items-center | form-group'>
          <span className='me-3'>
            <i className='bi bi-thermometer-sun fs-2qx'></i>
          </span>

          <div className='d-flex flex-column'>
            <h5 className='mb-1'>Grid Setpoint</h5>
            <input
              type='number'
              className='form-control form-control-lg form-control-solid fs-6'
              min='0'
              max='70'
              value={settings.gridSetpoint}
              onChange={(event) => setSettings({ ...settings, gridSetpoint: parseInt(event.target.value) })}
            />
          </div>
        </div>

        <div className='alert alert-secondary d-flex align-items-center | form-group'>
          <span className='me-3'>
            <i className='bi bi-lightning-charge fs-2qx'></i>
          </span>

          <div className='d-flex flex-column'>
            <h5 className='mb-1'>Boost Setpoint</h5>
            <input
              type='number'
              className='form-control form-control-lg form-control-solid fs-6'
              min='0'
              max='70'
              value={settings.boostSetpoint}
              onChange={(event) => setSettings({ ...settings, boostSetpoint: parseInt(event.target.value) })}
            />
          </div>
        </div>
        
      </div>

      <div className='separator separator-dashed border-primary'></div>

      <div className='card-body py-5 [ d-grid form_fields lg ] gap-4'>
        <div className='col-md-6X | form-group'>
          <div className='alert ps-0 d-flex align-items-center | form-group'>
            <div className='form-check form-switch form-check-custom form-check-solid | me-3'>
              <input
                className='form-check-input'
                type='checkbox'
                value=''
                id='flexSwitchJIT'
                checked={settings.useJustInTime}
                onChange={(event) => setSettings({ ...settings, useJustInTime: event.target.checked })}
              />
            </div>

            <div className='d-flex flex-column'>
              <label className='form-check-label h5 cursor-pointer' htmlFor='flexSwitchJIT'>
                Enable <span className='tc-rubicon-primary'>'Just in Time'</span> Heating Feature
              </label>
              <span className='form-text'>Heat water just in time at these times.</span>
            </div>
          </div>

          <div className='row'>
            <div className='col'>
              <h5 className='mb-1'>Critical Time #1</h5>
              <input
                type='number'
                className='form-control form-control-lg form-control-solid fs-6'
                min='0'
                max='24'
                value={settings.criticalTime1}
                onChange={(event) => setSettings({ ...settings, criticalTime1: event.target.value })}
              />
            </div>
            <div className='col'>
              <h5 className='mb-1'>Critical Time #2</h5>
              <input
                type='number'
                className='form-control form-control-lg form-control-solid fs-6'
                min='0'
                max='24'
                value={settings.criticalTime2}
                onChange={(event) => setSettings({ ...settings, criticalTime2: event.target.value })}
              />
            </div>
          </div>
        </div>

        <div className='col-md-6X | form-group'>
          <div className='alert ps-0 d-flex align-items-center | form-group'>
            {/* <h5 className='mb-1'>Set Geyser Parameters: leave at default if unsure.</h5> */}
            <div className='d-flex flex-column'>
              <label className='form-check-label h5'>Set Geyser Parameters</label>
              <span className='form-text'>Leave at default if unsure.</span>
            </div>
          </div>

          <div className='row'>
            <div className='col'>
              <h5 className='mb-1'>Geyser Element Power</h5>
              <select
                className='form-select form-select-solid'
                aria-label='Select option'
                data-placeholder='Select option'
                value={settings.GeyserElementPower}
                onChange={(event) => setSettings({ ...settings, GeyserElementPower: parseInt(event.target.value) })}
              >
                <option disabled>Select option</option>
                <option value='1500'>1.5kW</option>
                <option value='2000'>2.0kW</option>
                <option value='3000'>3.0kW</option>
              </select>
            </div>
            <div className='col'>
              <h5 className='mb-1'>Geyser Size (L)</h5>
              <select
                className='form-select form-select-solid'
                aria-label='Select option'
                data-placeholder='Select option'
                value={settings.GeyserSize}
                onChange={(event) => setSettings({ ...settings, GeyserSize: parseInt(event.target.value) })}
              >
                <option disabled>Select option</option>
                <option value='150'>150 L</option>
                <option value='200'>200 L</option>
                <option value='300'>300 L</option>
              </select>
            </div>
          </div>
        </div>
      </div>

      <div className='card-footer text-center'>
        <button className='btn btn-primary min-w-150px' type='button' onClick={onClick}>
          Save
        </button>
      </div>
    </div>
    </>
  )
}
