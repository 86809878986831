import React from 'react'
import ReactTooltip from 'react-tooltip'
import {Device} from '../../app/interfaces/Device'
import {useGlobalState} from '../../app/modules/global-state/GlobalState'

export function ActiveDevice() {
  const [deviceInfo] = useGlobalState('deviceInfo')

  return (
    <>
      <a
        href='javascript:;'
        id=''
        className='btn btn-icon-white btn-text-white btn-flex'
        data-html='true'
        data-tip={'<b>Device:</b> ' + (deviceInfo.name || `Couldn't be loaded`)}
      >
        <img
          className='img-avi avi-40'
          src={deviceInfo.type == "Geyser" ? '/img/logos/h20-logo-tr.png' : '/img/logos/inverter-logo-white.jpg'}
          alt=''
        />
        {/* <i className='bi bi-geo-alt fs-2'></i> */}
        <span className='d-flex flex-column align-items-start ms-2'>
          <span className='fs-3 fw-bold [ sr-only ] [ text-uppercase ]'>xefe454</span>
          <span className='fs-7 max-ch'>{deviceInfo.name || `Couldn't be loaded`}</span>
        </span>
      </a>
      <ReactTooltip effect='solid' place='bottom' type='dark' />
    </>
  )
}
