import { useEffect, useRef, useState } from 'react'
import ReactDOM from "react-dom";
import { Device } from '../../interfaces/Device'
import { ConfirmationModal } from '../../modules/modals/ConfirmationModal'
import { SendInviteLinkModal } from '../../modules/modals/SendInviteLink/SendInviteLinkModal'
import { AddDeviceModal } from './modals/AddDeviceModal'
import { useAuth0 } from '@auth0/auth0-react'
import CustomToast from "../../modules/utility/CustomToast"
import { useGlobalState } from '../../modules/global-state/GlobalState';

export function DeviceManagement() {
  const [list, setList] = useState<any>([])
  const [activeDevice, setActive] = useState<Device>()
  const [token, setToken] = useState('')
  const {getAccessTokenSilently} = useAuth0()

  const [userID] = useGlobalState('userID')

  useEffect(() => {
    getAccessTokenSilently().then((token) => {
      setToken(token)

      loadList(token)
    })
  }, [])

  function loadList(localtoken:string) {
    fetch(process.env.REACT_APP_IOT_API_URL + 'devices/listforinstaller?UserFilter=' + userID, {
      headers: {
        Accept: 'application/json',
        Authorization: 'Bearer ' + localtoken,
      },
    })
      .then((response) => {
        if (![200, 204].includes(response.status)) return

        response.json().then((data) => {
          setList(data.list)
        })
      })
      .catch()
  }

  function incrementList(newDevice: any) {
    loadList(token)
  }

  function removeDevice(closeButton: any) {
    fetch(process.env.REACT_APP_IOT_API_URL + 'devices/remove?deviceID=' + activeDevice!.deviceID, {
      method: 'DELETE',
      headers: {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
      },
    })
      .then((response) => {
        if (response.status !== 204) {
          CustomToast({type: 'error', msg: 'An error has occurred while trying to remove the device.'});
          
          return
        }

        CustomToast({type: 'success', msg: 'Device has been successfully removed.'});

        loadList(token)
        closeButton.current!.click()
      })
      .catch(() => {
        CustomToast({type: 'error', msg: 'An error has occurred while trying to remove the device.'});
        
      })
  }

  function resendInvite(closeButton: any) {
    closeButton.current!.click()

    fetch(process.env.REACT_APP_IOT_API_URL + 'devices/resendinvite', {
      method: 'PUT',
      headers: {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
      },
      body: JSON.stringify({
        hash: '',
        email: activeDevice?.ownerEmail,
      }),
    }).catch()
  }

  return (
    <>

      <div className='card card-custom shadow'>
        {ReactDOM.createPortal(
          <>
            <AddDeviceModal token={token} incrementList={incrementList} isGlobal={false} />
            <SendInviteLinkModal devices={list} activeDevice={activeDevice}/>
            <ConfirmationModal
              title='Remove Device'
              id='remove_device_modal'
              onConfirmEvent={removeDevice}
              contents={
                <h3 className='text-dark'>
                  Are you sure you want to <span className='tc-danger'>remove</span> this device?
                </h3>
              }
            />
            <ConfirmationModal
              title='Resend Invite'
              id='resend_invite_modal'
              color='primary'
              onConfirmEvent={resendInvite}
              contents={
                <h3 className='text-dark'>
                  Are you sure you want to <span className='tc-primary'>resend</span> the invite
                  to the owner?
                </h3>
              }
            />
          </>,
          document.getElementById('modal-div')!
        )}
        <div className='card-header'>
          <h3 className='card-title'>Device Management</h3>
          <div className='card-toolbar'>
            <button
              className='btn btn-primary min-w-150px'
              data-bs-toggle='modal'
              data-bs-target='#add_device_modal'
            >
              Enroll Device
            </button>
          </div>
        </div>
        <div className='card-body py-5 d-flex flex-column flex-center'>
          <table className='table table-row-dashed table-row-gray-300 gy-7'>
            <thead>
              <tr className='fw-bolder fs-6 text-gray-800'>
                <th>Device Type</th>
                <th>Device ID</th>
                <th>Site/Device Name</th>
                <th>Email</th>
                <th>Rights</th>
                <th>Status</th>
                <th className='text-center'>Actions</th>
              </tr>
            </thead>
            <tbody>
              {list.map((device: Device) => (
                <tr key={device.deviceID}>
                  <td style={{"padding": "0 0"}}><img
                src={device.type == "Geyser" ? "/img/logos/h2o-logo@0.5x.png" : "/img/logos/inverter-logo3.png"}
                className='img-fluid img-avi avi-80'
              /></td>
                  <td>{device.identifier}</td>
                  <td>{device.name}</td>
                  <td>{device.ownerEmail}</td>
                  <td>
                    {device.statusPretty === 'Pending' ? (
                      // <a
                      //   href='#'
                      //   className='link-dark'
                      //   data-bs-toggle='modal'
                      //   data-bs-target='#send_invite_link_modal'
                      // >
                      //   <u>{device.rights}</u>
                      // </a>
                      <a
                        href='#'
                        className='btn btn-sm btn-outline btn-outline-dashed btn-outline-default btn-active-light-primary'
                        data-bs-toggle='modal'
                        data-bs-target='#send_invite_link_modal'
                        onClick={() => setActive(device)}
                      >
                        {device.rights}
                      </a>
                    ) : (
                      // <a href='#' className='link-dark'>
                      //   <u>{device.rights}</u>
                      // </a>
                      // <a
                      //   href='#'
                      //   className='btn btn-sm btn-outline btn-outline-dashed btn-outline-primary btn-active-light-primary'
                      // >
                      //   {device.rights}
                      // </a>
                      <span className='badge badge-light-success owner_linked [ justify-content-center min-w-100px tc-rubicon-green text-center ]'>
                        <i className='bi bi-person-bounding-box [ me-2 tc-rubicon-green ]'></i>{' '}
                        {device.rights}
                      </span>
                    )}
                  </td>
                  <td>
                    <div
                      className={
                        'badge badge-' + (device.statusPretty === 'Pending' ? 'light' : 'primary')
                      }
                    >
                      {device.statusPretty}
                    </div>
                  </td>
                  <td className='text-center'>
                    <a
                      href='#'
                      className='btn btn-sm btn-icon btn-light-danger'
                      data-bs-toggle='modal'
                      data-bs-target='#remove_device_modal'
                      onClick={() => setActive(device)}
                    >
                      <i className='bi bi-x-lg'></i>
                    </a>
                    {/* <a href='#' className='link-primary' onClick={() => setActive(device)}><u>View</u> </a> */}
                    {device.ownerEmail != '' && device.ownerEmail != null
                      ? device.statusPretty === 'Pending' && (
                          <a
                            href='#'
                            className='btn btn-sm btn-light-primary ms-2'
                            data-bs-toggle='modal'
                            data-bs-target='#resend_invite_modal'
                            onClick={() => setActive(device)}
                          >
                            Resend Invite
                          </a>
                        )
                      : null}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className='card-footer text-center'>
          <button
            className='btn btn-primary min-w-150px'
            data-bs-toggle='modal'
            data-bs-target='#add_device_modal'
          >
            Enroll Device
          </button>
        </div>
      </div>
    </>
  )
}
