import {useNavigate} from 'react-router-dom'

export function FunctionSupportPage() {
  const navigate = useNavigate()

  return (
    <div className='card card-custom shadow'>
      <div className='card-header'>
        <div className='card-title'>
          <button
            onClick={() => navigate('/function-view')}
            className='btn btn-light-dark me-5'
            type='button'
          >
            <i className='bi bi-arrow-left'></i> Back
          </button>
          <h3>Support</h3>
        </div>
      </div>

      <div className='card-body py-5 d-flex flex-center flex-wrap gap-10'>
        <a
          href='tel:021-555-0570'
          className='btn btn-primary d-flex flex-column flex-center gap-5 | min-w-md-300px min-h-md-300px'
        >
          <i className='bi bi-telephone fs-5x'></i>
          <span className='fs-2x'>Call Us</span>
          <span className='fs-1x'>080 RUBICON</span>
          <span className='fs-1x'>080 782 4266</span>
        </a>

        <a
          href='mailto:support@rubiconsa.com'
          className='btn btn-primary d-flex flex-column flex-center gap-5 | min-w-md-300px min-h-md-300px'
        >
          <i className='bi bi-envelope fs-5x'></i>
          <span className='fs-2x'>Email Us</span>
          <span className='fs-1x'>support@rubiconsa.com</span>
        </a>
      </div>

      {/* <div className='card-footer'></div> */}
    </div>
  )
}
