import clsx from 'clsx'
import React, {FC} from 'react'
import {KTSVG} from '../../../helpers'
import {HeaderUserMenu, QuickLinks} from '../../../partials'
import {useAuth0} from '@auth0/auth0-react'
import {ActiveDevice} from '../../../../Components/switch-device/SwitchDevice'
import { useGlobalState } from '../../../../app/modules/global-state/GlobalState'
import { DeviceManager } from '../../../../app/modules/global-state/DeviceManager'

const toolbarButtonMarginClass = 'ms-1 ms-lg-3',
  toolbarButtonHeightClass = 'w-30px h-30px w-md-40px h-md-40px',
  toolbarUserAvatarHeightClass = 'symbol-30px symbol-md-40px',
  toolbarButtonIconSizeClass = 'svg-icon-1'

const Topbar: FC = () => {
  const {user} = useAuth0()
  const deviceID = DeviceManager.getDeviceID();

  return (
    <div className='topbar d-flex align-items-stretch flex-shrink-0'>
      {/* Search */}
      {/* <div className={clsx('d-flex align-items-stretch', toolbarButtonMarginClass)}>
      <Search />
    </div> */}
      {/* Activities */}

      {/* NOTIFICATIONS */}

      {/* CHAT */}
      {/* Switch Device */}
      {
        (deviceID != "") &&
        <div className={clsx('d-flex align-items-stretch', toolbarButtonMarginClass)}>
          <ActiveDevice />
        </div>
      }

      {/* Quick links */}
      <div className={clsx('d-flex align-items-center', toolbarButtonMarginClass)}>
        {/* begin::Menu wrapper */}
        <div
          className={clsx(
            'btn btn-icon btn-active-light-primary btn-custom',
            toolbarButtonHeightClass
          )}
          data-kt-menu-trigger='click'
          data-kt-menu-attach='parent'
          data-kt-menu-placement='bottom-end'
          data-kt-menu-flip='bottom'
        >
          <KTSVG
            path='/media/icons/duotune/general/gen025.svg'
            className={toolbarButtonIconSizeClass}
          />
        </div>
        <QuickLinks/>
        {/* end::Menu wrapper */}
      </div>

      {/* begin::User */}
      <div
        className={clsx('d-flex align-items-center', toolbarButtonMarginClass)}
        id='kt_header_user_menu_toggle'
      >
        {/* begin::Toggle */}
        <div
          className={clsx('cursor-pointer symbol', toolbarUserAvatarHeightClass)}
          data-kt-menu-trigger='click'
          data-kt-menu-attach='parent'
          data-kt-menu-placement='bottom-end'
          data-kt-menu-flip='bottom'
        >
          <img className='h-30px w-30px rounded' src={user?.picture} alt='User' />
        </div>
        <HeaderUserMenu />
        {/* end::Toggle */}
      </div>
      {/* end::User */}
    </div>
  )
}

export {Topbar}
