import { useRef, useState } from "react"
import { KTSVG } from "../../../../_metronic/helpers"
import CustomToast from "../../../modules/utility/CustomToast"

interface IMyProps {
    token: string,
    isGlobal: boolean;
    incrementList: (newDevice: any) => void
}

export function AddDeviceModal(props: IMyProps) {
    const [deviceID, setID] = useState("")
    const [deviceName, setName] = useState("")
    const [deviceType, setType] = useState("Geyser");
    const btnClose = useRef<any>(null)


    function addDevicePost() {
        fetch(process.env.REACT_APP_IOT_API_URL + 'devices/add', {
            method: 'POST',
            body: JSON.stringify({
                Identifier: deviceID,
                Name: deviceName,
                Type: deviceType
            }),
            headers: {
                Accept: 'application/json',
                Authorization: 'Bearer ' + props.token,
                "Content-Type": 'application/json;charset=UTF-8'
            }
        }).then(response => {

            if (response.status !== 200) {
                CustomToast({ type: 'error', msg: 'An error has occurred while trying to enroll the device.' });

                return
            }
            btnClose.current!.click()

            CustomToast({ type: 'success', msg: 'Device has been successfully enrolled.' });

            response.json().then(data => {
                props.incrementList({
                    identifier: data.identifier,
                    name: data.name
                })
            })
        }).catch(() => {
            CustomToast({ type: 'error', msg: 'An error has occurred while trying to enroll the device.' });
        });
    }

    return <>

        <div className='modal fade' id={!props.isGlobal ? 'add_device_modal' : 'global_add_device_modal'}>
            <div className='modal-dialog'>
                <div className='modal-content'>
                    <div className='modal-header'>
                        <h5 className='modal-title'>
                            Enroll Device
                        </h5>
                        <div
                            className="btn btn-icon btn-sm btn-active-light-primary ms-2"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                        >
                            <KTSVG
                                path="/media/icons/duotune/arrows/arr061.svg"
                                className="svg-icon svg-icon-2x"
                            />
                        </div>
                    </div>

                    <div className='modal-body'>
                        <div className='mb-10 d-flex flex-center'>
                            <label className='form-label w-200px'>Device Type</label>
                            <select onChange={event => {
                                setType(event.target.value)
                            }} className='form-select'>
                                <option value="Geyser">Geyser</option>
                                <option value="SunPro">SunPro</option>
                                <option value="Deye">Deye</option>
                                <option value="MegaRevo">MegaRevo</option>
                            </select>
                        </div>
                        <div className='mb-10 d-flex flex-center'>
                            <label className='form-label w-200px'>Device ID</label>
                            <input className='form-control' placeholder='Device Unique ID' type='text' onChange={event => {
                                setID(event.target.value)
                            }} />
                        </div>
                        <div className='mb-10 d-flex flex-center'>
                            <label className='form-label w-200px'>Device Name</label>
                            <input className='form-control' placeholder='Device Name' type='text' onChange={event => {
                                setName(event.target.value)
                            }} />
                        </div>
                    </div>

                    <div className='modal-footer d-flex flex-center gap-5'>
                        <button className='btn btn-light w-150px' data-bs-dismiss='modal' ref={btnClose}>Cancel</button>
                        <button className='btn btn-primary w-150px' onClick={() => addDevicePost()}>Add</button>
                    </div>
                </div>
            </div>
        </div>
    </>
}