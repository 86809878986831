import {DisplayDevice} from '../../../Components/active-view/DisplayDevice'
import {StatsWidget3} from '../../../Components/active-view/StatsWidget3'
import {KTSVG} from '../../../_metronic/helpers/components/KTSVG'
import {ActiveViewModel} from '../../../app/interfaces/ActiveView'
import OverlayTrigger from 'react-bootstrap/esm/OverlayTrigger'
import Tooltip from 'react-bootstrap/esm/Tooltip'
import React from 'react'
import {setGlobalState, useGlobalState} from '../../modules/global-state/GlobalState'


interface IMyProps {
  ActiveModel: ActiveViewModel | null
  refresh: any
}

export function ActiveView2(props: IMyProps) {

  function getLinearGradientFromPerc(perc: number){
    if(perc == 0)
      return "linear-gradient(90deg, lightgray 50%, lightgray 50%)";
    if(perc >= 100)
      return "linear-gradient(90deg, #50cd89 50%, #50cd89 50%)";
    if(perc > 0 && perc <= 25)
      return `linear-gradient(${(270 + 3.6 * perc).toFixed(2)}deg, lightgray 50%, transparent 50%), linear-gradient(270deg, #50cd89 50%, lightgray 50%)`
    if(perc > 25 && perc <= 50)
      return `linear-gradient(${(3.6 * (perc - 25)).toFixed(2)}deg, lightgray 50%, transparent 50%), linear-gradient(270deg, #50cd89 50%, lightgray 50%)`
    if(perc > 50 && perc <= 75)
      return `linear-gradient(270deg, #50cd89 50%, transparent 50%), linear-gradient(${270 + 3.6 * (perc - 50)}deg, #50cd89 50%, lightgray 50%)`
    if(perc > 75 && perc < 100)
      return `linear-gradient(270deg, #50cd89 50%, transparent 50%), linear-gradient(${(3.6 * (perc - 75))}deg, #50cd89 50%, lightgray 50%)`;
  }

  const [deviceInfo] = useGlobalState('deviceInfo');

  var total = props.ActiveModel != null ? props.ActiveModel.PVProductedToday + props.ActiveModel.ImportedToday : 0;
  var percImported =
    props.ActiveModel != null
      ? (props.ActiveModel.PVProductedToday / total) * 100 : 0;

  var percGradient = getLinearGradientFromPerc(percImported);

  return (
    <>
      <div className='card card-custom shadow'>
        <div className='card-body'>
          <div className='active_view_holder d-grid'>
            <div className='grid-item'></div>
            <div className='grid-item'>
              {/* <StatsWidget3 /> */}
              {/* <div className='card-body d-flexX d-none align-items-center justify-content-center flex-wrap | text-center'>
                <span className='svg-icon svg-icon-2hx svg-icon-info me-4 mb-5 mb-sm-0 [ pulse ]'>
                  <KTSVG
                    path='/media/icons/duotune/general/gen030.svg'
                    className='svg-icon svg-icon-success svg-icon-2hx ms-n1 flex-grow-1'
                  />
                </span>
                <div className='d-flex flex-column'>
                  <div className='me-2'>
                    <h2 className='fw-bold text-gray-800 mb-3'>Transmission</h2>
                    <div className='text-muted fw-semibold fs-6'>
                      <h5 className='mb-0'>
                        {props.ActiveModel != null ? (
                          <span>
                            Last Transmission received on
                            <b className='text-primary ms-1'>
                              {props.ActiveModel != null ? props.ActiveModel.DateReceivedS : ''}
                            </b>
                            <span className='mx-2'> at</span>
                            <b className='text-primary'>
                              {props.ActiveModel != null ? props.ActiveModel.TimeReceivedS : ''}
                            </b>
                          </span>
                        ) : (
                          <span>No transmission received yet.</span>
                        )}
                      </h5>
                    </div>
                  </div>
                  <a
                    href='javascript:;'
                    className='btn btn-light-warning fw-semibold [ mt-4 ]'
                    onClick={props.refresh}
                  >
                    <span
                      className='svg-icon svg-icon-1 svg-icon-primary'
                      data-html='true'
                      data-tip='Click to refresh'
                    >
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        width='16'
                        height='16'
                        fill='currentColor'
                        className='bi bi-arrow-counterclockwise'
                        viewBox='0 0 16 16'
                      >
                        <path
                          fill-rule='evenodd'
                          d='M8 3a5 5 0 1 1-4.546 2.914.5.5 0 0 0-.908-.417A6 6 0 1 0 8 2v1z'
                        />
                        <path d='M8 4.466V.534a.25.25 0 0 0-.41-.192L5.23 2.308a.25.25 0 0 0 0 .384l2.36 1.966A.25.25 0 0 0 8 4.466z' />
                      </svg>
                    </span>
                    Refresh
                  </a>
                </div>
              </div> */}
            </div>
            <div className='grid-item'></div>
            <div className='grid-item'>
              {/* <div className='card my-6X min-h-300px [ anim8-border ]' data-card-animate='true'>
                <div className='card-body | d-flex flex-center'>
                  <div className='content_holder'>
                    <h1 className='text-white'>Aweh - work in progress</h1>
                  </div>
                </div>
              </div> */}
              <div
                className='card w-250px h-250px h-lg-400px w-lg-400px | bg-gray-300'
                data-card-animate=''
                style={{'background': percGradient} as React.CSSProperties}
              >
                <div className='card-body | d-flex flex-center'>
                  <div className='content_holder'>
                    <div className='symbol symbol-100px symbol-2by3'>
                      <div className='symbol-label bg-transparent text-primary | overflow-hidden'>
                        {/* <img src='/img/logos/h2o-logo@0.5x.png' className='img-fluid rounded' /> */}
                        <img src='/img/logos/h20-logo-tr.png' className='img-fluid rounded' />
                      </div>
                    </div>
                    <h1 className='tc-rubicon-primary mt-3 text-center'>
                      {props.ActiveModel != null ? (props.ActiveModel.TotalConsumedToday).toFixed(2) : '-'} kWh
                    </h1>
                    <small className='fs-4'>Consumed</small>
                    {props.ActiveModel != null && props.ActiveModel.PowerMode == 3 ? <small className='fs-4 text-danger'>Boost Mode</small> : null}
                    {props.ActiveModel != null && props.ActiveModel.PowerMode == 2 ? <small className='fs-4 text-danger'>Grid Mode</small> : null}
                    {props.ActiveModel != null && props.ActiveModel.PowerMode == 1 ? <small className='fs-4 text-success'>Solar Mode</small> : null}
                    {props.ActiveModel != null && props.ActiveModel.PowerMode == 0 ? <small className='fs-4 text-muted'>Idle Mode</small> : null}
                  </div>
                </div>
              </div>
            </div>
            <div className='grid-item'>
              <div
                className={
                  props.ActiveModel == null || props.ActiveModel.GeyserTemp <= 45
                    ? 'card mt-6 [ geyser_temp hotX warmX cold ]'
                    : props.ActiveModel.GeyserTemp > 45 && props.ActiveModel.GeyserTemp <= 60
                    ? 'card mt-6 [ geyser_temp hotX warm coldX ]'
                    : 'card mt-6 [ geyser_temp hot warmX coldX ]'
                }
              >
                <div className='card-body | d-flex flex-center'>
                  <div className='content_holder [ d-flex align-items-center ]'>
                    <i
                      className={
                        props.ActiveModel != null && props.ActiveModel.GeyserTemp > 60
                          ? 'bi bi-thermometer-sun [ fs-5tx ]'
                          : 'bi bi-thermometer-sun [ fs-5tx | d-none ]'
                      }
                    ></i>
                    <i
                      className={
                        props.ActiveModel != null &&
                        props.ActiveModel.GeyserTemp > 45 &&
                        props.ActiveModel.GeyserTemp <= 60
                          ? 'bi bi-thermometer-half [ fs-5tx ]'
                          : 'bi bi-thermometer-half [ fs-5tx | d-none ]'
                      }
                    ></i>
                    <i
                      className={
                        props.ActiveModel == null || props.ActiveModel.GeyserTemp <= 45
                          ? 'bi bi-thermometer-low [ fs-5tx ]'
                          : 'bi bi-thermometer-low [ fs-5tx | d-none ]'
                      }
                    ></i>
                    <h1 className='text-white mb-0 display-4'>
                      {props.ActiveModel != null ? props.ActiveModel.GeyserTemp : '-'} &deg;
                    </h1>
                  </div>
                </div>
              </div>
              <div className='d-flex align-items-center flex-column | py-3 | time_to_hot'>
                <div className='symbol symbol-50 symbol-circle symbol-light'>
                  <span className='symbol-label'>
                    <i className='bi bi-clock fs-3x'></i>
                  </span>
                </div>
                <div>
                  <h1 className='tc-rubicon-primary mt-3 text-center'>
                    {props.ActiveModel != null && props.ActiveModel.TimeToHot < 1440
                      ? (Math.floor(props.ActiveModel.TimeToHot / 60)) +
                        'H' +
                        ((props.ActiveModel.TimeToHot -  Math.floor(props.ActiveModel.TimeToHot / 60) * 60)) +
                        'Min'
                      : '-'}
                  </h1>
                  {/* <span className='font-weight-bolder text-dark h3'></span> */}
                  <span className='d-block | fs-4'>Time to hot</span>
                </div>
              </div>
              <div className='d-flex align-items-center justify-content-center flex-row | py-3'>
                {props.ActiveModel != null && props.ActiveModel.isConnected == true ? 
                <span className='svg-icon svg-icon-2hx svg-icon-info me-4 mb-5 mb-sm-0 [ pulse ]'>
                  <KTSVG
                    path='/media/icons/duotune/general/gen030.svg'
                    className='svg-icon svg-icon-success svg-icon-2hx ms-n1 flex-grow-1'
                  />
                </span> : null}
                <div className='bg-rubicon-primary badge px-3 text-center'>
                  <span className='d-block | fs-5 text-white max-ch py-1' >
                   {deviceInfo.name} (DeviceID: {deviceInfo.identifier})
                  </span>
                </div>

                <OverlayTrigger
                  delay={{show: 250, hide: 400}}
                  placement='right'
                  overlay={
                    <Tooltip id='info-tooltip'>
                      <h5 className='mb-0'>
                        {props.ActiveModel != null ? (
                          <span>
                            Last Transmission received on
                            <b className='text-primary ms-1'>
                              {props.ActiveModel != null ? props.ActiveModel.DateReceivedS : ''}
                            </b>
                            <span className='mx-2'> at</span>
                            <b className='text-primary'>
                              {props.ActiveModel != null ? props.ActiveModel.TimeReceivedS : ''}
                            </b>
                          </span>
                        ) : (
                          <span>No transmission received yet.</span>
                        )}
                      </h5>
                    </Tooltip>
                  }
                >
                  <i className='bi bi-info-circle fs-3 ms-4 tc-rubicon-primary'></i>
                </OverlayTrigger>
              </div>
            </div>
            <div className='grid-item | arr-l' data-animate=''>
              <svg
                id='arrow-01'
                data-name='arrow-01'
                xmlns='http://www.w3.org/2000/svg'
                viewBox='0 0 30 10'
              >
                <line
                  x1='0'
                  y1='1'
                  x2='30'
                  y2='1'
                  stroke={
                    props.ActiveModel == null || props.ActiveModel.InstantImported <= 0 ? 'var(--bs-gray-500)' : 'var(--bs-danger)'
                  }
                  stroke-width='.5'
                  stroke-dasharray={props.ActiveModel == null || props.ActiveModel.InstantImported <= 0 ? '3%' : '0%'}
                ></line>
              </svg>
              <div className='card bg-gray-300'>
                <div className='card-body | d-flex flex-center'>
                  <div className='content_holder text-center'>
                    <i className='bi bi-lightning-charge fs-2hx'></i>
                    <h1 className='mb-0 mt-3'>
                      {props.ActiveModel != null ? props.ActiveModel.InstantImported.toFixed(2) : '-'} kW
                    </h1>
                  </div>
                </div>
              </div>
            </div>
            <div className='grid-item | arr-r' data-animate=''>
              <svg
                id='arrow-02'
                data-name='arrow-02'
                xmlns='http://www.w3.org/2000/svg'
                viewBox='0 0 30 10'
              >
                <line
                  x1='0'
                  y1='1'
                  x2='30'
                  y2='1'
                  stroke={props.ActiveModel == null || props.ActiveModel.InstantPV <= 0 ? 'var(--bs-gray-500)' : 'var(--bs-success)'}
                  stroke-width='.5'
                  stroke-dasharray={props.ActiveModel == null || props.ActiveModel.InstantPV <= 0 ? '3%' : '0%'}
                ></line>
              </svg>
              <div className='card bg-gray-300'>
                <div className='card-body | d-flex flex-center'>
                  <div className='content_holder text-center'>
                    <i className='bi bi-brightness-high fs-2hx'></i>
                    <h1 className='mb-0 mt-3'>
                      {props.ActiveModel != null ? props.ActiveModel.InstantPV.toFixed(2) : '-'} kW
                    </h1>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <div className='card my-6 [ anim8-border ]' data-card-animate='true'>
        <div className='card-body | d-flex flex-center'>
          <div className='content_holder'>
            <h1 className='text-white'>Aweh</h1>
          </div>
        </div>
      </div> */}
    </>
  )
}
