import React from 'react'

export function StatsWidget5() {
  return (
    <div className=''>
      <span className='text-muted fw-semibold text-muted d-block fs-7'>Time to hot</span>
      <span className='text-gray-800 fw-bolder fs-1'>0 H 9 Min</span>
    </div>
  )
}
