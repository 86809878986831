/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {Link} from 'react-router-dom'
import { MenuComponent } from '../../../assets/ts/components'
import {KTSVG, toAbsoluteUrl} from '../../../helpers'
import { AddDeviceModal } from '../../../../../src/app/pages/device-management/modals/AddDeviceModal'
import { useAuth0 } from '@auth0/auth0-react'
import { useEffect, useRef, useState } from 'react'
import ReactDOM from "react-dom";

const QuickLinks: FC = () => {
  const {getAccessTokenSilently} = useAuth0()
  const [token, setToken] = useState('')

  useEffect(() => {
    getAccessTokenSilently().then((token) => {
      setToken(token)
    })
  }, [])

  return (<>
  {ReactDOM.createPortal(
  <AddDeviceModal token={token} incrementList={() => {}} isGlobal={true} />, document.getElementById('modal-div')!
  )}
  <div
    className='menu menu-sub menu-sub-dropdown menu-column w-250px w-lg-325px'
    data-kt-menu='true'
  >
    {/* <div
      className='d-flex flex-column flex-center bgi-no-repeat rounded-top px-9 py-10'
      style={{backgroundImage: `url('${toAbsoluteUrl('/media/misc/pattern-1.jpg')}')`}}
    >
      <h3 className='text-white fw-bold mb-3X [ mb-0 ]'>Quick Links</h3>

      <span className='badge bg-primary py-2 px-3 [ d-none ]'>25 pending tasks</span>
    </div> */}

    <div className='d-flex flex-column flex-center rounded-top px-9 py-10 [ bg-rubicon-primary ]'>
      <h3 className='text-white fw-bold [ mb-0 ]'>Quick Links</h3>
    </div>

    <div className='row g-0'>
      <div className='col-6'>
        <Link to='/profile' onClick={e => MenuComponent.hideDropdowns(undefined)}>
          <a className='d-flex flex-column flex-center h-100 p-6 bg-hover-light border-end border-bottom'>
            <KTSVG
              path='/media/icons/duotune/technology/teh002.svg'
              className='svg-icon-3x svg-icon-rubicon-primary mb-2'
            />
            <span className='fs-5 fw-bold text-gray-800 mb-0'>Profile</span>
            <span className='fs-7 text-gray-400'>View profile</span>
          </a>
        </Link>
      </div>

      <div className='col-6'>
        <a
          className='d-flex flex-column flex-center h-100 p-6 bg-hover-light border-bottom'
          onClick={e => MenuComponent.hideDropdowns(undefined)}
          data-bs-toggle='modal'
          data-bs-target='#global_add_device_modal'
        >
          <KTSVG
            path='/media/icons/duotune/arrows/arr013.svg'
            className='svg-icon-3x svg-icon-rubicon-primary mb-2'
          />
          <span className='fs-5 fw-bold text-gray-800 mb-0'>New Device</span>
          <span className='fs-7 text-gray-400'>Add new device</span>
        </a>
      </div>

      <div className='col-6'>
        <Link to='/switch-device' onClick={e => MenuComponent.hideDropdowns(undefined)}>
          <a className='d-flex flex-column flex-center h-100 p-6 bg-hover-light border-end'>
            <KTSVG
              path='/media/icons/duotune/arrows/arr033.svg'
              className='svg-icon-3x svg-icon-rubicon-primary mb-2'
            />
            <span className='fs-5 fw-bold text-gray-800 mb-0'>Switch Device</span>
            <span className='fs-7 text-gray-400'>Choose diff. device</span>
          </a>
        </Link>
      </div>
      <div className='col-6'>
        {/* <a href='/device-management/devices' className='d-flex flex-column flex-center h-100 p-6 bg-hover-light'>
          <KTSVG
            path='/media/icons/duotune/general/gen034.svg'
            className='svg-icon-3x svg-icon-danger mb-2'
          />
          <span className='fs-5 fw-bold text-gray-800 mb-0'>Logout</span>
          <span className='fs-7 text-gray-400'>Exit the app</span>
        </a> */}
        <Link to='/device-management/devices' onClick={e => MenuComponent.hideDropdowns(undefined)}>
          <a className='d-flex flex-column flex-center h-100 p-6 bg-hover-light border-end'>
            <KTSVG
              path='/media/icons/duotune/technology/teh001.svg'
              className='svg-icon-3x svg-icon-rubicon-primary mb-2'
            />
            <span className='fs-5 fw-bold text-gray-800 mb-0'>Devices</span>
            <span className='fs-7 text-gray-400'>Manage devices</span>
          </a>
        </Link>
      </div>
    </div>
    {/* <div className='col-6'>
        <a href='#' className='d-flex flex-column flex-center h-100 p-6 bg-hover-light'>
          <KTSVG
            path='/media/icons/duotune/general/gen034.svg'
            className='svg-icon-3x svg-icon-danger mb-2'
          />
          <span className='fs-5 fw-bold text-gray-800 mb-0'>Logout</span>
          <span className='fs-7 text-gray-400'>Exit the app</span>
        </a>
      </div>
    </div> */}

    <div className='py-2 text-center border-top [ d-none ]'>
      <Link to='/crafted/pages/profile' className='btn btn-color-gray-600 btn-active-color-primary'>
        View All <KTSVG path='/media/icons/duotune/arrows/arr064.svg' className='svg-icon-5' />
      </Link>
    </div>
  </div>
  </>
)
  }

export {QuickLinks}
