import * as Yup from 'yup'
import clsx from 'clsx'
import { useFormik } from 'formik'
import { useSearchParams } from 'react-router-dom'
import { useState, forwardRef, useRef, useImperativeHandle } from 'react'


interface IMyProps {
    userInfo: any
    setUserInfo: any
    setValid: any
    isValid: any
    validationResponse: any
}

const inviteSchema = Yup.object().shape({
    name: Yup.string(),
    lastName: Yup.string(),
    email: Yup.string()
        .email('Wrong email format')
        .min(3, 'Minimum 3 symbols')
        .max(50, 'Maximum 50 symbols')
        .required('Email is required'),
    password: Yup.string()
        .min(8, 'Minimum 8 symbols')
        .max(50, 'Maximum 50 symbols')
        .required('Password is required')
        .matches(
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
            "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
          ),
    deviceID: Yup.number()
        .required('Device ID is required'),
    address: Yup.string()
        .required('Address is required'),
    contactNo1: Yup.string()
        .max(10, 'Maximum 10 symbols'),
    contactNo2: Yup.string()
        .max(10, 'Maximum 10 symbols')
})

const CustomerDetails = forwardRef((props : IMyProps, ref) =>  {
    const initialValues = {
        name: props.userInfo?.name || '',
        lastName: props.userInfo?.lastName || '',
        email: props.validationResponse.inviteeEmail || props.userInfo?.email || '',
        password: props.userInfo?.password || '',
        deviceID: props.validationResponse.deviceID || props.userInfo?.deviceID || 0,
        address: props.userInfo?.address || '',
        contactNo1: props.userInfo?.contactNo1 || '',
        contactNo2: props.userInfo?.contactNo2 || ''
    }

    useImperativeHandle(ref, () => ({
        validateForm() {
            formik.handleSubmit();
        }
    }));

    const formik = useFormik({
        initialValues,
        validationSchema: inviteSchema,
        onSubmit: async (values, {setStatus, setSubmitting}) => {},
        validateOnBlur: true,
    })

    return <>
    <div className='w-100'>

      <div className='fv-row'>
        <div className='row'>
          <div className='col'>
          <form
        className='form w-100 d-flex flex-column gap-3'
        onSubmit={formik.handleSubmit}
        noValidate
        id='kt_login_signin_form'
        >

            {/* First Name */}
            <div>
                <label className='form-label fs-6 fw-bolder text-dark'>First Name</label>
                <input type='text'  {...formik.getFieldProps('name')} className='form-control form-control-lg form-control-solid fs-6'
                    onBlur={eve => {
                        props.setUserInfo(formik.values)
                        formik.handleBlur(eve)
                        props.setValid(formik.isValid)
                    }}
                />
            </div>

            {/* Last Name */}
            <div>
                <label className='form-label fs-6 fw-bolder text-dark'>Last Name</label>
                <input type='text'  {...formik.getFieldProps('lastName')} className='form-control form-control-lg form-control-solid fs-6'
                    onBlur={eve => {
                        props.setUserInfo(formik.values)
                        formik.handleBlur(eve)
                        props.setValid(formik.isValid)
                    }}
                />
            </div>

            {/* Email */}
            <div>
                <label className='required form-label fs-6 fw-bolder text-dark'>Email</label>
                <input type='email' {...formik.getFieldProps('email')} className='form-control form-control-lg form-control-solid fs-6 is-valid' readOnly/>
            </div>

            {/* Password */}
            <div>
                <label className='required form-label fs-6 fw-bolder text-dark'>Password</label>
                <input type='password' {...formik.getFieldProps('password')} className={clsx(
                    'form-control form-control-lg form-control-solid fs-6',
                    {'is-invalid': formik.touched.password && formik.errors.password},
                    {
                    'is-valid': formik.touched.password && !formik.errors.password,
                    }
                )} onBlur={eve => {
                    props.setUserInfo(formik.values)
                    formik.handleBlur(eve)
                    props.setValid(formik.isValid)
                }}/>
                {formik.touched.password && formik.errors.password && (
                <div className='fv-plugins-message-container'>
                    <span role='alert' className='text-danger'>{formik.errors.password}</span>
                </div>
                )}
            </div>

            {/* Device ID */}
            <div>
                <label className='required form-label fs-6 fw-bolder text-dark'>Device ID</label>
                <input type='number'  {...formik.getFieldProps('deviceID')} className='form-control form-control-lg form-control-solid fs-6 is-valid' readOnly/>
            </div>

            {/* Address */}
            <div>
                <label className='required form-label fs-6 fw-bolder text-dark'>Address of Installation</label>
                <input type='text' {...formik.getFieldProps('address')} className={clsx(
                    'form-control form-control-lg form-control-solid fs-6',
                    {'is-invalid': formik.touched.address && formik.errors.address},
                    {
                    'is-valid': formik.touched.address && !formik.errors.address,
                    }
                )} onBlur={eve => {
                    props.setUserInfo(formik.values)
                    formik.handleBlur(eve)
                    props.setValid(formik.isValid)
                }}/>
                {formik.touched.address && formik.errors.address && (
                <div className='fv-plugins-message-container'>
                    <span role='alert' className='text-danger'>{formik.errors.address}</span>
                </div>
                )}
            </div>

            {/* Contact no 1 */}
            <div>
                <label className='form-label fs-6 fw-bolder text-dark'>Contact Number 1</label>
                <input type='tel' {...formik.getFieldProps('contactNo1')} className='form-control form-control-lg form-control-solid fs-6'
                    onBlur={eve => {
                        props.setUserInfo(formik.values)
                        formik.handleBlur(eve)
                        props.setValid(formik.isValid)
                    }}
                />
                {formik.touched.contactNo1 && formik.errors.contactNo1 && (
                <div className='fv-plugins-message-container'>
                    <span role='alert' className='text-danger'>{formik.errors.contactNo1}</span>
                </div>
                )}
            </div>

            {/* Contact no 2 */}
            <div>
                <label className='form-label fs-6 fw-bolder text-dark'>Contact Number 2</label>
                <input type='tel' {...formik.getFieldProps('contactNo2')} className='form-control form-control-lg form-control-solid fs-6'
                    onBlur={eve => {
                        props.setUserInfo(formik.values)
                        formik.handleBlur(eve)
                        props.setValid(formik.isValid)
                    }}
                />
                {formik.touched.contactNo2 && formik.errors.contactNo2 && (
                <div className='fv-plugins-message-container'>
                    <span role='alert' className='text-danger'>{formik.errors.contactNo2}</span>
                </div>
                )}
            </div>
        </form>
          </div>
        </div>
      </div>
    </div>
        
    </>
})

export default CustomerDetails;