import React from 'react'
import ReactDOM from 'react-dom'
// Axios
import axios from 'axios'
import {Chart, registerables} from 'chart.js'
import {QueryClient, QueryClientProvider} from 'react-query'

// Apps
import {MetronicI18nProvider} from './_metronic/i18n/Metronici18n'
/**
 * TIP: Replace this style import with dark styles to enable dark mode
 *
 * import './_metronic/assets/sass/style.dark.scss'
 *
 * TIP: Replace this style import with rtl styles to enable rtl mode
 *
 * import './_metronic/assets/css/style.rtl.css'
 **/
import './_metronic/assets/sass/style.scss'
import './_metronic/assets/sass/style.react.scss'
import './assets/scss/custom.scss'
import './_metronic/assets/sass/plugins.scss'
import {AppRoutes} from './app/routing/AppRoutes'
import {setupAxios} from './app/modules/auth'
import { AppState, Auth0Provider } from '@auth0/auth0-react'
/**
 * Creates `axios-mock-adapter` instance for provided `axios` instance, add
 * basic Metronic mocks and returns it.
 *
 * @see https://github.com/ctimmerm/axios-mock-adapter
 */

/**
 * Inject Metronic interceptors for axios.
 *
 * @see https://github.com/axios/axios#interceptors
 */
setupAxios(axios)

Chart.register(...registerables)

const queryClient = new QueryClient()

ReactDOM.render(
  <QueryClientProvider client={queryClient}>
    <MetronicI18nProvider>
      <Auth0Provider
        domain='rubiconsa.us.auth0.com'//'rubiconh2o.eu.auth0.com'
        clientId='MBOcrf93kl91gQF0F7tU7OA7xBu2TS10'//'q0hbPkzIzB4G3SsDXMsGjHzUtD2YzaaX'
        audience='https://rubiconsa-apih2o.azurewebsites.net'//'Iot'
        redirectUri={window.location.origin}
        onRedirectCallback={(appState?: AppState) => {
          console.log(appState?.targetUrl || '/')
        }}
      >
        <AppRoutes />
      </Auth0Provider>
    </MetronicI18nProvider>
  </QueryClientProvider>,

  document.getElementById('root')
)
