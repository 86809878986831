import React from 'react'
import {MenuItem} from './MenuItem'
import {MenuInnerWithSub} from './MenuInnerWithSub'
import {MegaMenu} from './MegaMenu'
import {useIntl} from 'react-intl'
import {useGlobalState} from '../../../../app/modules/global-state/GlobalState'
import { DeviceManager } from '../../../../app/modules/global-state/DeviceManager'

export function MenuInner() {
  const intl = useIntl()
  const deviceID = DeviceManager.getDeviceID();
  const [deviceInfo] = useGlobalState('deviceInfo');

  return (
    <>
      {deviceID != "" && (
        <>
          {/* <MenuItem title={intl.formatMessage({id: 'MENU.DASHBOARD'})} to='/dashboard' /> */}
          <MenuItem title='Active View' to='/active-view' />
          <MenuItem title='Function View' to='/function-view' />
          <MenuItem title='Energy Consumption' to='/energy-consumption' />
          {deviceInfo.type == "Geyser" ? 
            <MenuItem title='Geyser Activity' to='/geyser-activity' /> 
            : <MenuItem title='Device Activity' to='/device-activity' />
          }
          
          
          <MenuItem title='Users' to='/user-management/users' />
        </>
      )}
    </>
  )
}
