/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import { FC, useEffect, useState } from 'react'
import { Routes, Route, BrowserRouter, Navigate, useSearchParams, useNavigate } from 'react-router-dom'
import { PrivateRoutes } from './PrivateRoutes'
import { ErrorsPage } from '../modules/errors/ErrorsPage'
import { Logout } from '../modules/auth'
import { App } from '../App'
import { EnrollmentPage } from '../modules/customer-enrollment/EnrollmentPage'
import { AcceptInvitePage } from '../modules/invite/AcceptInvitePage'
import { DeclineInvitePage } from '../modules/invite/DeclineInvitePage'
import { EnrollmentDeclineInvitePage } from '../modules/customer-enrollment/EnrollmentDeclineInvitePage'
import { PrivateRoute } from './PrivateRoute'
import { useAuth0 } from '@auth0/auth0-react'
import { EnrollmentWizard } from '../modules/customer-enrollment/EnrollmentWizard'
import {MasterLayout} from '../../_metronic/layout/MasterLayout'

/**
 * Base URL of the website.
 *
 * @see https://facebook.github.io/create-react-app/docs/using-the-public-folder
 */

const AppRoutes: FC = () => {
  const { user, getAccessTokenSilently } = useAuth0()
  const [token, setToken] = useState('')

  useEffect(() => {
    if (!user) return

    getAccessTokenSilently().then(token => {
      setToken(token)
    })
  }, [getAccessTokenSilently, setToken])

  return (
    <BrowserRouter>
      <Routes>
        <Route element={<App />}>
          <Route element={<MasterLayout isPublic={true}/>}>
            <Route path='error/*' element={<ErrorsPage />} />
            <Route path='logout' element={<Logout />} />
            <Route path='/users/enroll' element={<EnrollmentPage />} />
            <Route path='/users/enroll/wizard' element={<EnrollmentWizard />} />
            <Route path='/users/enrolldecline' element={<EnrollmentDeclineInvitePage />} />
            <Route path='/users/acceptinvite' element={<AcceptInvitePage token={token} />} />
            <Route path='/users/declineinvite' element={<DeclineInvitePage />} />
          </Route>
          {/* <Route path='/*' element={<PrivateRoutes/> } /> */}
          <Route path='/*' element={<PrivateRoute component={PrivateRoutes} />} />
        </Route>
      </Routes>
    </BrowserRouter>
  )
}

export { AppRoutes }
