import React from 'react'

export function StatsWidget7() {
  return (
    <>
      <div className='d-flex align-items-center'>
        <span className='bullet bullet-vertical h-40px bg-warning'></span>
        <div className='flex-grow-1 [ ps-3 ]'>
          <span className='text-gray-600 text-hover-primary fw-bolder fs-3'>
            70 <span className='deg_celsius'>&#8451;</span>
          </span>
          {/* <span className='text-muted fw-semibold d-block'>70 C </span> */}
        </div>
      </div>
      {/* ---- */}
      <div className='d-flex align-items-center'>
        <span className='bullet bullet-vertical h-40px bg-warning'></span>
        <div className='flex-grow-1 [ ps-3 ]'>
          <span className='text-gray-800 text-hover-primary fw-bolder fs-1'>
            68 <span className='deg_fahrenheit'>&#8457;</span>
          </span>
        </div>
      </div>
      {/* ---- */}
      <div className='d-flex align-items-center'>
        <span className='bullet bullet-vertical h-40px bg-warning'></span>
        <div className='flex-grow-1 [ ps-3 ]'>
          <span className='text-gray-600 text-hover-primary fw-bolder fs-3'>
            0 <span className='deg_celsius'>&#8451;</span>
          </span>
        </div>
      </div>
    </>
  )
}
