import * as Yup from 'yup'
import clsx from 'clsx'
import { useFormik } from 'formik'
import { useSearchParams } from 'react-router-dom'
import { useState, forwardRef, useRef, useEffect, useImperativeHandle, useCallback } from 'react'
import React from 'react'
import CustomToast from "../../modules/utility/CustomToast"
import IFile from '../../interfaces/File'
import { useDropzone } from 'react-dropzone'

interface IValidationResponse {
    isValid: boolean
    status: string
    isExistingEmail: boolean
    invitedByEmail: string
    invitedByFullName: string
}

const inviteSchema = Yup.object().shape({
    name: Yup.string(),
    lastName: Yup.string(),
    email: Yup.string()
        .email('Wrong email format')
        .min(3, 'Minimum 3 symbols')
        .max(50, 'Maximum 50 symbols')
        .required('Email is required'),
    password: Yup.string()
        .min(8, 'Minimum 8 symbols')
        .max(50, 'Maximum 50 symbols')
        .required('Password is required')
        .matches(
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
            "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
        ),
    contactNo1: Yup.string()
        .max(10, 'Maximum 10 symbols'),
    contactNo2: Yup.string()
        .max(10, 'Maximum 10 symbols')
})

const initialValues = {
    name: '',
    lastName: '',
    email: '',
    password: '',
    contactNo1: '',
    contactNo2: '',
    Pictures: []
}

const UserDetailsForm = forwardRef((props: any, ref: any) => {
    const [isLoading, setLoading] = useState(false)
    const [validationResponse, setValidation] = useState<IValidationResponse>()

    useImperativeHandle(ref, () => ({

        validateForm() {
            formik.handleSubmit();
        }

    }));


    useEffect(() => {
        fetch(process.env.REACT_APP_IOT_API_URL + 'users/validateinvite?hash=' + props.hash, {
            method: 'GET',
            headers: {
                Accept: 'application/json'
            }
        }).then(response => {
            if (response.status !== 200) return

            response.json().then(data => {
                setValidation(data);
                props.setExisting(data.isExistingEmail);
            })
        }).catch()
    }, [])

    function getValidationMessage() {
        if (validationResponse?.isValid == false) {
            if (validationResponse?.status == "Expired")
                return "This invite has expired.";
            if (validationResponse?.status == "Linked")
                return "This invite has already been linked.";
            if (validationResponse?.status == "Declined")
                return "This invite has already been declined.";
            return "An unknown error has occurred while verifying the invite, please try again."
        }
        else return "";
    }

    const formik = useFormik({
        initialValues,
        validationSchema: inviteSchema,
        onSubmit: async (values, { setStatus, setSubmitting }) => { },
        validateOnBlur: true
    })

    const onDrop = useCallback(acceptedFiles => {
        const reader = new FileReader();
        reader.addEventListener('load', () => {
            let data = {} as IFile;
            data.file = Array.from(new Uint8Array(reader.result as ArrayBuffer));
            // call api here 
            data.fileName = acceptedFiles[0].name;

            props.AddPicture(data);
        });
        reader.readAsArrayBuffer(acceptedFiles[0]);
    }, [])

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop, accept: {
            'image/jpeg': [], 'image/jpg': [],
            'image/png': []
        }
    })

    return <>

        <form
            className='form w-100 d-flex flex-column gap-3'
            onSubmit={formik.handleSubmit}
            noValidate
            id='kt_login_signin_form'
        >
            {(validationResponse?.isExistingEmail && validationResponse?.isValid) ?
                <div className='mb-lg-15 alert alert-info text-center'>
                    <div className='alert-text font-weight-bold'>Please accept the invite to the new device below.</div>
                </div>
                : ((!validationResponse?.isValid || formik.status) ? (
                    <div className='mb-lg-15 alert alert-danger text-center'>
                        <div className='alert-text font-weight-bold'>{validationResponse?.isValid ? formik.status : (getValidationMessage())}</div>
                    </div>
                ) : (<>

                    {/* First Name */}
                    <div>
                        <label className='form-label fs-6 fw-bolder text-dark'>First Name</label>
                        <input type='text'  {...formik.getFieldProps('name')} className='form-control form-control-lg form-control-solid fs-6'
                            onBlur={eve => {
                                props.setUserInfo(formik.values)
                                formik.handleBlur(eve)
                                props.setValid(formik.isValid)
                            }} />
                    </div>

                    {/* Last Name */}
                    <div>
                        <label className='form-label fs-6 fw-bolder text-dark'>Last Name</label>
                        <input type='text'  {...formik.getFieldProps('lastName')} className='form-control form-control-lg form-control-solid fs-6'
                            
                            onBlur={eve => {
                                props.setUserInfo(formik.values)
                                formik.handleBlur(eve)
                                props.setValid(formik.isValid)
                            }} />
                    </div>

                    {/* Email */}
                    <div>
                        <label className='required form-label fs-6 fw-bolder text-dark'>Email</label>
                        <input type='email' {...formik.getFieldProps('email')} className={clsx(
                            'form-control form-control-lg form-control-solid fs-6',
                            { 'is-invalid': formik.touched.email && formik.errors.email },
                            {
                                'is-valid': formik.touched.email && !formik.errors.email,
                            }
                        )} 
                            onBlur={eve => {
                                props.setUserInfo(formik.values)
                                formik.handleBlur(eve)
                                props.setValid(formik.isValid)
                            }} />
                        {formik.touched.email && formik.errors.email && (
                            <div className='fv-plugins-message-container'>
                                <span role='alert' className='text-danger'>{formik.errors.email}</span>
                            </div>
                        )}
                    </div>

                    {/* Password */}
                    <div>
                        <label className='required form-label fs-6 fw-bolder text-dark'>Password</label>
                        <input type='password' {...formik.getFieldProps('password')} className={clsx(
                            'form-control form-control-lg form-control-solid fs-6',
                            { 'is-invalid': formik.touched.password && formik.errors.password },
                            {
                                'is-valid': formik.touched.password && !formik.errors.password,
                            }
                        )}
                            
                            onBlur={eve => {
                                props.setUserInfo(formik.values)
                                formik.handleBlur(eve)
                                props.setValid(formik.isValid)
                            }} />
                        {formik.touched.password && formik.errors.password && (
                            <div className='fv-plugins-message-container'>
                                <span role='alert' className='text-danger'>{formik.errors.password}</span>
                            </div>
                        )}
                    </div>

                    {/* Contact no 1 */}
                    <div>
                        <label className='form-label fs-6 fw-bolder text-dark'>Contact Number 1</label>
                        <input type='tel' {...formik.getFieldProps('contactNo1')} className='form-control form-control-lg form-control-solid fs-6'
                            
                            onBlur={eve => {
                                props.setUserInfo(formik.values)
                                formik.handleBlur(eve)
                                props.setValid(formik.isValid)
                            }} />
                        {formik.touched.contactNo1 && formik.errors.contactNo1 && (
                            <div className='fv-plugins-message-container'>
                                <span role='alert' className='text-danger'>{formik.errors.contactNo1}</span>
                            </div>
                        )}
                    </div>

                    {/* Contact no 2 */}
                    <div>
                        <label className='form-label fs-6 fw-bolder text-dark'>Contact Number 2</label>
                        <input type='tel' {...formik.getFieldProps('contactNo2')} className='form-control form-control-lg form-control-solid fs-6'
                            
                            onBlur={eve => {
                                props.setUserInfo(formik.values)
                                formik.handleBlur(eve)
                                props.setValid(formik.isValid)
                            }} />
                        {formik.touched.contactNo2 && formik.errors.contactNo2 && (
                            <div className='fv-plugins-message-container'>
                                <span role='alert' className='text-danger'>{formik.errors.contactNo2}</span>
                            </div>
                        )}
                    </div>


                    <h1>Upload Multiple Profile Pictures</h1>

                    <section className='pb-10'>
                        <div {...getRootProps()}>
                            <input {...getInputProps()} />
                            <div className="dropzone" id="company_logo_uploader">
                                <div className="dropzone-msg dz-message needsclick">
                                    <h3 className="dropzone-msg-title">{isDragActive ? "Release drag to add files here" : "Drop files here or click to upload."}</h3>
                                </div>
                            </div>
                        </div>
                    </section>
                </>)
                )
            }

        </form>
    </>
})

export default UserDetailsForm;