import {EnrollmentWizard} from './EnrollmentWizard'



export function EnrollmentPage() {

  return (
    <>
      <EnrollmentWizard />
    </>
  )
}
