import * as Yup from 'yup'
import clsx from 'clsx'
import { useRef, useState } from "react"
import { Device } from "../../../../interfaces/Device"
import { FormikErrors, useFormik } from 'formik'
import { useEffect, useImperativeHandle } from 'react'

interface IMyProps {
    inviteTo: any
    setInviteTo: any
    setValid: any
    devices: [Device]
}

const valuesSchema = Yup.object().shape({
    email: Yup.string()
        .required('Email is required')
        .email('Incorrect format'),
    deviceID: Yup.string()
        .required('Device ID is required')
        .min(1, 'Device ID is required'),
})

export function Step1(props: IMyProps) {
    const [selectedRadio, setRadio] = useState('Existing')
    const existingRef = useRef<HTMLSelectElement>(null)
    const newRef = useRef<HTMLInputElement>(null)

    const initialValues = {
        email: props.inviteTo?.email || '',
        deviceID: ''
    }

    const formik = useFormik({
        initialValues,
        validationSchema: valuesSchema,
        onSubmit: async (values, { setStatus, setSubmitting }) => { },
    })

    function setValid() {
        formik.validateForm().then((values?: FormikErrors<{}>) => {
            if(!values) return

            props.setValid(Object.keys(values).length === 0)
        })
    }

    useEffect(() => {formik.setFieldValue('deviceID', ((props.inviteTo?.deviceID && props.inviteTo?.deviceID.length > 0) && props.inviteTo?.deviceID) || props.devices[0]?.deviceID || ''); setValid()}, [props.devices])
    useEffect(() => {setValid(); props.setInviteTo(formik.values)}, [formik.values])

    return <>
        <div className='mb-10 d-flex flex-center flex-column'>
            <p className='text-black text-center'>Enter the Email address of the person you want to Invite to have access to this device</p>
            <div>
                <input type='email' {...formik.getFieldProps('email')} className={clsx(
                    'form-control fs-5 py-1 px-3 w-300px',
                    { 'is-invalid': formik.touched.email && formik.errors.email },
                    {
                        'is-valid': formik.touched.email && !formik.errors.email,
                    }
                )} onBlur={eve => {
                    formik.handleBlur(eve)
                }}/>
                {formik.touched.email && formik.errors.email && (
                    <div className='fv-plugins-message-container'>
                        <span role='alert' className='text-danger'>{formik.errors.email}</span>
                    </div>
                )}
            </div>

            <div className='d-flex flex-center flex-row gap-1 mt-3 w-350px'>
                <input className='form-check-input h-15px w-15px' type='radio' checked={selectedRadio === 'Existing'} onChange={() => {setRadio('Existing'); formik.setFieldValue('deviceID', existingRef.current?.value)}} />
                <label className='form-check-label w-100px'>
                    Existing Device
                </label>
                <select ref={existingRef} onChange={(event) => {formik.setFieldValue('deviceID', event.target.value)}} className='form-select w-200px fs-5 py-1 px-3'>
                    {
                        props.devices.map((device: Device) => (
                            device.status == 'pending' && <option key={device.deviceID} value={device.deviceID}>{device.identifier}</option>
                        ))
                    }
                </select>
            </div>

            <div className='d-flex flex-center flex-row gap-1 mt-3 w-350px'>
                <input className='form-check-input h-15px w-15px' type='radio' checked={selectedRadio === 'New'} onChange={() => {setRadio('New'); formik.setFieldValue('deviceID', newRef.current?.value)}} />
                <label className='form-check-label w-100px'>
                    New Device
                </label>
                <input ref={newRef} onChange={(event) => {formik.setFieldValue('deviceID', event.target.value)}} type='string' className='form-control fs-5 py-1 px-3 w-200px'/>
            </div>

            {formik.errors.deviceID && (
                <div className='fv-plugins-message-container'>
                    <span role='alert' className='text-danger'>{formik.errors.deviceID}</span>
                </div>
            )}
        </div>
    </>
}