import { createGlobalState } from 'react-hooks-global-state'
import { Device } from '../../interfaces/Device'
import { ActiveViewModel } from '../../interfaces/ActiveView'
import { ActiveInverterViewModel } from '../../interfaces/ActiveInverterView'
import { FunctionViewModel } from '../../interfaces/FunctionView'
import DeviceManagementWrapper from '../../pages/device-management/DeviceManagementWrapper'
import {DeviceManager} from '../global-state/DeviceManager'

const { setGlobalState, useGlobalState } = createGlobalState({
    userID: -1,
    deviceInfo: {} as Device,
    ActiveModel: null as null | ActiveViewModel,
    ActiveInverterModel: null as null | ActiveInverterViewModel,
    FunctionModel: null as null | FunctionViewModel
})

export { useGlobalState, setGlobalState }