import { useDropzone } from 'react-dropzone'
import React, { useCallback } from 'react'
import IFile from '../../interfaces/File'

export function UploadPictures(props: any) {
    const onDrop = useCallback(acceptedFiles => {
        const reader = new FileReader();
        reader.addEventListener('load', () => {
            let data = {} as IFile;
            data.file = Array.from(new Uint8Array(reader.result as ArrayBuffer));
            // call api here 
            data.fileName = acceptedFiles[0].name;

            props.addPicture(data);
        });
        reader.readAsArrayBuffer(acceptedFiles[0]);
    }, [])

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop, accept: {
            'image/jpeg': [], 'image/jpg': [],
            'image/png': []
        }
    })

    return <>
        <div className='w-100'>
        <div className='pb-10 pb-lg-15'>
          <h2 className='fw-bolder d-flex align-items-center text-dark'>Upload images of the device</h2>
        </div>
            <div className='fv-row'>
                <div className='row'>
                    <div className='col'>
                        <section>
                            <div {...getRootProps()}>
                                <input {...getInputProps()} />
                                <div className="dropzone" id="company_logo_uploader">
                                    <div className="dropzone-msg dz-message needsclick mb-5">
                                        <h3 className="dropzone-msg-title">{isDragActive ? "Release drag to add files here" : "Drop files here or click to upload."}</h3>
                                    </div>
                                </div>
                            </div>

                            <div className="dropzone mt-5" style={{ cursor: "default" }}>
                                {
                                    props.pictures.map((picture: IFile) => {
                                        return <img key={picture.fileName} className='me-5 mb-5 bg-dark mw-25' src={'data:image/png;base64,' + Buffer.from(picture.file).toString('base64')}></img>
                                    })
                                }
                            </div>
                        </section>
                    </div>
                </div>
            </div>
        </div>
    </>
}