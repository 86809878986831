import { useRef, useState, useEffect } from 'react'
import ReactDOM from "react-dom";
import { Device } from '../../../interfaces/Device'
import { useAuth0 } from '@auth0/auth0-react'
import Toast from 'react-bootstrap/Toast';
import ToastInfo from '../../../interfaces/ToastInfo';
import ToastContainer from 'react-bootstrap/ToastContainer'
import { Step1 } from './Steps/Step1'
import CaptureValues from '../../customer-enrollment/CaptureValues'
import { UploadPictures } from '../../customer-enrollment/UploadPictures'
import Signature from '../../customer-enrollment/Signature'
import IFile from '../../../interfaces/File';
import { KTSVG } from '../../../../_metronic/helpers';
import CustomToast from "../../../modules/utility/CustomToast"

interface IMyProps {
    devices: [Device]
    activeDevice?: Device
}

export function SendInviteLinkModal(props: IMyProps) {
    const btnClose = useRef<any>(null)
    const [token, setToken] = useState('')
    const { getAccessTokenSilently } = useAuth0()

    const [step, setStep] = useState(1)
    const [isValid, setValid] = useState(true)
    const [voltageInfo, setVoltageInfo] = useState<any>()
    const [pictures, setPictures] = useState<IFile[]>([])
    const [signature, setSignature] = useState<IFile>()
    const [signDrew, setSignDrew] = useState<IFile>()
    const [inviteTo, setInviteTo] = useState({
        email: "",
        deviceID: ""
    })

    const signRef = useRef<any>()

    const maxSteps = 4;

    useEffect(() => {
        getAccessTokenSilently().then(token => {
            setToken(token)
        })
    }, [getAccessTokenSilently, setToken])

    useEffect(() => setInviteTo({...inviteTo, deviceID: props.activeDevice?.deviceID.toString() || ''}), [props.activeDevice])

    function AddPicture(file: IFile) {
        setPictures(pics => [...pics, file])
    }

    function SendInviteLink() {
        if (inviteTo.email == "") {
            CustomToast({type: 'error', msg: 'Please enter an email to send an invite to.'});
            
            return;
        };

        if (inviteTo.deviceID == "") {
            CustomToast({type: 'error', msg: 'Please select a device to send an invite for.'});
            
            return;
        };


        fetch(process.env.REACT_APP_IOT_API_URL + 'devices/ownerinvite?email=' + inviteTo.email, {
            method: 'PUT',
            headers: {
                Accept: 'application/json',
                Authorization: 'Bearer ' + token,
                "Content-Type": 'application/json;charset=UTF-8'
            },
            body: JSON.stringify({
                gridVoltage: voltageInfo?.gridVoltage,
                pvVoltage: voltageInfo?.pvVoltage,
                polarity: voltageInfo?.polarity,
                pictures: pictures,
                signature: signature || signDrew,
                deviceID: inviteTo.deviceID,
            }),
        }).then((response) => {
            if (response.status !== 204) {
                CustomToast({type: 'error', msg: 'An error has occurred while trying to send an invite link to be owner.'});
                
                return
            }

            CustomToast({type: 'success', msg: 'Invite link to be owner has been successfully sent.'});

            btnClose.current?.click();
        }).catch(() => {

            CustomToast({type: 'error', msg: 'An error has occurred while trying to send an invite link to be owner.'});
            
        });
    }

    function getPage() {
        switch (step) {
          case 1:
            return <Step1 inviteTo={inviteTo} setInviteTo={setInviteTo} devices={props.devices} setValid={setValid}></Step1>
          case 2:
            return <CaptureValues voltageInfo={voltageInfo} setVoltageInfo={setVoltageInfo} setValid={setValid}></CaptureValues>
          case 3:
            return <UploadPictures pictures={pictures} addPicture={AddPicture}></UploadPictures>
          case 4:
            return <Signature ref={signRef} addSignature={setSignature} signature={signature} setSignDrew={setSignDrew}></Signature>
        }
    }

    function nextStep() {
        if(isValid){
            setStep(step + 1);
            if(step == 4)
                signRef.current.initCanvas();
        }
    }

    function goBack() {
        setStep(step - 1)
        setValid(true)
    }

    return (
        <div className='modal fade' tabIndex={-1} id='send_invite_link_modal' data-bs-backdrop='static' data-bs-keyboard='false' aria-labelledby="staticBackdropLabel" aria-hidden="true">
            <div className='modal-dialog'>
                <div className='modal-content'>
                    <div className='modal-header'>
                        <h5 className='modal-title'>
                            Send Invite Link
                        </h5>
                        <div
                        className="btn btn-icon btn-sm btn-active-light-primary ms-2"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                        >
                            <KTSVG
                                path="/media/icons/duotune/arrows/arr061.svg"
                                className="svg-icon svg-icon-2x"
                            />
                        </div>
                    </div>

                    <div className='modal-body'>
                        {getPage()}
                    </div>

                    <div className='modal-footer d-flex flex-center gap-5'>
                        <button className='d-none' data-bs-dismiss='modal' ref={btnClose}></button>
                        <button className='btn btn-light w-150px' onClick={() => {step === 1 ? btnClose.current?.click() : goBack()}}>{step === 1 ? 'Close' : 'Back'}</button>
                        <button className='btn btn-primary w-150px' onClick={() => {step < maxSteps ? nextStep() : SendInviteLink()}}>{step < maxSteps ? 'Next' : 'Confirm'}</button>
                    </div>
                </div>
            </div>
        </div>)
}