import React from 'react'
import {KTSVG} from '../../_metronic/helpers/components/KTSVG'

export function StatsWidget3() {
  return (
    <div className='card h-150px card-xl-stretch'>
      <div className='card-body d-flex align-items-center justify-content-between flex-wrap'>
        <span className='svg-icon svg-icon-2hx svg-icon-info me-4 mb-5 mb-sm-0 [ pulse ]'>
          <KTSVG
            path='/media/icons/duotune/general/gen030.svg'
            className='svg-icon svg-icon-success svg-icon-2hx ms-n1 flex-grow-1'
          />
        </span>
        <div className='d-flex flex-column'>
          <div className='me-2'>
            <h2 className='fw-bold text-gray-800 mb-3'>Transmission</h2>
            <div className='text-muted fw-semibold fs-6'>
              <h5 className='mb-0'>
                Last Transmission received on
                <b className='text-primary ms-1'>Tuesday, July 2022</b>
                <span className='mx-2'> at</span>
                <b className='text-primary'>15:11</b>
              </h5>
            </div>
          </div>
          <a href='javascript:;' className='btn btn-light-warning fw-semibold [ mt-4 ]'>
            <span
              className='svg-icon svg-icon-1 svg-icon-primary'
              data-html='true'
              data-tip='Click to refresh'
            >
              <svg
                xmlns='http://www.w3.org/2000/svg'
                width='16'
                height='16'
                fill='currentColor'
                className='bi bi-arrow-counterclockwise'
                viewBox='0 0 16 16'
              >
                <path
                  fill-rule='evenodd'
                  d='M8 3a5 5 0 1 1-4.546 2.914.5.5 0 0 0-.908-.417A6 6 0 1 0 8 2v1z'
                />
                <path d='M8 4.466V.534a.25.25 0 0 0-.41-.192L5.23 2.308a.25.25 0 0 0 0 .384l2.36 1.966A.25.25 0 0 0 8 4.466z' />
              </svg>
            </span>
            Refresh
          </a>
        </div>
      </div>
    </div>
  )
}
