import { ActiveViewModel } from '../../app/interfaces/ActiveView'
import {KTSVG} from '../../_metronic/helpers'

interface IMyProps{
  onUpdate: any,
  ActiveModel: ActiveViewModel | null
}

export function SolarAlertUpdate(props: IMyProps) {
  return (
    props.ActiveModel != null && props.ActiveModel.NewSolarFirmwareID != null ? 
    <div className='[ alert alert-custom alert-dismissible ] [ d-flex flex-column flex-sm-row align-items-center justify-content-between ] [ p-5 mb-10X ]'>
      <div className='d-flex align-items-center'>
        <span className='svg-icon svg-icon-2hx svg-icon-info me-4 mb-5 mb-sm-0'>
          <KTSVG
            path='/media/icons/duotune/general/gen002.svg'
            className='svg-icon svg-icon-rubicon-turquoise svg-icon-2hx ms-n1 flex-grow-1'
          />
        </span>
        <div className='d-flex flex-column text-primary pe-0 pe-sm-10'>
          <h5 className='mb-0'>Solar Firmware Update Available</h5>
          <span className='text-secondary'> Version {props.ActiveModel != null ? props.ActiveModel.NewSolarFirmware : ""}</span>
        </div>
      </div>
      <div>
        <a
          href='javascript:;'
          className='btn btn-sm btn-secondary fw-semibold'
          data-bs-dismiss='alert'
        >
          Later
        </a>
        <a href='javascript:;' className='btn btn-sm btn-primary min-w-150px fw-semibold text-dark [ ms-2 ]' onClick={() => props.onUpdate()}>
          Update Now
        </a>
      </div>
    </div>: null
  )
}
