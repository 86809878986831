import React from 'react'
import RedLED from './assets/img/active-view/led4_red.png'
import BaseDevice from 'https://rubicon-iot-dev.web.app/assets/img/active/default_state2.png'
import { DisplayDevice } from '../../../Components/active-view/DisplayDevice'
import { AlertTransmission } from '../../../Components/active-view/AlertTransmission'
import { StatsWidget7 } from '../../../Components/active-view/StatsWidget7'
import { StatsWidget5 } from '../../../Components/active-view/StatsWidget5'
import { StatsWidget11 } from '../../../Components/active-view/StatsWidget11'
import { SolarAlertUpdate } from '../../../Components/active-view/SolarAlertUpdate'
import { EspAlertUpdate } from '../../../Components/active-view/EspAlertUpdate'
import { ActiveView2 } from './ActiveView2'
import { ActiveViewInverter } from './ActiveViewInverter'
import { useEffect, useState } from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import { setGlobalState, useGlobalState } from '../../modules/global-state/GlobalState'
import { ActiveViewModel } from '../../interfaces/ActiveView'
import { DeviceNotConnected } from '../../../Components/active-view/DeviceNotConnected'
import CustomToast from "../../modules/utility/CustomToast"
import { DeviceManager } from '../../modules/global-state/DeviceManager'

export function ActiveView() {
  const { getAccessTokenSilently } = useAuth0()
  const [token, setToken] = useState('')
  const deviceID = DeviceManager.getDeviceID();
  const [ActiveModel, setActiveModel] = useGlobalState('ActiveModel')
  const [ActiveInverterModel, setActiveInverterModel] = useGlobalState('ActiveInverterModel')
  const [InitialLoad, setInitialLoad] = useState(true);
  const [deviceInfo] = useGlobalState('deviceInfo');

  useEffect(() => {
    getAccessTokenSilently().then((token) => {
      setToken(token);
      loadActiveView(token);
    })
  }, []);



  function loadActiveView(localtoken: string) {
    fetch(process.env.REACT_APP_IOT_API_URL + 'active/get/' + deviceID, {
      headers: {
        Accept: 'application/json',
        Authorization: 'Bearer ' + localtoken,
      },
    })
      .then((response) => {
        setInitialLoad(false);
        if (![200, 204].includes(response.status)) return

        if (response.status == 204) {
          setActiveModel(null)
        } else {
          response
            .clone()
            .json()
            .then((result) => {

              if (result.deviceType == "Geyser") {
                var data = result.geyserModel;
                setActiveModel(
                  Object.assign({}, ActiveModel, {
                    ImportedToday: (data.importedToday < 0) ? 0 : data.importedToday,
                    PVProductedToday: (data.pvProductedToday < 0) ? 0 : data.pvProductedToday,
                    TotalConsumedToday: (data.totalConsumedToday < 0) ? 0 : data.totalConsumedToday,
                    InstantPV: data.isGrid ? 0 : data.instantPV,
                    InstantImported: data.isGrid ? data.instantImported : 0,
                    DateReceivedS: data.dateReceivedS,
                    TimeReceivedS: data.timeReceivedS,
                    PowerMode: data.powerMode,
                    GeyserTemp: data.geyserTemp,
                    TimeToHot: data.timeToHot,
                    isGrid: data.isGrid,
                    isConnected: data.isDeviceConnected,
                    NewSolarFirmware: data.newSolarFirmware,
                    NewSolarFirmwareID: data.newSolarFirmwareID,
                    NewEspFirmware: data.newEspFirmware,
                    NewEspFirmwareID: data.newEspFirmwareID,
                  })
                )
              }else{
                var data = result.inverterModel;
                setActiveInverterModel(
                  Object.assign({}, ActiveInverterModel, {
                    deviceIdentifier: result.deviceIdentifier,
                    DateReceivedS: data.dateReceivedS,
                    TimeReceivedS: data.timeReceivedS,
                    grid_frequency: data.grid_frequency,
                    grid_voltage: data.grid_voltage,
                    grid_current: data.grid_current,
                    grid_power: data.grid_power,
                    room_temp: data.room_temp,
                    environmental_temp: data.environmental_temp,
                    load_voltage: data.load_voltage,
                    load_current: data.load_current,
                    load_power: data.load_power,
                    load_rate: data.load_rate,
                    battery_voltage: data.battery_voltage,
                    battery_current: data.battery_current,
                    battery_power: data.battery_power,
                    battery_temp: data.battery_temp,
                    battery_soc: data.battery_soc,
                    consumption: data.consumption,
                    isConnected: data.isDeviceConnected
                  })
                )
              }
            })
        }
      })
      .catch()
  }

  function UpdateSolarFirmware() {

    fetch(process.env.REACT_APP_IOT_API_URL + 'active/updatefirmware/' + deviceID + "?firmwareID=" + ActiveModel!.NewSolarFirmwareID, {
      method: 'PUT',
      headers: {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
      },
    })
      .then((response) => {
        if (response.status !== 204) {
          CustomToast({ type: 'error', msg: 'An error has occurred while trying to update the solar firmware.' });

          return
        }

        CustomToast({ type: 'success', msg: 'Device firmware update has been pushed to the device.' });

      })
      .catch(() => {
        CustomToast({ type: 'error', msg: 'An error has occurred while trying to update the solar firmware.' });

      })

  }

  function UpdateEspFirmware() {

    fetch(process.env.REACT_APP_IOT_API_URL + 'active/updatefirmware/' + deviceID + "?firmwareID=" + ActiveModel!.NewEspFirmwareID, {
      method: 'PUT',
      headers: {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
      },
    })
      .then((response) => {
        if (response.status !== 204) {
          CustomToast({ type: 'error', msg: 'An error has occurred while trying to update the solar firmware.' });

          return
        }

        CustomToast({ type: 'success', msg: 'Device firmware update has been pushed to the device.' });

      })
      .catch(() => {
        CustomToast({ type: 'error', msg: 'An error has occurred while trying to update the solar firmware.' });

      })

  }

  return (
    <>
      {deviceInfo.type == "Geyser" ?
        <><SolarAlertUpdate ActiveModel={ActiveModel} onUpdate={UpdateSolarFirmware} />
          <EspAlertUpdate ActiveModel={ActiveModel} onUpdate={UpdateEspFirmware} />
          {(ActiveModel == null || ActiveModel.isConnected == false) && InitialLoad == false ? <DeviceNotConnected /> : null}
          <ActiveView2 ActiveModel={ActiveModel} refresh={() => loadActiveView(token)} /></>
        : <ActiveViewInverter ActiveModel={ActiveInverterModel} refresh={() => loadActiveView(token)} />}
      {/* Old */}
      <div className='row g-5 g-xl-8 [ d-none ]'>
        <div className='col-xl-12'>
          <div className='mb-8'>{/* <AlertTransmission /> */}</div>
          <div className='card card-xl-stretch'>
            {/* <div className='mx-8 mt-8'>
              <AlertTransmission />
            </div> */}
            <div className='[ card-body ] [ active-view-d-grid-holder ] [ height-custom-100 ]'>
              {/* <div className='grid-item-4'>
                <StatsWidget11 />
              </div> */}
              <StatsWidget11 />
              <div className='grid-item-1'>
                <div>
                  <StatsWidget7 />
                </div>
                {/* <div className='card card-custom shadow mb-xl-3'>
                  <div className='card-body'>
                    <StatsWidget7 />
                  </div>
                </div> */}
              </div>
              <div className='grid-item-2'>
                <DisplayDevice />
              </div>
              <div className='grid-item-3'>
                <StatsWidget5 />
                {/* <div className='card card-custom shadow mb-xl-3'>
                  <div className='card-body'>
                    <StatsWidget5 />
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* -----  ----- */}
      <div className='card card-custom card-flush [ d-none ]'>
        <div className='card-header'>
          <h3 className='card-title'>ActiveView</h3>
          <div className='card-toolbar'>
            <button type='button' className='btn btn-sm btn-light'>
              Action
            </button>
          </div>
        </div>
        <div data-img-layers='true' className='card-body'>
          <div className='row'>
            <div className='col align-self-center'>
              <div data-img-layer='main_device' className=''>
                <img
                  src='https://rubicon-iot-dev.web.app/assets/img/active/default_state2.png'
                  alt='base device'
                  className='img-fluid'
                />
              </div>
              <div data-img-layer='led1_blue' className=''>
                <img
                  src='https://rubicon-iot-dev.web.app/assets/img/active/led1_blue.png'
                  alt=''
                  className='img-fluid'
                />
              </div>
              <div data-img-layer='led2_green' className=''>
                <img
                  src='https://rubicon-iot-dev.web.app/assets/img/active/led2_green.png'
                  alt=''
                  className='img-fluid'
                />
              </div>
              <div data-img-layer='led3_red' className=''>
                <img
                  src='https://rubicon-iot-dev.web.app/assets/img/active/led3_red.png'
                  alt=''
                  className='img-fluid'
                />
              </div>
              <div data-img-layer='led4_red' className=''>
                <img
                  src='https://rubicon-iot-dev.web.app/assets/img/active/led4_red.png'
                  alt=''
                  className='img-fluid'
                />
              </div>
            </div>
          </div>
        </div>
        <div data-img-layers='true' className='card-body py-5 [ d-none ]'>
          {/* <img src={"toAbsoluteUrl('./assets/img/active-view/led4_red.png')"} alt="red LED" /> */}
          {/* <img src={"toAbsoluteUrl('./assets/img/active-view/led4_red.png')"} alt="red LED" /> */}
          {/* <img src="{RedLED}" alt="" /> */}
          {/* <img src="{BaseDevice}" alt="" /> */}

          <div data-img-layer='main_device' className=''>
            <img
              src='https://rubicon-iot-dev.web.app/assets/img/active/default_state2.png'
              alt=''
              className='img-fluid'
            />
          </div>
          <div data-img-layer='led1_blue' className=''>
            <code>led1_blue LED</code>
          </div>
          <div data-img-layer='led2_green' className=''>
            <code>led2_green LED</code>
          </div>
          <div data-img-layer='led3_red' className=''>
            <code>led3_red LED</code>
          </div>
          <div data-img-layer='led4_red' className=''>
            <code>led4_red LED</code>
          </div>
        </div>
        <div className='card-footer'>
          <code>test img</code>
          <img src='./img/active-view/default_state2.png' alt='' className='img-fluid' />
        </div>
      </div>
      {/* -----  ----- */}
      <div className='row g-5 g-xl-8 [ d-none ]'>
        <div className='col-xl-12'>
          <div className='card card-xl-stretch'>
            <div className='mx-3 mt-4'>
              <AlertTransmission />
            </div>
            <div className='card-body d-flex flex-column [ pos-relative ] [ height-custom-100 ]'>
              <DisplayDevice />
              <div className='stats-holder-7 [ pos-absolute ]'>
                <div className='card card-custom shadow mb-xl-3'>
                  <div className='card-body'>
                    <StatsWidget7 />
                  </div>
                </div>
              </div>
              <div className='stats-holder-5 [ pos-absolute ]'>
                <div className='card card-custom shadow mb-xl-3'>
                  <div className='card-body'>
                    <StatsWidget5 />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
