import { useNavigate, useSearchParams } from "react-router-dom";
import UserDetailsForm from "./UserDetailsForm";
import CustomToast from "../../modules/utility/CustomToast"
import React, { useState, useRef } from 'react'
import IFile from "../../interfaces/File";

interface IMyProps {
    token: string
}

export function AcceptInvitePage(props: IMyProps) {
    const [searchParams, setSearchParams] = useSearchParams()
    const hash = searchParams.get('hash') || ''
    const navigate = useNavigate()

    const [userInfo, setUserInfo] = useState<any>(null)
    const [Pictures, setPictures] = useState<IFile[]>([])
    const [isValid, setValid] = useState(false);
    const [isExisting, setExisting] = useState(false);

    const userRef = useRef<any>();

    function AddPicture(file: IFile) {
        setPictures([...Pictures, file]);
    }

    function Submit() {
        var userForm = userRef.current;
        userForm.validateForm();

        if (isValid || isExisting) {
            if(!isExisting)
                userInfo.Pictures = Pictures;

            fetch(process.env.REACT_APP_IOT_API_URL + 'users/acceptinvite?' + new URLSearchParams({
                hash: hash || ''
            }), {
                method: 'PUT',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    user: userInfo
                })
            }).then(response => {

                if ([200, 204].includes(response.status)) {
                    CustomToast({type: 'success', msg: 'User has been successfully linked to the device.'});
                    setTimeout(() => {
                        window.location.href = "/";
                      }, 1000);
                } else {
                    CustomToast({type: 'error', msg: 'An error has occurred while trying to add the user.'});
                }
            }

            ).catch(() => {
                CustomToast({type: 'error', msg: 'An error has occurred while trying to add the user.'});
            })
        }
    }

    return <>
        
        <div className='p-10 pb-lg-20'>
            <div className='w-lg-500px bg-white rounded shadow-sm p-10 p-lg-15 mx-auto'>
                <div className='text-center mb-10'>
                    <h1 className='text-dark mb-3'>You've been invited!</h1>
                    <div className='text-gray-400 fw-bold fs-4'>
                        Fill in your details
                    </div>
                </div>

                <UserDetailsForm ref={userRef} hash={hash} setUserInfo={setUserInfo} setValid={setValid} setExisting={setExisting} AddPicture={AddPicture} />

                <button className='btn btn-primary' type='button' onClick={Submit}>Submit</button>
            </div>
        </div>
    </>
}