/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {Link} from 'react-router-dom'
import {useAuth} from '../../../../app/modules/auth'
import {Languages} from './Languages'
import {toAbsoluteUrl} from '../../../helpers'
import {useAuth0} from '@auth0/auth0-react'

// Use this to get the user access token
// getAccessTokenSilently

const HeaderUserMenu: FC = () => {
  const {user, logout} = useAuth0()
  return (
    <div
      className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg menu-state-primary fw-bold py-4 fs-6 w-275px'
      data-kt-menu='true'
    >
      {/* <div className='menu-item px-3'>
        <div className='menu-content d-flex align-items-center px-3'>
          <div className='symbol symbol-50px me-5'>
            <img alt='Logo' src={user?.picture} />
          </div>

          <div className='d-flex flex-column'>
            <div className='fw-bolder d-flex align-items-center fs-5'>
              {user?.nickname}
              <span className='badge badge-light-success fw-bolder fs-8 px-2 py-1 ms-2'>Pro</span>
            </div>
            <a href='#' className='fw-bold text-muted text-hover-primary fs-7'>
              {user?.email}
            </a>
          </div>
        </div>
      </div>

      <div className='separator my-2'></div> */}

      <div className='d-flex flex-column px-9'>
        <div className='pt-10 pb-0'>
          <div className='symbol symbol-50px [ d-flex justify-content-center ] [ mb-4 ]'>
            <img alt='Logo' src={user?.picture} />
          </div>
          <h3 className='text-dark text-center fw-bolder'> {user?.nickname}</h3>
          <div className='text-center text-gray-600 fw-bold pt-1 [ fs-7 ] [ truncate-email ]'>
            {user?.email}
          </div>
          <div className='text-center mt-5 mb-9'>
            <a href="/logout" className='btn btn-sm btn-light-danger px-6'>
              Sign Out
            </a>
          </div>
        </div>
      </div>

      {/* <div className='separator my-2'></div>

      <Languages /> */}
    </div>
  )
}

export {HeaderUserMenu}
