import { useAuth0 } from "@auth0/auth0-react"
import { useRef } from "react"
import { useEffect, useState } from "react"
import { User } from "../../interfaces/User"
import CustomToast from "../../modules/utility/CustomToast"

export function ProfilePage() {
    const { user, getAccessTokenSilently } = useAuth0()
    const [devices, setDevices] = useState([])
    const [token, setToken] = useState('')
    const [userInfo, setUserInfo] = useState<User>()

    const name = useRef<HTMLInputElement>(null)
    const lastName = useRef<HTMLInputElement>(null)
    const email = useRef<HTMLInputElement>(null)
    const address = useRef<HTMLInputElement>(null)
    const contactNo1 = useRef<HTMLInputElement>(null)
    const contactNo2 = useRef<HTMLInputElement>(null)

    useEffect(() => {
        if (!user) return

        getAccessTokenSilently().then(token => {
            setToken(token)

            fetch(process.env.REACT_APP_IOT_API_URL + 'users/get', {
                headers: {
                Accept: 'application/json',
                    Authorization: 'Bearer ' + token
                }
            }).then(response => {
                if (response.status !== 200 && response.status !== 204) return

                response.json().then(data => {
                    setUserInfo(data)
                    
                    if (name.current) name.current.value = data?.name || user?.given_name || ''
                    if (lastName.current) lastName.current.value = data?.lastName || user?.family_name || ''
                    if (email.current) email.current.value = data?.email || user?.email || ''
                    if (address.current) address.current.value = data?.address || user?.address || ''
                    if (contactNo1.current) contactNo1.current.value = data?.contacts ? data?.contacts?.[0]?.number : ''
                    if (contactNo2.current) contactNo2.current.value = data?.contacts ? data?.contacts?.[1]?.number : ''
                })
            }).catch()
        })
    }, [getAccessTokenSilently, setToken, fetch, setUserInfo])

    function updateInfo() {
        let info = {...userInfo}
        info.name = name.current?.value || ''
        info.lastName = lastName.current?.value || ''
        info.email = email.current?.value || ''
        info.address = address.current?.value || ''
        info.contacts = [
            {
                userContactID: 0,
                number: contactNo1.current?.value || '',
                order: 1
            },

            {
                userContactID: 1,
                number: contactNo2.current?.value || '',
                order: 2
            }
        ]

        fetch(process.env.REACT_APP_IOT_API_URL + 'users/edit', {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                Authorization: 'Bearer ' + token,
                "Content-Type": 'application/json;charset=UTF-8'
            },
            body: JSON.stringify(info)
        }).then(response => {
            if (response.status !== 200 && response.status !== 204){
                CustomToast({type: 'error', msg: 'An error has occurred while trying to update the profile information.'});
                return
            }
            CustomToast({type: 'success', msg: 'Profile information has been successfully updated.'});
        }).catch(() => {
            CustomToast({type: 'error', msg: 'An error has occurred while trying to update the profile information.'});
        })
    }

    return (
        <div className='card card-custom card-flush'>
            <div className='card-header'>
            <h3 className='card-title'>Profile</h3>
            <div className='card-toolbar'></div>
        </div>

        <div className='card-body py-5 d-flex flex-column gap-4'>
            <div>
                <label className='form-label fs-6 fw-bolder text-dark'>First Name</label>
                <input ref={name} type='text' className='form-control form-control-lg form-control-solid fs-6'/>
            </div>

            <div>
                <label className='form-label fs-6 fw-bolder text-dark'>Last Name</label>
                <input ref={lastName} type='text' className='form-control form-control-lg form-control-solid fs-6'/>
            </div>

            <div>
                <label className='form-label fs-6 fw-bolder text-dark'>Email</label>
                <input ref={email} type='text' className='form-control form-control-lg form-control-solid fs-6'/>
            </div>

            <div>
                <label className='form-label fs-6 fw-bolder text-dark'>Address of Installation</label>
                <input ref={address} type='text' className='form-control form-control-lg form-control-solid fs-6'/>
            </div>

            <div>
                <label className='form-label fs-6 fw-bolder text-dark'>Contact Number 1</label>
                <input ref={contactNo1} type='text' className='form-control form-control-lg form-control-solid fs-6'/>
            </div>

            <div>
                <label className='form-label fs-6 fw-bolder text-dark'>Contact Number 2</label>
                <input ref={contactNo2} type='text' className='form-control form-control-lg form-control-solid fs-6'/>
            </div>

            <button className='btn btn-primary' type='button' onClick={() => updateInfo()}>Update</button>
        </div>
        </div>
    )
}