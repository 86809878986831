import React from 'react'
import ReactDOM from "react-dom";
import { useEffect, useState } from 'react'
import { User } from '../../interfaces/User'
import { ConfirmationModal } from '../../modules/modals/ConfirmationModal'
import { InviteUserModal } from './modals/InviteUser/InviteUserModal'
import { useAuth0 } from '@auth0/auth0-react'
import CustomToast from "../../modules/utility/CustomToast"
import { setGlobalState, useGlobalState } from "../../modules/global-state/GlobalState";
import { DeviceManager } from '../../modules/global-state/DeviceManager'

export function UserManagement() {
  const [list, setList] = useState<any>([])
  const [activeUser, setActive] = useState<User>()
  const deviceID = DeviceManager.getDeviceID();
  const [token, setToken] = useState('')
  const {getAccessTokenSilently} = useAuth0()


  useEffect(() => {
    getAccessTokenSilently().then((token) => {
      setToken(token)

      loadList(token)
    })
  }, [getAccessTokenSilently, fetch, setToken, setList])

  function loadList(localtoken: string) {
    fetch(process.env.REACT_APP_IOT_API_URL + 'users/list?DeviceFilter=' + deviceID, {
      headers: {
        Accept: 'application/json',
        Authorization: 'Bearer ' + localtoken,
      },
    })
      .then((response) => {
        if (![200, 204].includes(response.status)) return

        response.json().then((data) => {
          setList(data.list)
        })
      })
      .catch()
  }

  function triggerLoad() {
    loadList(token)
  }

  function removeUser(closeButton: any) {
    let email = activeUser?.email

    fetch(process.env.REACT_APP_IOT_API_URL + 'users/removeaccess', {
      method: 'POST',
      body: JSON.stringify({
        deviceID: deviceID,
        userID: activeUser?.userID,
        email: email,
      }),
      headers: {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
        'Content-Type': 'application/json',
      },
    })
      .then((response) => {
        if (![200, 204].includes(response.status)) {
          CustomToast({type: 'error', msg: 'An error has occurred while trying to remove the user.'});
          
          return
        }

        CustomToast({type: 'success', msg: 'User has been successfully removed.'});

        loadList(token)
        closeButton.current!.click()
      })
      .catch(() => {
        CustomToast({type: 'error', msg: 'An error has occurred while trying to remove the user.'});
        
      })
  }

  function transferOwnership(closeButton: any) {
    fetch(process.env.REACT_APP_IOT_API_URL + 'users/changeOwner', {
      method: 'POST',
      body: JSON.stringify({
        deviceID: deviceID,
        userID: activeUser?.userID,
      }),
      headers: {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
        'content-type': 'application/json',
      },
    })
      .then((response) => {
        if (![200, 204].includes(response.status)) {
          CustomToast({type: 'error', msg: 'An error has occurred while trying to change owner of the device.'});
          
          return
        }

        CustomToast({type: 'success', msg: 'Ownership of this device has been successfully changed.'});
          
        loadList(token)
        closeButton.current!.click()
      })
      .catch(() => {
        CustomToast({type: 'error', msg: 'An error has occurred while trying to change owner of the device.'});
        
      })
  }

  function resendInvite(closeButton: any) {
    fetch(
      process.env.REACT_APP_IOT_API_URL +
        'users/resendinvite?' +
        new URLSearchParams({
          email: activeUser?.email || '',
        }).toString(),
      {
        method: 'PUT',
        headers: {
          Accept: 'application/json',
          Authorization: 'Bearer ' + token,
        },
      }
    )
      .then((response) => {
        if (![200, 204].includes(response.status)) {
          CustomToast({type: 'error', msg: 'An error has occurred while trying to resend the invite.'});
          
          return
        }

        CustomToast({type: 'success', msg: 'Invite to owner has been successfully resent.'});

        loadList(token)
        closeButton.current!.click()
      })
      .catch(() => {
        CustomToast({type: 'error', msg: 'An error has occurred while trying to resend the invite.'});
        
      })
  }

  const statusColors = {
    pending: 'light',
    linked: 'primary',
    declined: 'danger',
  } as any

  return (
    <>

      <div className='card card-custom shadow'>
        {ReactDOM.createPortal(
          <>
            <InviteUserModal token={token} deviceID={deviceID} onSuccess={triggerLoad} />
            <ConfirmationModal
              title='Remove User'
              id={'remove_user_modal'}
              color='danger'
              onConfirmEvent={removeUser}
              contents={
                <h3 className='text-dark'>
                  Are you sure you want to <span className='text-danger'>remove</span> this user?
                </h3>
              }
            />
            <ConfirmationModal
              title='Transfer Ownership'
              id={'transfer_ownership_modal'}
              color='primary'
              onConfirmEvent={transferOwnership}
              contents={
                <h3 className='text-dark'>
                  Are you sure you want to <span className='text-primary'>transfer</span> ownership
                  to this user?
                </h3>
              }
            />
            <ConfirmationModal
              title='Resend Invite'
              id={'resend_invite_modal'}
              color='warning'
              onConfirmEvent={resendInvite}
              contents={
                <h3 className='text-dark'>
                  Are you sure you want to <span className='text-warning'>resend</span> the invite?
                </h3>
              }
            />
          </>,
          document.getElementById('modal-div')!
        )}
        <div className='card-header'>
          <h3 className='card-title'>User Management</h3>
          <div className='card-toolbar'>
            <button
              className='btn btn-primary min-w-150px'
              data-bs-toggle='modal'
              data-bs-target='#invite_user_modal'
            >
              Invite User
            </button>
          </div>
        </div>
        <div className='card-body py-5 d-flex flex-column flex-center'>
          <table className='table table-row-dashed table-row-gray-300 gy-7'>
            <thead>
              <tr className='fw-bolder fs-6 text-gray-800'>
                <th>Name</th>
                <th>Email</th>
                <th>Rights</th>
                <th>Status</th>
                <th className='text-center'>Actions</th>
              </tr>
            </thead>
            <tbody>
              {list.map(
                (user: User) =>
                  user && (
                    <tr key={user.email}>
                      <td>{user.name}</td>
                      <td>{user.email}</td>
                      <td>
                        {!user.isOwner ? (
                          <a
                            href='#'
                            className='btn btn-sm btn-outline btn-outline-dashed btn-outline-default btn-active-light-primary'
                            data-bs-toggle='modal'
                            data-bs-target='#transfer_ownership_modal'
                            onClick={() => setActive(user)}
                          >
                            Make Owner
                          </a>
                        ) : (
                          <span className='badge badge-light-success owner_linked [ justify-content-center min-w-100px tc-rubicon-green text-center ]'>
                            <i className='bi bi-person-bounding-box [ me-2 tc-rubicon-green ]'></i> Owner
                          </span>
                        )}
                      </td>
                      <td>
                        <div className={'badge badge-' + statusColors[user.status]}>
                          {user.statusPretty}
                        </div>
                      </td>
                      <td className='text-center'>
                        <a
                          href='#'
                          className='btn btn-sm btn-icon btn-light-danger'
                          data-bs-toggle='modal'
                          data-bs-target='#remove_user_modal'
                          onClick={() => setActive(user)}
                        >
                          <i className='bi bi-x-lg'></i>
                        </a>
                        {user.statusPretty === 'Pending' && (
                          <a
                            href='#'
                            className='btn btn-sm btn-light-primary ms-2'
                            data-bs-toggle='modal'
                            data-bs-target='#resend_invite_modal'
                            onClick={() => setActive(user)}
                          >
                            {' '}
                            <u>Resend Invite</u>
                          </a>
                        )}
                      </td>
                    </tr>
                  )
              )}
            </tbody>
          </table>
        </div>
        <div className='card-footer text-center'>
          <button
            className='btn btn-primary min-w-150px'
            data-bs-toggle='modal'
            data-bs-target='#invite_user_modal'
          >
            Invite User
          </button>
        </div>
      </div>
    </>
  )
}