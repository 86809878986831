import { useDropzone } from 'react-dropzone'
import { useCallback, useEffect, useRef, forwardRef, useImperativeHandle } from 'react'
import IFile from '../../interfaces/File'
import SignatureCanvas from 'react-signature-canvas'
import ReactSignatureCanvas from 'react-signature-canvas'

const Signature = forwardRef((props: any, ref) => {
    const dropzone = useRef<HTMLDivElement>(null)
    const canvas = useRef<ReactSignatureCanvas>(null)

    useImperativeHandle(ref, () => ({
        initCanvas() {
            if (canvas.current) {
                let canv = canvas.current.getCanvas()
                canv.width = dropzone.current?.offsetWidth || 0
                canv.height = (dropzone.current?.offsetHeight || 100) * 1.9
            }
        }
    }));

    const onDrop = useCallback(acceptedFiles => {
        const reader = new FileReader();
        reader.addEventListener('load', () => {
            let data = {} as IFile;
            data.file = Array.from(new Uint8Array(reader.result as ArrayBuffer));
            // call api here 
            data.fileName = acceptedFiles[0].name;

            props.addSignature(data);
        });
        reader.readAsArrayBuffer(acceptedFiles[0]);
    }, [props])

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop, accept: {
            'image/jpeg': [], 'image/jpg': [],
            'image/png': []
        }
    })

    useEffect(() => {
        let down = false

        canvas.current?.getCanvas().addEventListener('mousedown', () => {
            down = true
        })

        window.addEventListener('mouseup', () => {
            if (!down || props.signature) { down = false; return }

            down = false

            let canv = canvas.current?.getCanvas()
            let data = {} as IFile
            data.fileName = ''
            canv?.toBlob((blob: Blob | null) => {
                const reader = new FileReader()
                reader.addEventListener('loadend', () => {
                    data.file = Array.from(new Uint8Array(reader.result as ArrayBuffer))
                    props.setSignDrew(data)
                })
                if (blob) reader.readAsArrayBuffer(blob)
            })
        })

        window.addEventListener('resize', () => {
            if (canvas.current) {
                let canv = canvas.current.getCanvas()
                canv.width = dropzone.current?.offsetWidth || 0
                canv.height = (dropzone.current?.offsetHeight || 100) * 1.9
            }
        })

        if (canvas.current) {
            let canv = canvas.current.getCanvas()
            canv.width = dropzone.current?.offsetWidth || 0
            canv.height = (dropzone.current?.offsetHeight || 100) * 1.9
        }
    }, [canvas, dropzone, window])




    return <div>
        <h2 className='fw-bolder d-flex text-dark'>Sign below</h2>
        <section>
        {// @ts-ignore
            <SignatureCanvas ref={canvas} canvasProps={{ className: 'w-100 mb-4 card bg-gray-100 border border-dashed border-gray-600' }} clearOnResize={false} />
        }
        </section>
        <div className='btn p-0 m-0 w-100'>
            <button className='btn btn-light mb-4 float-end' onClick={() => {
                canvas.current?.clear()
                props.setSignDrew()
            }} type='button'>Clear</button>
        </div>

        <div className='position-relative separator border-2 border-secondary my-6'>
            <label className='position-absolute start-50 top-50 translate-middle text-dark bg-white px-2 fs-5'>
                or
            </label>
        </div>

        <section ref={dropzone}>
            <div {...getRootProps()}>
                <input {...getInputProps()} />
                <div className='dropzone' id='company_logo_uploader'>
                    <div className='dropzone-msg dz-message needsclick'>
                        <h3 className='dropzone-msg-title'>{isDragActive ? 'Release drag to add file here' : 'Drop file here or click to upload.'}</h3>
                    </div>
                </div>
            </div>
        </section>

        {props.isUser && <div className='mt-4 d-flex gap-4'>
            <div className='h-100'><input className='form-check-input' type='checkbox' /></div>
            <label className='form-check-label'><b>I have read and understood the Privacy Policy and expressly consent to my information being shared with a certified installer for further communication in respect of the above submission. Privacy Policy</b></label>
        </div>}
    </div>
})

export default Signature;