import { useCallback, useRef, useState } from "react"
import { KTSVG } from "../../../_metronic/helpers"

interface IMyProps {
    title: string,
    id: string,
    color?: string,
    contents: React.ReactNode,
    onConfirmEvent: (...arg: any) => void
}

export function ConfirmationModal(props: IMyProps) {
    const btnClose = useRef<any>(null)

    return <div className='modal fade' tabIndex={-1} id={props.id}>
        <div className='modal-dialog'>
            <div className='modal-content'>
                <div className='modal-header'>
                    <h5 className='modal-title'>
                        {
                            props.title
                        }
                    </h5>
                    <div
                    className="btn btn-icon btn-sm btn-active-light-primary ms-2"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                    >
                        <KTSVG
                            path="/media/icons/duotune/arrows/arr061.svg"
                            className="svg-icon svg-icon-2x"
                        />
                    </div>
                </div>

                <div className='modal-body'>
                    <div className='mb-10 d-flex flex-center'>
                        {
                            props.contents
                        }
                    </div>
                </div>

                <div className='modal-footer d-flex flex-center gap-5'>
                    <button className='btn btn-light w-150px' data-bs-dismiss='modal' ref={btnClose}>Cancel</button>
                    <button className={'btn btn-' + (props.color ? props.color : 'danger') + ' w-150px'} onClick={() => props.onConfirmEvent(btnClose)}>Confirm</button>
                </div>
            </div>
        </div>
    </div>
}