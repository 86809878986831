import { useAuth0 } from '@auth0/auth0-react'
import {useEffect} from 'react'
import {Navigate, Routes} from 'react-router-dom'
import {useAuth} from './core/Auth'

export function Logout() {
  const {logout} = useAuth0()
  
  useEffect(() => {
    logout({
      returnTo: window.location.origin
    })
  }, [logout])

  return (
      null
  )
}
