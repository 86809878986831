import React from 'react'
import ReactTooltip from 'react-tooltip'
import {KTSVG} from '../../_metronic/helpers/components/KTSVG'

export function AlertTransmission() {
  return (
    <div className='[ alert alert-dismissible ] [ bg-light-primary ] [ border border-primary border-dashed ] [ d-flex flex-column flex-sm-row align-items-center ] [ p-5 mb-10X ]'>
      <span className='svg-icon svg-icon-2hx svg-icon-info me-4 mb-5 mb-sm-0 [ pulse ]'>
        {/* <KTSVG
          path='/media/icons/duotune/medicine/med001.svg'
          className='svg-icon-muted svg-icon-2hx'
        /> */}
        <KTSVG
          path='/media/icons/duotune/general/gen030.svg'
          className='svg-icon-success svg-icon-2hx'
        />
      </span>
      <div className='d-flex flex-column text-primary pe-0 pe-sm-10'>
        <h5 className='mb-0'>
          Last Transmission received on
          <b className='text-primary ms-1'>Tuesday, July 2022</b>
          <span className='mx-2'> at</span>
          <b className='text-primary'>15:11</b>
        </h5>
        {/* <span>
        Last Transmission received at {date and time}
        </span> */}
      </div>

      <button
        type='button'
        className='position-absolute position-sm-relative m-2 m-sm-0 top-0 end-0 btn btn-icon ms-sm-auto'
        data-bs-dismiss='alert'
      >
        <span
          className='svg-icon svg-icon-1 svg-icon-primary'
          data-html='true'
          data-tip='Click to refresh'
        >
          {/* <KTSVG
            path='/media/icons/duotune/arrows/arr011.svg'
            className='svg-icon-muted svg-icon-2hx'
          /> */}
          {/* <KTSVG
            path='/media/icons/duotune/general/gen034.svg'
            className='svg-icon-muted svg-icon-2hx'
          /> */}
          <svg
            xmlns='http://www.w3.org/2000/svg'
            width='16'
            height='16'
            fill='currentColor'
            className='bi bi-arrow-counterclockwise'
            viewBox='0 0 16 16'
          >
            <path
              fill-rule='evenodd'
              d='M8 3a5 5 0 1 1-4.546 2.914.5.5 0 0 0-.908-.417A6 6 0 1 0 8 2v1z'
            />
            <path d='M8 4.466V.534a.25.25 0 0 0-.41-.192L5.23 2.308a.25.25 0 0 0 0 .384l2.36 1.966A.25.25 0 0 0 8 4.466z' />
          </svg>
        </span>
      </button>
      <ReactTooltip effect='solid' place='bottom' type='dark' />
    </div>
  )
}
