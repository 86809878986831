import * as Yup from 'yup'
import clsx from 'clsx'
import { FormikErrors, useFormik } from 'formik'
import { useEffect, forwardRef, useImperativeHandle } from 'react'

interface IMyProps {
    voltageInfo?: any
    setVoltageInfo?: any
    setValid?: any
}

const valuesSchema = Yup.object().shape({
    pvVoltage: Yup.number()
        .required('PV Voltage is required'),
    gridVoltage: Yup.string()
        .required('Grid Voltage is required'),
    polarity: Yup.number()
        .required('Polarity is required'),
})

const CaptureValues = forwardRef((props: IMyProps, ref) => {
    const initialValues = {
        pvVoltage: props.voltageInfo?.pvVoltage || '',
        gridVoltage: props.voltageInfo?.gridVoltage || '',
        polarity: props.voltageInfo?.polarity || ''
    }

    useImperativeHandle(ref, () => ({
        validateForm() {
            formik.handleSubmit();
        }
    }));

    const formik = useFormik({
        initialValues,
        validationSchema: valuesSchema,
        onSubmit: async (values, { setStatus, setSubmitting }) => { },
    })

    useEffect(() => {
        formik.validateForm().then((values?: FormikErrors<{}>) => {
            if(!values) return

            props.setValid(Object.keys(values).length === 0)
        })
    }, [props.setValid])

    return <>
        <form
            className='form w-100 d-flex flex-column gap-4'
            onSubmit={formik.handleSubmit}
            noValidate
            id='kt_login_signin_form'
        >
            <h1>Capture Values</h1>
            <h3 className='pb-10 text-gray-600'>Confirm these values before switching on.</h3>

            {/* PV Voltage */}
            <div>
                <label className='required form-label fs-6 fw-bolder text-dark'>PV Voltage</label>
                <input type='number' {...formik.getFieldProps('pvVoltage')} className={clsx(
                    'form-control form-control-lg form-control-solid fs-6',
                    { 'is-invalid': formik.touched.pvVoltage && formik.errors.pvVoltage },
                    {
                        'is-valid': formik.touched.pvVoltage && !formik.errors.pvVoltage,
                    }
                )} onBlur={eve => {
                    props.setVoltageInfo(formik.values)
                    formik.handleBlur(eve)
                    props.setValid(formik.isValid)
                }} />
                {formik.touched.pvVoltage && formik.errors.pvVoltage && (
                    <div className='fv-plugins-message-container'>
                        <span role='alert' className='text-danger'>{formik.errors.pvVoltage}</span>
                    </div>
                )}
            </div>

            {/* Grid Voltage */}
            <div>
                <label className='required form-label fs-6 fw-bolder text-dark'>Grid Voltage</label>
                <input type='number' {...formik.getFieldProps('gridVoltage')} className={clsx(
                    'form-control form-control-lg form-control-solid fs-6',
                    { 'is-invalid': formik.touched.gridVoltage && formik.errors.gridVoltage },
                    {
                        'is-valid': formik.touched.gridVoltage && !formik.errors.gridVoltage,
                    }
                )} onBlur={eve => {
                    props.setVoltageInfo(formik.values)
                    formik.handleBlur(eve)
                    props.setValid(formik.isValid)
                }} />
                {formik.touched.gridVoltage && formik.errors.gridVoltage && (
                    <div className='fv-plugins-message-container'>
                        <span role='alert' className='text-danger'>{formik.errors.gridVoltage}</span>
                    </div>
                )}
            </div>

            {/* Polarity */}
            <div>
                <label className='required form-label fs-6 fw-bolder text-dark'>Polarity</label>
                <input type='number' {...formik.getFieldProps('polarity')} className={clsx(
                    'form-control form-control-lg form-control-solid fs-6',
                    { 'is-invalid': formik.touched.polarity && formik.errors.polarity },
                    {
                        'is-valid': formik.touched.polarity && !formik.errors.polarity,
                    }
                )} onBlur={eve => {
                    props.setVoltageInfo(formik.values)
                    formik.handleBlur(eve)
                    props.setValid(formik.isValid)
                }} />
                {formik.touched.polarity && formik.errors.polarity && (
                    <div className='fv-plugins-message-container'>
                        <span role='alert' className='text-danger'>{formik.errors.polarity}</span>
                    </div>
                )}
            </div>
        </form>
    </>
});

export default CaptureValues;