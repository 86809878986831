
import { DeviceManager } from '../../../modules/global-state/DeviceManager'
import { useAuth0 } from '@auth0/auth0-react'
import ApexCharts, { ApexOptions } from 'apexcharts'
import { Chart } from 'chart.js';
import { useEffect, useRef, useState } from 'react'
import ReactApexChart from 'react-apexcharts';

interface IDates {
    start: string,
    end: string
}

export function StatsPanel() {
    const [token, setToken] = useState('')
    const { getAccessTokenSilently } = useAuth0()
    const [visible, setVisible] = useState<boolean>(false)
    const deviceID = DeviceManager.getDeviceID();

    const [chartData, setChartData] = useState<any>([]);

    const todayjs = new Date();
    const [dates, setDates] = useState<IDates>({
        start: todayjs.getFullYear() + '-' + ((todayjs.getMonth() < 9 ? "0" : "") + (todayjs.getMonth() + 1)) + '-' + todayjs.getDate(),
        end: todayjs.getFullYear() + '-' + ((todayjs.getMonth() < 9 ? "0" : "") + (todayjs.getMonth() + 1)) + '-' + todayjs.getDate()
    })

    const [interval, setInterval] = useState("300");
    const [isSolar, setSolar] = useState(true)

    useEffect(() => {
        getAccessTokenSilently()
            .then((token) => {
                setToken(token)

                loadGraph(token);
            })
    }, [getAccessTokenSilently, setToken, fetch])

    const loadGraph = (localtoken: string) => {
        fetch(process.env.REACT_APP_IOT_API_URL + 'deviceactivity/' + deviceID + "?start=" + dates.start + "&end=" + dates.end + "&interval=" + interval, {
            headers: {
                Accept: 'application/json',
                Authorization: 'Bearer ' + localtoken,
            },
        })
            .then((response) => {

                response.json().then((data) => {

                    setChartData(data);

                })

            })

            .catch()
    }


    function getChartOptions(height: number, data: any): any {

        //Battery state of charge (soc %) y axis
        var minBatterySOC: number = 100;
        var maxBatterySOC: number = 0;
        var BatterySOCs = data.list.map((val: any) => {
            if (val.batterySOC < minBatterySOC)
                minBatterySOC = Math.floor(val.batterySOC);
            if (val.batterySOC > maxBatterySOC)
                maxBatterySOC = Math.ceil(val.batterySOC);
            return val.batterySOC;
        });


        //Grid voltage y axis
        var minGridVoltage: number = Number.MAX_VALUE;
        var maxGridVoltage: number = Number.MIN_VALUE;
        var GridVoltages = data.list.map((val: any) => {
            if (val.gridVoltage < minGridVoltage)
                minGridVoltage = Math.floor(val.gridVoltage);
            if (val.gridVoltage > maxGridVoltage)
                maxGridVoltage = Math.ceil(val.gridVoltage);
            return val.gridVoltage;
        });

        //Load power y axis
        var minLoadPower: number = Number.MAX_VALUE;
        var maxLoadPower: number = Number.MIN_VALUE;
        var LoadPowers = data.list.map((val: any) => {
            if (val.load < minLoadPower)
                minLoadPower = Math.floor(val.load);
            if (val.load > maxLoadPower)
                maxLoadPower = Math.ceil(val.load);
            return val.load;
        });

        //Date x axis
        var Dates = data.list.map((val: any) => val.periodS);




        return {
            series: [
                {
                    name: 'BatterySOC',
                    data: BatterySOCs,
                    color: "#f1416c",
                },
                {
                    name: 'LoadPower',
                    data: LoadPowers,
                    color: "#ffc700"
                },
                {
                    name: 'GridVoltage',
                    data: GridVoltages,
                    color: "#2a3d4d"
                }
            ],
            options: {
                stroke: {
                    show: true,
                    curve: 'smooth',
                    lineCap: 'butt',
                    colors: undefined,
                    width: 1,
                    dashArray: 0,
                },
                chart: {

                    fontFamily: 'inherit',
                    type: 'line',
                    height: 350,
                    toolbar: {
                        show: false,
                    },

                },
                dataLabels: {
                    enabled: false,
                },
                fill: {
                    opacity: 1,
                },
                tooltip: {
                    fillSeriesColor: false,
                    theme: 'light',
                    style: {
                        fontSize: '12px',
                    },
                    y: [{
                        formatter: function (val: any) {
                            return val + " " + "%"
                        },
                    },
                    {
                        formatter: function (val: any) {
                            return val + " " + "W"
                        },
                    },
                    {
                        formatter: function (val: any) {
                            return val + " " + "V"
                        },
                    }],
                    x: {
                        format: "HH:mm, dd MMM yyyy"
                    },
                },
                xaxis: {
                    categories: Dates,
                    type: "datetime",
                },
                yaxis: [{
                    seriesName: "BatterySOC",
                    min: minBatterySOC,
                    max: maxBatterySOC,
                    labels: {
                        formatter: (value: any) => {
                            return value.toFixed(0)
                        }
                    },
                    title: {
                        text: "Battery SOC (" + "%" + ")",
                        style: {
                            color: '#f1416c',
                        }
                    }
                },
                {
                    seriesName: "LoadPower",
                    min: minLoadPower,
                    max: maxLoadPower,
                    labels: {
                        formatter: (value: any) => {
                            return value.toFixed(0)
                        }
                    },
                    title: {
                        text: "Load Power (" + "W" + ")",
                        style: {
                            color: '#ffc700',
                        }
                    },
                },
                {
                    seriesName: "GridVoltage",
                    min: minGridVoltage,
                    max: maxGridVoltage,
                    labels: {
                        formatter: (value: any) => {
                            return value.toFixed(0)
                        }
                    },
                    title: {
                        text: "Grid Voltage (" + "V" + ")",
                        style: {
                            color: '#2a3d4d',
                        }
                    },
                }
                ]
            }
        }
    }

    return (
        <div className='container'>
            <div className='row'>
                <div className='col-lg-6'>
                    <div className='d-flex justify-content-between'>
                        <div className='form-group | col me-1'>
                            <label className='form-label'>From</label>
                            <input type='date' className='form-control' value={dates.start} onChange={(event) => setDates({ ...dates, start: event.target.value })} />
                        </div>
                        <div className='form-group ms-3X | col ms-1'>
                            <label className='form-label'>To</label>
                            <input type='date' className='form-control' value={dates.end} onChange={(event) => setDates({ ...dates, end: event.target.value })} />
                        </div>
                    </div>
                </div>
                <div className='col-lg-6'>
                    <label className='form-label w-200px'>Interval</label>
                    <select onChange={event => {
                        setInterval(event.target.value)
                    }} className='form-select' value={interval}>
                        <option value="0" >Realtime</option>
                        <option value="30">30 Seconds</option>
                        <option value="60">1 Minute</option>
                        <option value="300">5 Minutes</option>
                        <option value="900">15 Minutes</option>
                        <option value="1800">30 Minutes</option>
                        <option value="3600">60 Minutes</option>
                    </select>
                </div>
                <div className='d-flex flex-center mt-10'>
                    <a className='btn btn-primary w-50 me-4' type='button' href='#lifetime_graph' onClick={() => loadGraph(token)}>
                        <i className='bi bi-check2 fs-2'></i>Get Data
                    </a>

                    {/* <button
              className={'btn btn-icon me-4 ' + (isSolar ? 'btn-warning' : 'btn-light')}
              onClick={() => {
                setSolar(true)
              }}
            >
              <i className='bi bi-brightness-high-fill fs-2x'></i>
            </button>

            <button
              className={'btn btn-icon ' + (isSolar ? 'btn-light' : 'btn-warning')}
              onClick={() => setSolar(false)}
            >
              <i className='bi bi-lightning-charge-fill fs-2x'></i>
            </button> */}
                </div>
            </div>
            {chartData.length == 0 || chartData.list.length == 0 ?
                <div className='mb-lg-15 alert alert-info text-center mt-20'>
                    <div className='alert-text font-weight-bold'>There is no data for the chart during the selected period.</div>
                </div> :
                // @ts-ignore
                <ReactApexChart series={getChartOptions(350, chartData).series} options={getChartOptions(350, chartData).options}></ReactApexChart>}

        </div>
    )
}