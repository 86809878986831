import { KTSVG } from "../../../_metronic/helpers";

export function Rating(props: any) {
    return <>

        <div className='w-100'>

            <div className='fv-row'>
                <div className='row'>
                    <div className='col'>



                        <div className='d-flex flex-center flex-column'>
                            
                            <p className='text-gray-400'>Please rate your installer</p>
                            <div className="rating">
                                <label className="rating-label" htmlFor="kt_rating_2_input_1">
                                    <KTSVG
                                        path="/media/icons/duotune/general/gen029.svg"
                                        className="svg-icon svg-icon-3x"
                                    />
                                </label>
                                <input
                                    className="rating-input"
                                    name="rating2"
                                    value="1"
                                    type="radio"
                                    checked={true}
                                    onChange={() => { props.setRating(1) }}
                                    id="kt_rating_2_input_1"
                                />

                                <label className="rating-label" htmlFor="kt_rating_2_input_2">
                                    <KTSVG
                                        path="/media/icons/duotune/general/gen029.svg"
                                        className="svg-icon svg-icon-3x"
                                    />
                                </label>
                                <input
                                    className="rating-input"
                                    name="rating2"
                                    value="2"
                                    type="radio"
                                    onChange={() => { props.setRating(2) }}
                                    id="kt_rating_2_input_2"
                                />

                                <label className="rating-label" htmlFor="kt_rating_2_input_3">
                                    <KTSVG
                                        path="/media/icons/duotune/general/gen029.svg"
                                        className="svg-icon svg-icon-3x"
                                    />
                                </label>
                                <input
                                    className="rating-input"
                                    name="rating2"
                                    value="3"
                                    type="radio"
                                    onChange={() => { props.setRating(3) }}
                                    id="kt_rating_2_input_3"
                                />

                                <label className="rating-label" htmlFor="kt_rating_2_input_4">
                                    <KTSVG
                                        path="/media/icons/duotune/general/gen029.svg"
                                        className="svg-icon svg-icon-3x"
                                    />
                                </label>
                                <input
                                    className="rating-input"
                                    name="rating2"
                                    value="4"
                                    type="radio"
                                    onChange={() => { props.setRating(4) }}
                                    id="kt_rating_2_input_4"
                                />

                                <label className="rating-label" htmlFor="kt_rating_2_input_5">
                                    <KTSVG
                                        path="/media/icons/duotune/general/gen029.svg"
                                        className="svg-icon svg-icon-3x"
                                    />
                                </label>
                                <input
                                    className="rating-input"
                                    name="rating2"
                                    value="5"
                                    type="radio"
                                    onChange={() => { props.setRating(5) }}
                                    id="kt_rating_2_input_5"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </>
}