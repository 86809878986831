import React from 'react'
import {KTSVG} from '../../_metronic/helpers/components/KTSVG'

export function UpdateWidgetBlock() {
  return (
    <div className='card h-150px card-xl-stretch'>
      <div className='card-body d-flex align-items-center justify-content-between flex-wrap'>
        <span className='svg-icon svg-icon-2hx svg-icon-info me-4 mb-5 mb-sm-0'>
          <KTSVG
            path='/media/icons/duotune/general/gen002.svg'
            className='svg-icon svg-icon-primary svg-icon-2hx ms-n1 flex-grow-1'
          />
        </span>
        <div className='d-flex flex-column'>
          <div className='me-2'>
            <h2 className='fw-bold text-gray-800 mb-3'>Update</h2>
            <div className='text-muted fw-semibold fs-6'>New Firmware Update Available: v1.011</div>
          </div>
          <a href='javascript:;' className='btn btn-primary fw-semibold [ mt-4 ]'>
            Update
          </a>
        </div>
      </div>
    </div>
  )
}
