import React from 'react'

const ContactSupportModal = () => {
  return (
    <div className='modal fade' id='contact_support_modal' tabIndex={-1}>
      <div className='modal-dialog'>
        <div className='modal-content'>
          <div className='modal-header'>
            <h5 className='modal-title'>Contact Support</h5>
          </div>

          <div className='modal-body { py-5 d-flex flex-center gap-10 }'>
            <a
              href='tel:021-555-0570'
              className='btn btn-primary d-flex flex-column flex-center gap-5 | min-w-150px min-h-150px'
            >
              <i className='bi bi-telephone fs-5x'></i>
              <span className='fs-2x'>Call Us</span>
              <span className='fs-1x'>021-555-0570</span>
            </a>

            <a
              href='mailto:support@rubiconsa.com'
              className='btn btn-primary d-flex flex-column flex-center gap-5 | min-w-150px min-h-150px'
            >
              <i className='bi bi-envelope fs-5x'></i>
              <span className='fs-2x'>Email Us</span>
              <span className='fs-1x'>support@rubiconsa.com</span>
            </a>
          </div>

          <div className='modal-footer d-flex flex-center gap-5'>
            <button className='btn btn-light w-150px' data-bs-dismiss='modal'>
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ContactSupportModal
