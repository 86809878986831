import {LifetimePanel} from './components/LifetimePanel'
import {WeekPanel} from './components/WeekPanel'

export function EnergyConsumption() {
  return (
    <div className='card card-custom shadow'>
      <div className='card-header card-header-stretch'>
        <h3 className='card-title'>Energy Consumption</h3>
        <div className='card-toolbar'>
          <ul className='nav nav-tabs nav-line-tabs nav-line-tabs-2x nav-stretch fs-6 border-0'>
            <li className='nav-item'>
              <a className='nav-link active' data-bs-toggle='tab' href='#energy_lifetime_pane'>
                Lifetime
              </a>
            </li>
            <li className='nav-item'>
              <a className='nav-link' data-bs-toggle='tab' href='#energy_week_pane'>
                Seven days
              </a>
            </li>
          </ul>
        </div>
      </div>
      <div className='card-body'>
        <div className='tab-content' id='myTabContent'>
          <div className='tab-pane fade show active' id='energy_lifetime_pane' role='tabpanel'>
            <LifetimePanel />
          </div>
          <div className='tab-pane fade' id='energy_week_pane' role='tabpanel'>
            <WeekPanel />
          </div>
        </div>
      </div>
    </div>
  )
}
