/* eslint-disable jsx-a11y/anchor-is-valid */
import { useAuth0 } from '@auth0/auth0-react'
import ApexCharts, { ApexOptions } from 'apexcharts'
import { Chart } from 'chart.js';
import { useEffect, useRef, useState } from 'react'
import ReactApexChart from 'react-apexcharts';
import { getCSS } from '../../../_metronic/assets/ts/_utils'
import { setGlobalState, useGlobalState } from "../../modules/global-state/GlobalState";
import { DeviceManager } from '../../modules/global-state/DeviceManager'

interface IDates {
  start: string,
  end: string
}

export function GeyserActivity() {

  const [token, setToken] = useState('')
  const { getAccessTokenSilently } = useAuth0()
  const [visible, setVisible] = useState<boolean>(false)
  const deviceID = DeviceManager.getDeviceID();

  const [chartData, setChartData] = useState<any>([]);

  const todayjs = new Date();
  const [dates, setDates] = useState<IDates>({
    start: todayjs.getFullYear() + '-' + ((todayjs.getMonth() < 9 ? "0" : "") + (todayjs.getMonth() + 1)) + '-' + todayjs.getDate(),
    end: todayjs.getFullYear() + '-' + ((todayjs.getMonth() < 9 ? "0" : "") + (todayjs.getMonth() + 1)) + '-' + todayjs.getDate()
  })

  const [interval, setInterval] = useState("300")
  const [isSolar, setSolar] = useState(true)

  useEffect(() => {
    getAccessTokenSilently()
      .then((token) => {
        setToken(token)

        loadGraph(token);
      })
  }, [getAccessTokenSilently, setToken, fetch])

  const loadGraph = (localtoken: string) => {
    fetch(process.env.REACT_APP_IOT_API_URL + 'geyseractivity/' + deviceID + "?start=" + dates.start + "&end=" + dates.end + "&interval=" + interval, {
      headers: {
        Accept: 'application/json',
        Authorization: 'Bearer ' + localtoken,
      },
    })
      .then((response) => {

        response.json().then((data) => {

          setChartData(data);

        })

      })

      .catch()
  }

  function getChartOptions(height: number, data: any): any {

    //Temperature y axis
    var minTemp: number = 200;
    var maxTemp: number = -100;
    var Temperatures = data.list.map((val: any) => {
      if (val.temp < minTemp)
        minTemp = Math.floor(val.temp);
      if (val.temp > maxTemp)
        maxTemp = Math.ceil(val.temp);
      return val.temp;
    });
    //adj min max by setpoints
    if (data.solarSetpoint < minTemp)
      minTemp = Math.floor(data.solarSetpoint);
    if (data.solarSetpoint > maxTemp)
      maxTemp = Math.ceil(data.solarSetpoint);

    if (data.gridSetpoint < minTemp)
      minTemp = Math.floor(data.gridSetpoint);
    if (data.gridSetpoint > maxTemp)
      maxTemp = Math.ceil(data.gridSetpoint);

    if (data.boostSetpoint < minTemp)
      minTemp = Math.floor(data.boostSetpoint);
    if (data.boostSetpoint > maxTemp)
      maxTemp = Math.ceil(data.boostSetpoint);

    //Grid y axis
    var minGrid: number = Number.MAX_VALUE;
    var maxGrid: number = Number.MIN_VALUE;
    var Grids = data.list.map((val: any) => {
      if (val.grid < minGrid)
        minGrid = Math.floor(val.grid);
      if (val.grid > maxGrid)
        maxGrid = Math.ceil(val.grid);
      return val.grid;
    });

    //Solar y axis
    var minSolar: number = Number.MAX_VALUE;
    var maxSolar: number = Number.MIN_VALUE;
    var Solars = data.list.map((val: any) => {
      if (val.solar < minSolar)
        minSolar = Math.floor(val.solar);
      if (val.solar > maxSolar)
        maxSolar = Math.ceil(val.solar);
      return val.solar;
    });
    //Date x axis
    var Dates = data.list.map((val: any) => val.periodS);




    return {
      series: [
        {
          name: 'Temperature',
          data: Temperatures,
          color: "#ff4545",
        },
        {
          name: 'Solar',
          data: Solars,
          color: "#0077c8"
        },
        {
          name: 'Grid',
          data: Grids,
          color: "#2a3d4d"
        }
      ],
      options: {
        stroke: {
          show: true,
          curve: 'smooth',
          lineCap: 'butt',
          colors: undefined,
          width: 1,
          dashArray: 0,
        },
        chart: {

          fontFamily: 'inherit',
          type: 'line',
          height: 350,
          toolbar: {
            show: false,
          },

        },
        dataLabels: {
          enabled: false,
        },
        fill: {
          opacity: 1,
        },
        tooltip: {
          fillSeriesColor: false,
          theme: 'light',
          style: {
            fontSize: '12px',
          },
          y: [{
            formatter: function (val: any) {
              return val + " " + String.fromCodePoint(8451)
            },
          }, {
            formatter: function (val: any) {
              return val + " " + "kW"
            },
          },
          {
            formatter: function (val: any) {
              return val + " " + "kW"
            },
          }],
          x: {
            format: "HH:mm, dd MMM yyyy"
          },
        },
        xaxis: {
          categories: Dates,
          type: "datetime",
        },
        yaxis: [{
          seriesName: "Temperature",
          min: minTemp,
          max: maxTemp,
          labels: {
            formatter: (value: any) => {
              return value.toFixed(0)
            }
          },
          title: {
            text: "Temperature (" + String.fromCodePoint(8451) + ")",
            style: {
              color: '#ff4545',
            }
          }
        },
        {
          seriesName: "Solar",
          min: minSolar,
          max: maxSolar,
          labels: {
            formatter: (value: any) => {
              return value.toFixed(0)
            }
          },
          title: {
            text: "Solar usage (" + "kW" + ")",
            style: {
              color: '#0077c8 ',
            }
          },
        },
        {
          seriesName: "Grid",
          min: minGrid,
          max: maxGrid,
          labels: {
            formatter: (value: any) => {
              return value.toFixed(0)
            }
          },
          title: {
            text: "Grid usage (" + "kW" + ")",
            style: {
              color: '#2a3d4d',
            }
          },
        }
        ],
        annotations: {
          yaxis: [
            {
              y: Math.round(data.solarSetpoint),
              strokeDashArray: 0,
              borderColor: '#0077c8',
              borderWidth: 2,
              label: {
                text: 'Solar Setpoint',
                borderColor: '#0077c8',
                style: {
                  color: '#fff',
                  background: '#0077c8',
                },
              },
            },

            {
              y: Math.round(data.boostSetpoint),
              //offsetY: 3,
              strokeDashArray: 0,
              borderWidth: 2,
              borderColor: '#76DBD5',
              label: {
                text: 'Boost Setpoint',
                borderColor: '#76DBD5',
                style: {
                  color: '#fff',
                  background: '#76DBD5',
                },
              },
            },

            {
              y: Math.round(data.gridSetpoint),
              strokeDashArray: 0,
              borderWidth: 2,
              borderColor: '#2a3d4d',
              label: {
                text: 'Grid Setpoint',
                borderColor: '#2a3d4d',
                style: {
                  color: '#fff',
                  background: '#2a3d4d',
                },
              },
            },
          ],
        }
      }
    }
  }

  return (
    <div className='card card-custom shadow'>
      <div className='card-header'>
        <h3 className='card-title'>Geyser Activity</h3>
        {/* <div className='card-toolbar'></div> */}
      </div>

      <div className='card-body py-5'>
        <div className='row'>
          <div className='col-lg-6'>
            <div className='d-flex justify-content-between'>
              <div className='form-group | col me-1'>
                <label className='form-label'>From</label>
                <input type='date' className='form-control' value={dates.start} onChange={(event) => setDates({ ...dates, start: event.target.value })} />
              </div>
              <div className='form-group ms-3X | col ms-1'>
                <label className='form-label'>To</label>
                <input type='date' className='form-control' value={dates.end} onChange={(event) => setDates({ ...dates, end: event.target.value })} />
              </div>
            </div>
          </div>
          <div className='col-lg-6'>
            <label className='form-label w-200px'>Interval</label>
            <select onChange={event => {
              setInterval(event.target.value)
            }} className='form-select' value={interval}>
              <option value="0" >Realtime</option>
              <option value="30">30 Seconds</option>
              <option value="60">1 Minute</option>
              <option value="300">5 Minutes</option>
              <option value="900">15 Minutes</option>
              <option value="1800">30 Minutes</option>
              <option value="3600">60 Minutes</option>
            </select>
          </div>
          <div className='d-flex flex-center mt-10'>
            <a className='btn btn-primary w-50 me-4' type='button' href='#lifetime_graph' onClick={() => loadGraph(token)}>
              <i className='bi bi-check2 fs-2'></i>Get Data
            </a>
          </div>
        </div>
        {chartData.length == 0 || chartData.list.length == 0 ?
          <div className='mb-lg-15 alert alert-info text-center mt-20'>
            <div className='alert-text font-weight-bold'>There is no data for the chart during the selected period.</div>
          </div> :
          // @ts-ignore
          <ReactApexChart series={getChartOptions(350, chartData).series} options={getChartOptions(350, chartData).options}></ReactApexChart>}
      </div>

    </div>
  )
}