import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { Device } from '../../../interfaces/Device'
import { useGlobalState } from '../../../modules/global-state/GlobalState'

interface IMyProps {
    title: string
    deviceID?: string
    api?: string
    icon?: string
    active?: boolean
    lastActivity?: {}
    setLastActivity?: React.Dispatch<React.SetStateAction<{}>>
    setActive?: any
    isToggle?: boolean
    isFlipped?: boolean
    link?: string
    token?: string
}

const ConditionalWrapper = ({condition, wrap, children}: any) => condition ? wrap(children) : <>{children}</>

export function FunctionButton(props: IMyProps) {

    return <div className={(props.active == undefined ? '' : 'cursor-pointer ') + 'col-sm-12 col-md-4 p-1'} onClick={() => {
        if (props.isToggle && props.active != undefined) {
            if (!props.api) return

            fetch(process.env.REACT_APP_IOT_API_URL + props.api  + (props.deviceID || '') + "?onOff=" + (!props.active).toString()
            , {
                method: 'PUT',
                headers: {
                    Accept: 'application/json',
                    Authorization: 'Bearer ' + props.token
                }
            }).then(response => {
                if (response.status === 200 || response.status === 204) {
                    props.setActive();
                }
            }).catch()
        }
    }}>
        <ConditionalWrapper
            condition={!props.isToggle && props.link}
            wrap={(children: any) => <Link to={props.link || ''}>{children}</Link>}
        >
            <div className={'rounded ' + ((props.active == undefined ? ('bg-rubicon-slate') : (props.isFlipped ? !props.active : props.active) ? 'bg-rubicon-yellow' : 'bg-gray-200')) + ' p-1 h-100 d-flex flex-column flex-center'}>
                <i className={'bi ' + props.icon + ((props.active == undefined ? (' text-gray-500') : (props.isFlipped ? !props.active : props.active) ? ' text-dark' : ' text-dark')) + ' fs-5x'}></i>
                <h1 className={((props.active == undefined ? ('text-gray-500') : (props.isFlipped ? !props.active : props.active) ? 'text-dark' : 'text-dark'))}>{props.title}</h1>
            </div>
        </ConditionalWrapper>
    </div>
}