import ApexCharts, { ApexOptions } from 'apexcharts'
import { getCSS } from '../../../../_metronic/assets/ts/_utils'
import { StatsEC } from './StatsEC'
import { useEffect, useRef, useState } from 'react'
import { setGlobalState, useGlobalState } from "../../../modules/global-state/GlobalState";
import { DeviceManager } from '../../../modules/global-state/DeviceManager'
import { useAuth0 } from '@auth0/auth0-react'
import ReactApexChart from 'react-apexcharts';

interface IDates {
  start: string,
  end: string
}

export function WeekPanel() {
  const [token, setToken] = useState('')
  const { getAccessTokenSilently } = useAuth0()
  const deviceID = DeviceManager.getDeviceID();
  const [chartData, setChartData] = useState(null);
  const [deviceInfo] = useGlobalState('deviceInfo');

  useEffect(() => {
    getAccessTokenSilently()
      .then((token) => {
        setToken(token)

        loadGraph(token);
      })
  }, [getAccessTokenSilently, setToken, fetch])

  const loadGraph = (localtoken: string) => {
    fetch(process.env.REACT_APP_IOT_API_URL + 'energyconsumption/last7/' + deviceID, {
      headers: {
        Accept: 'application/json',
        Authorization: 'Bearer ' + localtoken,
      },
    })
      .then((response) => {
        if (response.status == 204) {
          setChartData(null);
        } else {
          response.json().then((data) => {

            setChartData(data);

          })
        }

      })

      .catch()
  }

  function getChartOptions(data: any): any {


    var solarArr: any = [];
    var gridArr: any = [];
    var batteryArr: any = [];

    var All = data.list.map((val: any) => {
      var total = val.solar + val.grid;
      solarArr.push(val.solar / total * 100);
      gridArr.push(val.grid / total * 100);
      batteryArr.push(val.battery / total * 100);

      return val;
    });

    var Dates = data.list.map((val: any) => val.dateS);

    var seriesVal = [
      {
        name: 'Solar used',
        type: 'bar',
        data: solarArr,
        color: '#0077c8',
      },

      {
        name: 'Grid used',
        type: 'bar',
        data: gridArr,
        color: '#2a3d4d'
      },
    ];

    if (deviceInfo.type != "Geyser") {
      seriesVal.push({
        name: 'Battery used',
        type: 'bar',
        data: batteryArr,
        color: '#f1416c'
      });
    }
    return {
      series: seriesVal,
      options: {
        chart: {
          fontFamily: 'inherit',
          stacked: (deviceInfo.type != "Geyser") ? false : true,
          type: 'line',
          height: 350,
          toolbar: {
            show: false,
          },
        },
        dataLabels: {
          formatter: function (val: any) {
            return val.toFixed(2) + ' %'
          },
        },
        fill: {
          opacity: 1,
        },
        tooltip: {
          fillSeriesColor: false,
          theme: 'light',
          style: {
            fontSize: '12px',
          },
          y: {
            formatter: function (val: any) {
              return val.toFixed(2) + ' %'
            },
          },
        },
        xaxis: {
          categories: Dates,
          type: "category",
        },
        yaxis: [{
          min: 0,
          max: 100,
          labels: {
            formatter: (value: any) => {
              return value.toFixed(0) + ' %'
            }
          },
          title: {
            text: "Percentage (%)",
          }
        }]
      }
    }
  }

  return (
    <div className='container'>
      <div className='row'>
        <div className='col-12 stats-grid'>
          {/* <StatsEC /> */}
        </div>
        <div className='col-12'>
          {chartData == null ?
            <div className='mb-lg-15 alert alert-info text-center mt-20'>
              <div className='alert-text font-weight-bold'>There is no data for the chart during the selected period.</div>
            </div> :
            (// @ts-ignore
              <ReactApexChart series={getChartOptions(chartData).series} options={getChartOptions(chartData).options} type="bar"></ReactApexChart>)
          }
          {/* <div ref={chartRef} style={{height: '350px'}} />*/}
        </div>
      </div>
    </div>
  )
}
