
import { useState, useEffect, forwardRef, useImperativeHandle } from 'react'

const CustomerSignOff = forwardRef((props: any, ref) => {

    const [Signoff, setSignOff] = useState({
        ActiveView: false,
        GridPower: false,
        PeriodAway: false,
        Settings: false,
        EnergyConsumption: false,
        GeyserActivity: false,
        PowerCycle: false
    });

    useImperativeHandle(ref, () => ({

        validateForm() {

            var isValid = Signoff.ActiveView && Signoff.GridPower && Signoff.PeriodAway && Signoff.Settings
                && Signoff.EnergyConsumption && Signoff.GeyserActivity && Signoff.PowerCycle
            return isValid;
        }

    }));



    return <>
        <div className='w-100'>

            <div className='fv-row'>
                <div className='row'>
                    <div className='col'>
                        <div className=''>
                            <div className='form-check form-check-custom form-check-solid mb-2'>
                                <input className='form-check-input' type='checkbox' checked={Signoff.ActiveView} onClick={() => setSignOff({ ...Signoff, ActiveView: !Signoff.ActiveView })} />
                                <label className='form-check-label'>Active View of the System</label>
                            </div>
                            <div className='form-check form-check-custom form-check-solid mb-2'>
                                <input className='form-check-input' type='checkbox' checked={Signoff.GridPower} onClick={() => setSignOff({ ...Signoff, GridPower: !Signoff.GridPower })} />
                                <label className='form-check-label'>How to enable and disable access to Grid power?</label>
                            </div>

                            <div className='form-check form-check-custom form-check-solid mb-2'>
                                <input className='form-check-input' type='checkbox' checked={Signoff.PeriodAway} onClick={() => setSignOff({ ...Signoff, PeriodAway: !Signoff.PeriodAway })} />
                                <label className='form-check-label'>How to turn off for period away from home?</label>
                            </div>

                            <div className='form-check form-check-custom form-check-solid mb-2'>
                                <input className='form-check-input' type='checkbox' checked={Signoff.Settings} onClick={() => setSignOff({ ...Signoff, Settings: !Signoff.Settings })} />
                                <label className='form-check-label'>How to adjust and edit the settings in the App. (ie: geyser setpoint, boost temp)?</label>
                            </div>

                            <div className='form-check form-check-custom form-check-solid mb-2'>
                                <input className='form-check-input' type='checkbox' checked={Signoff.EnergyConsumption} onClick={() => setSignOff({ ...Signoff, EnergyConsumption: !Signoff.EnergyConsumption })} />
                                <label className='form-check-label'>How to interpret your Energy consumption (Wh)?</label>
                            </div>

                            <div className='form-check form-check-custom form-check-solid mb-2'>
                                <input className='form-check-input' type='checkbox' checked={Signoff.GeyserActivity} onClick={() => setSignOff({ ...Signoff, GeyserActivity: !Signoff.GeyserActivity })} />
                                <label className='form-check-label'>How to interpret your Geyser Activity and filters?</label>
                            </div>

                            <div className='form-check form-check-custom form-check-solid mb-2'>
                                <input className='form-check-input' type='checkbox' checked={Signoff.PowerCycle} onClick={() => setSignOff({ ...Signoff, PowerCycle: !Signoff.PowerCycle })} />
                                <label className='form-check-label'>How to reset errors by power cycle</label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
})

export default CustomerSignOff;