import { useAuth0 } from '@auth0/auth0-react'
import React, { useEffect, useState } from 'react'
import { Device } from '../../interfaces/Device'
import { useGlobalState } from '../../modules/global-state/GlobalState'
import { DeviceManager } from '../../modules/global-state/DeviceManager'
import { FunctionButton } from './components/FunctionButton'
import ContactSupportModal from './modals/ContactSupportModal'

export function FunctionView() {
  const deviceID = DeviceManager.getDeviceID();
  const [FunctionModel, setFunctionModel] = useGlobalState('FunctionModel');

  const [deviceInfo] = useGlobalState('deviceInfo');

  const { getAccessTokenSilently } = useAuth0()
  const [token, setToken] = useState('')

  useEffect(() => {
    getAccessTokenSilently().then((token) => {
      setToken(token)

      fetch(process.env.REACT_APP_IOT_API_URL + 'function/get/' + deviceID, {
        headers: {
          Accept: 'application/json',
          Authorization: 'Bearer ' + token,
        },    
      })
        .then((response) => {

          if (response.status == 204) {
            setFunctionModel(null);
          }
          else{ response.clone().json().then((data) => {
            setFunctionModel(data)
          })}

        })
        .catch();

    })
  }, [getAccessTokenSilently, fetch]);

  const setBoost = () => {
    setFunctionModel({...FunctionModel, isBoost: undefined})
  }

  const setAway = () => {
    setFunctionModel({...FunctionModel, isAway: undefined})
  }

  const setGrid = () => {
    setFunctionModel({...FunctionModel, isGrid: undefined})
  }

  return (
    <>
      <div className='card card-custom card-flush bg-transparent'>
        <div className=''>
        {deviceInfo.type == "Geyser" ? 
          <div className='row h-250px'>
            <FunctionButton
              icon='bi-lightning-charge'
              title='Boost'
              isToggle={true}
              api='function/setboost/'
              deviceID={deviceID}
              token={token}
              active={FunctionModel != null ? FunctionModel.isBoost : false}
              setActive={setBoost}
            />
            <FunctionButton
              icon='bi-lightbulb-off'
              title='Grid'
              isToggle={true}
              api='function/setgrid/'
              deviceID={deviceID}
              token={token}
              active={FunctionModel != null ? FunctionModel.isGrid : false}
              setActive={setGrid}
            />
            <FunctionButton
              icon='bi-clock-history'
              title='Away'
              isToggle={true}
              api='function/setaway/'
              deviceID={deviceID}
              token={token}
              active={FunctionModel != null ? FunctionModel.isAway : false}
              setActive={setAway}
            />
          </div>: null}

          <div className='row h-250px'>
            <FunctionButton
              icon='bi-bell'
              title='Notifications/Alerts'
              link='/function-view/notifications'
              active={false}
              deviceID={deviceID}
            />
            <FunctionButton
              icon='bi-question-circle'
              title='Support'
              link='/function-view/support'
              deviceID={deviceID}
              active={false}
            />
            {/* <FunctionButton
              icon='bi-question-circle'
              title='Support'
              data-bs-toggle='modal'
              data-bs-target='#contact_support_modal'
              device={device}
            /> */}
            {deviceInfo.type == "Geyser" ? <>
            <FunctionButton
              icon='bi-gear'
              title='Settings'
              link='/function-view/settings'
              deviceID={deviceID}
              active={false}
            /></> : null}
          </div>
        </div>
      </div>
      <ContactSupportModal />
    </>
  )
}
