import React, { useState } from 'react'
import { Link, useNavigate, useSearchParams } from 'react-router-dom'
import { useAuth0 } from '@auth0/auth0-react'
import CustomToast from "../../modules/utility/CustomToast"


export function EnrollmentDeclineInvitePage() {
    const [searchParams, setSearchParams] = useSearchParams()
    const hash = searchParams.get('hash')
    const navigate = useNavigate()

    function declineInvite() {
        fetch(process.env.REACT_APP_IOT_API_URL + 'devices/declineownerinvite?' + new URLSearchParams({
            hash: hash || ''
        }), {
            method: 'PUT',
            headers: {
                Accept: 'application/json'
            }
        }).then(response => {
            if ([200, 204].includes(response.status)) {
                CustomToast({type: 'success', msg: 'Owner enrollment invite has been declined.'});
                
            }else{
                CustomToast({type: 'error', msg: 'An error has occurred while trying to decline the enrollment.'});
               
            }
        }).catch(() => {
            CustomToast({type: 'error', msg: 'An error has occurred while trying to decline the enrollment.'});
            
        })
    }

    return <>
        
        <div className='p-10 pb-lg-20 d-flex flex-center h-100'>
            <div className='w-lg-500px bg-white rounded shadow-sm p-10 p-lg-15 mx-auto'>
                <form
                className='form w-100 d-flex flex-column gap-3'
                noValidate
                id='kt_login_signin_form'
                >
                    <div className='mb-10 text-center'>
                        <h1>
                            Decline Invite
                        </h1>
                    </div>

                    <div className='mb-10 text-center'>
                        <h3>
                            Are you sure you want to <span className='text-danger'>decline</span> the invite?
                        </h3>
                    </div>

                    <div className='form-footer d-flex flex-center gap-5'>
                        <Link className='btn btn-light w-150px' to={"/"}>No</Link>
                        <button className='btn btn-danger w-150px' type='button' onClick={() => declineInvite()}>Yes</button>
                    </div>
                </form>
            </div>
        </div>
    </>
}