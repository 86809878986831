import {KTSVG} from '../../../../_metronic/helpers'
import { setGlobalState, useGlobalState } from "../../../modules/global-state/GlobalState";

export function StatsEC(props:any) {

  const [deviceInfo] = useGlobalState('deviceInfo');

  return (
    <>
      <div className='d-flex flex-wrap align-items-center bg-light rounded p-5 my-3'>
        <span className='svg-icon svg-icon-warning me-5 [ d-none ]'>
          {/* <KTSVG path='/media/icons/duotune/abstract/abs027.svg' className='svg-icon-1' /> */}
          <i className="bi bi-align-center"></i>
        </span>

        <div className='flex-grow-1 me-2'>
          <span className='fw-bolder text-gray-800 fs-6'>Average Solar Per Day</span>
          {/* <span className='text-muted fw-bold d-block'>Friday, 05 Aug 2022</span> */}
        </div>

        <span className='fw-bolder tc-rubicon-primary py-1 fs-4'>{props.data != null ? (props.data.avesolar).toFixed(2): "-"} kWh</span>
      </div>
      {/* --- */}
      <div className='d-flex flex-wrap align-items-center bg-light rounded p-5 my-3'>
        <span className='svg-icon svg-icon-warning me-5 [ d-none ]'>
          {/* <KTSVG path='/media/icons/duotune/abstract/abs027.svg' className='svg-icon-1' /> */}
          <i className="bi bi-align-top"></i>
        </span>

        <div className='flex-grow-1 me-2'>
          <span className='fw-bolder text-gray-800 fs-6'>Highest Solar Day</span>
          {/* <span className='text-muted fw-bold d-block'>Friday, 05 Aug 2022</span> */}
        </div>

        <span className='fw-bolder tc-rubicon-primary py-1 fs-4'>{props.data != null ? (props.data.highsolar).toFixed(2) : "-"} kWh</span>
      </div>
      {/* --- */}
      <div className='d-flex flex-wrap align-items-center bg-light rounded p-5 my-3'>
        <span className='svg-icon svg-icon-warning me-5 [ d-none ]'>
          {/* <KTSVG path='/media/icons/duotune/abstract/abs027.svg' className='svg-icon-1' /> */}
          <i className="bi bi-align-bottom"></i>
        </span>

        <div className='flex-grow-1 me-2'>
          <span className='fw-bolder text-gray-800 fs-6'>Lowest Solar Day</span>
          {/* <span className='text-muted fw-bold d-block'>Friday, 05 Aug 2022</span> */}
        </div>

        <span className='fw-bolder tc-rubicon-primary py-1 fs-4'>{props.data != null ? (props.data.lowsolar).toFixed(2) : "-"} kWh</span>
      </div>
      {deviceInfo.type != "Geyser" ?
      <><div className='d-flex flex-wrap align-items-center bg-light rounded p-5 my-3'>
        <div className='flex-grow-1 me-2'>
          <span className='fw-bolder text-gray-800 fs-6'>Highest Battery Usage Day</span>
          {/* <span className='text-muted fw-bold d-block'>Friday, 05 Aug 2022</span> */}
        </div>

        <span className='fw-bolder tc-rubicon-primary py-1 fs-4'>{props.data != null ? (props.data.highbattery).toFixed(2) : "-"} kWh</span>
      </div>
      <div className='d-flex flex-wrap align-items-center bg-light rounded p-5 my-3'>
        <div className='flex-grow-1 me-2'>
          <span className='fw-bolder text-gray-800 fs-6'>Lowest Battery Usage Day</span>
          {/* <span className='text-muted fw-bold d-block'>Friday, 05 Aug 2022</span> */}
        </div>

        <span className='fw-bolder tc-rubicon-primary py-1 fs-4'>{props.data != null ? (props.data.lowbattery).toFixed(2) : "-"} kWh</span>
      </div></>: null
      }
    </>
  )
}
